<div
  class="password-reqs-container"
  [class.hide]="!isVisible"
  id="password-requirements"
  aria-atomic="true"
  aria-live="polite"
  [attr.aria-hidden]="!isVisible ? true : false"
>
  <div class="title-text">Your password must contain:</div>

  <div class="row">
    <div class="col-md-5"> 

      <div
        class="requirement"
        [ngClass]="{
          valid: showStatus && hasLowercase,
          invalid: showStatus && !hasLowercase
        }"
        [attr.aria-hidden]="!isVisible ? true : false"
      >
        <i
          class="fa fa-{{ showStatus && !hasLowercase ? 'times' : 'check' }}"
          role="img"
          aria-label="showStatus && !hasLowercase ? 'Password must contain at least one lowercase character' : null"
        ></i>
        <span>1 lowercase</span>
      </div>

      <div
        class="requirement"
        [ngClass]="{
          valid: showStatus && hasUppercase,
          invalid: showStatus && !hasUppercase
        }"
        [attr.aria-hidden]="!isVisible ? true : false"
      >
        <i
          class="fa fa-{{ showStatus && !hasUppercase ? 'times' : 'check' }}"
          role="img"
          aria-label="showStatus && !hasUppercase ? 'Password must contain at least one uppercase character' : null"
        ></i>
        <span>1 uppercase</span>
      </div>
      <div
        class="requirement"
        [ngClass]="{
          valid: showStatus && hasDigit,
          invalid: showStatus && !hasDigit
        }"
        [attr.aria-hidden]="!isVisible ? true : false"
      >
        <i
          class="fa fa-{{ showStatus && !hasDigit ? 'times' : 'check' }}"
          role="img"
          aria-label="showStatus && !hasDigit ? 'Password must contain at least one digit' : null"
        ></i>
        <span>1 digit</span>
      </div>
    </div>
    <div class="col-md-7">
      <div
        class="requirement"
        [ngClass]="{
          valid: showStatus && hasSpecialCharacter,
          invalid: showStatus && !hasSpecialCharacter
        }"
        [attr.aria-hidden]="!isVisible ? true : false"
      >
        <i
          class="fa fa-{{ showStatus && !hasSpecialCharacter ? 'times' : 'check' }}"
          role="img"
          aria-label="showStatus && !hasSpecialCharacter ? 'Password must contain at least one special character' : null"
        ></i>
        <span class="text-nowrap">1 special character</span>
      </div>
      <div
        class="requirement"
        [ngClass]="{
          valid: showStatus && hasEightCharacters,
          invalid: showStatus && !hasEightCharacters
        }"
        [attr.aria-hidden]="!isVisible ? true : false"
      >
        <i
          class="fa fa-{{ showStatus && !hasEightCharacters ? 'times' : 'check' }}"
          role="img"
          aria-label="showStatus && !hasEightCharacters ? 'Password must contain at least eight characters' : null"
        ></i>
        <span>8 characters</span>
      </div>
    </div>
  </div>
</div>
