import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const incomeRouting: CvFormBuilder.Routes = {
  // contact info
  guidRouteA: {
    pageGuid: 'guidPageA',
    // routeNext: 'guidRouteB',
    ruleGroups: [
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
      // This is special case for only user selects Employment (W-2) but do not proceed.
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.$$custom.isEmploymentInformationEmpty',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.NE,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.NE,
            value: -1,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.employments',
            operator: RuleExprOp.GT,
            value: 0,
          },
        ],
      },
    ],
  },

  guidRouteB: {
    pageGuid: 'guidPageB',
    ruleGroups: [
      {
        routeNext: 'guidRouteEVOIEVOESelectA',
        rules: [
          // If income type is 0 (employment)
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          // And twn is enabled in the WP config
          {
            field: 'config.config.area.voi.voe.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          // And twn has NOT been run yet
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationId',
            operator: RuleExprOp.EQ,
            value: null,
          },
          {
            field: 'loan.$$custom.twnFailedInCreditSection',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEVOIEVOESelectA',
        rules: [
          // If income type is 2 (Social Security)
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 3,
          },
          // And twn is enabled in the WP config
          {
            field: 'config.config.area.voi.voe.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          // And twn has NOT been run yet
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationId',
            operator: RuleExprOp.EQ,
            value: null,
          },
          {
            field: 'loan.$$custom.twnFailedInCreditSection',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEVOIEVOESelectA',
        rules: [
          // If income type is 0 (employment)
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          // And twn is enabled in the WP config
          {
            field: 'config.config.area.voi.voe.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isCoBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          // And twn has NOT been run yet
          {
            field: 'loan.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationId',
            operator: RuleExprOp.EQ,
            value: null,
          },
          {
            field: 'loan.$$custom.twnFailedInCreditSectionSpouse',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEVOIEVOESelectA',
        rules: [
          // If income type is 0 (employment)
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          // And twn is enabled in the WP config
          {
            field: 'config.config.area.voi.voe.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isCoBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          // And twn has NOT been run yet
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.employmentVerificationId',
            operator: RuleExprOp.EQ,
            value: null,
          },
          {
            field: 'loan.$$custom.twnFailedInCreditSectionSpouse',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEVOIEVOESelectA',
        rules: [
          // If income type is 2 (Social Security)
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 3,
          },
          // And twn is enabled in the WP config
          {
            field: 'config.config.area.voi.voe.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          // And twn has NOT been run yet
          {
            field: 'loan.transactionInfo.borrowers[1].borrowerDetail.employmentVerificationId',
            operator: RuleExprOp.EQ,
            value: null,
          },
          {
            field: 'loan.$$custom.twnFailedInCreditSectionSpouse',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEmpA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 0,
          },
        ],
      },
      {
        routeNext: 'guidRouteMilitaryA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 1,
          },
        ],
      },
      {
        routeNext: 'guidRouteSocialA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 2,
          },
        ],
      },
      {
        routeNext: 'guidRoutPensionA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 3,
          },
        ],
      },
      {
        routeNext: 'guidRouteBusinessA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 4,
          },
        ],
      },
      {
        routeNext: 'guidRouteOtherA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 5,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 99,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: -1,
          },
        ],
      },
    ],
  },

  guidRouteEVOIEVOESelectA: {
    pageGuid: 'guidPageEVOIEVOESelectA',
    ruleGroups: [
      {
        routeNext: 'guidRouteSocialSecurityBorrower1',
        rules: [
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.isEVerifyIncomeEmployment',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteSocialSecurityBorrower1',
        rules: [
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isEVerifyIncomeEmploymentSpouse',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteSocialSecurityBorrower1',
        rules: [
          {
            field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.borrowerActive.isCoBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isEVerifyIncomeEmployment',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteEmpA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 0,
          },
        ],
      },
      {
        routeNext: 'guidRoutPensionA',
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].cvIncomeTypeId',
            operator: RuleExprOp.EQ,
            value: 3,
          },
        ],
      },
    ],
  },

  guidRouteSocialSecurityBorrower1: {
    pageGuid: 'guidPageSocialSecurityBorrower1',
    ruleGroups: [
      {
        routeNext: 'guidRouteTWNFeatureBorrower1',
        rules: [
          {
            field: 'loan.$$custom.borrowerActive',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteTWNFeatureBorrower2',
        rules: [
          {
            field: 'loan.$$custom.borrowerActive',
            operator: RuleExprOp.NE,
            value: null,
          },
        ],
      },
    ],
  },

  guidRouteTWNFeatureBorrower1: {
    pageGuid: 'guidPageTWNFeatureBorrower1',
    routeNext: 'guidRouteZ',
  },

  guidRouteTWNFeatureBorrower2: {
    pageGuid: 'guidPageTWNFeatureBorrower2',
    routeNext: 'guidRouteZ',
  },

  guidRouteEmpA: {
    pageGuid: 'guidPageEmpA',
    routeNext: 'guidRouteEmpB',
  },

  guidRouteEmpB: {
    pageGuid: 'guidPageEmpB',
    routeNext: 'guidRouteEmpC',
  },

  guidRouteEmpC: {
    pageGuid: 'guidPageEmpC',
    routeNext: 'guidRouteZ',
  },

  guidRouteMilitaryA: {
    pageGuid: 'guidPageMilitaryA',
    routeNext: 'guidRouteMilitaryB',
  },

  guidRouteMilitaryB: {
    pageGuid: 'guidPageMilitaryB',
    routeNext: 'guidRouteZ',
  },

  guidRouteSocialA: {
    pageGuid: 'guidPageSocialA',
    routeNext: 'guidRouteZ',
  },

  guidRoutPensionA: {
    pageGuid: 'guidPagePensionA',
    routeNext: 'guidRouteZ',
  },

  guidRouteBusinessA: {
    pageGuid: 'guidPageBusinessA',
    routeNext: 'guidRouteBusinessB',
  },

  guidRouteBusinessB: {
    pageGuid: 'guidPageBusinessB',
    routeNext: 'guidRouteBusinessC',
  },

  guidRouteBusinessC: {
    pageGuid: 'guidPageBusinessC',
    routeNext: 'guidRouteZ',
  },

  guidRouteOtherA: {
    pageGuid: 'guidPageOtherA',
    routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
