import { mapValues, isArray, isObject, isNil } from 'lodash';
import { ICPOSAppConfig } from 'src/app/shared/models';

export function walk(object: any, callback: (obj: any, path: any[], parent?: any) => void) {
  const _walk = (obj: any, path: any[], parent: any) => {
    callback(obj, path, parent);
    if (isArray(obj)) {
      obj.forEach((value, index) => _walk(value, [...path, index], obj));
    } else if (isObject(obj)) {
      Object.entries(obj).forEach(([key, value]) => _walk(value, [...path, key], obj));
    }
  };

  _walk(object, [], null);
}

export function forEachContent(obj: any, callback: (content: any) => void) {
  return walk(obj, (o, p) => {
    if (isArray(o) && p[p.length - 1] === 'content') {
      o.forEach(c => callback(c));
    }
  });
}

export function getConfigValue<T = any>(config: Record<string, ICPOSAppConfig>, key: string, defaultValue: T = null) {
  const value = config && config[key] && config[key].value as T;
  return !isNil(value) ? value : defaultValue;
}

function mapSections(
  sections: CvFormBuilder.Section[],
  callback: (section: CvFormBuilder.Section, sectionId: string) => CvFormBuilder.Section,
): CvFormBuilder.Section[] {
  return sections.map(section => callback(section, section.sectionId || ''));
}

function mapSectionPages(
  section: CvFormBuilder.Section,
  callback: (page: CvFormBuilder.Page, pageId: string, pageGuid: string) => CvFormBuilder.Page,
): CvFormBuilder.Section {
  return {
    ...section,
    pages: mapValues(section.pages, (page, pageGuid) => callback(page, page.pageId, pageGuid)),
  };
}

function mapSectionRouting(
  section: CvFormBuilder.Section,
  callback: (route: CvFormBuilder.Route, pageId: string) => CvFormBuilder.Route,
): CvFormBuilder.Section {
  return {
    ...section,
    routing: mapValues(section.routing, route => callback(route, route.pageId)),
  };
}

/**
 * Maps the pages of a set of sections calling the callback for each page object.
 * @param {CvFormBuilder.Section[]} sections - The sections to map.
 * @param {(page: CvFormBuilder.Page, route: [string, string], pageGuid: string) => CvFormBuilder.Page} callback - The callback for each page in the section.
 * @return {CvFormBuilder.Section[]} The mapped sections.
 */
export function mapPages(
  sections: CvFormBuilder.Section[],
  callback: (page: CvFormBuilder.Page, route: [string, string], pageGuid: string) => CvFormBuilder.Page,
): CvFormBuilder.Section[] {
  return mapSections(sections, (section, sectionId) =>
    mapSectionPages(section, (page, pageId, pageGuid) => callback(page, [sectionId, pageId], pageGuid)),
  );
}

export function mapRouting(
  sections: CvFormBuilder.Section[],
  callback: (route: CvFormBuilder.Route, sectionId: string, pageId: string) => CvFormBuilder.Route,
): CvFormBuilder.Section[] {
  return mapSections(sections, (section, sectionId) =>
    mapSectionRouting(section, (route, pageId) => callback(route, sectionId, pageId)),
  );
}

/**
 * Maps the content of a page calling the callback for each content object.
 * @param {CvFormBuilder.Page} page - The page to map.
 * @param {(content: any) => any} callback - The callback for each content item in the page. The content is filtered out if the callback returns null.
 * @return {CvFormBuilder.Page} The mapped page.
 */
export function mapSectionPageContents(page: CvFormBuilder.Page, callback: (content: any) => any): CvFormBuilder.Page {
  return {
    ...page,
    content: page.content.map(content => callback(content)).filter(content => !!content),
  };
}
