<div class="modal-body text-center p-4" automationid="Page_ModalNewDesign">

  <h1 class="mb-5 page-title border-color-primary color-primary">A New Look And Feel</h1>

  <div class="device-image-container mb-5">
    <img class="mw-100" src="/assets/img/temp/new-design-devices.png" alt="Mobile devices, tablets, and computer monitors">
  </div>
  <p class="mb-4">We redesigned our website.<br> We made some improvements to our site making it mobile friendly</p>
  <div class="accessible-icon mb-3 color-primary">
    <i class="fa fa-universal-access" aria-label="Universal access logo"></i>
  </div>
  <p class="mb-4">and accessible</p>

  <div class="buttons-footer" automationid="Section_FooterClose">
    <button
      type="button"
      mat-flat-button
      color="primary-outline"
      class="border-color-primary color-primary"
      (click)="submit()"
      aria-label="Close"
      automationid="BtnClose"
    >
      Close
    </button>
  </div>
</div>

