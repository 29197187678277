// @angular modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, enableProdMode, ErrorHandler, APP_INITIALIZER } from '@angular/core'; // APP_INITIALIZER,
import { RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

// Global vendor modules
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

//

// Store Reducer
import { UIReducer } from '$ui';
import { ApiReducer } from '$api';

// Main entrypoint component
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { environment } from '$env';

// Enables faster prod mode, does disable some dirty error checking though
enableProdMode();

// Shared
import {
  AppSettings, // App settings
  AppConfigService, // App config/env settings

  // Interceptors
  HttpInterceptorService,
  GlobalErrorHandler,
} from '$shared';

// Non-lazy loaded routes
import {
  ForgotPasswordComponent,
  LoginComponent,
  MfaComponent,
  NoContentComponent,
  DummyComponent,
  RegisterComponent,
  ActivationComponent,
  BrokerComponent,
  MyLoansComponent,
  MyAccountComponent,
  AuthenticationStatusComponent,
} from '$routes';
import { SiteModule } from '$site';
import { SectionsConfigComponent } from './sections-config/sections-config.component';

// Components
export const APP_COMPONENTS = [
  // App component
  AppComponent,
  // Routes
  LoginComponent,
  MfaComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  BrokerComponent,
  MyAccountComponent,
  MyLoansComponent,
  NoContentComponent,
  DummyComponent,
  ActivationComponent,
  SectionsConfigComponent,
  AuthenticationStatusComponent
];

@NgModule({
  declarations: [APP_COMPONENTS],
  imports: [
    BrowserModule.withServerTransition({ appId: 'angular-starter' }),
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: environment.settings.preloadRoutes ? PreloadAllModules : NoPreloading,
      scrollPositionRestoration: 'enabled',
    }),


    StoreModule.forRoot({ api: ApiReducer, ui: UIReducer  }), // NGRX
    StoreDevtoolsModule.instrument({
      name: 'Clover store',
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }), // environment.settings.enableServiceWorker

    SiteModule.forRoot(),
  ],
  providers: [
    AppSettings, // App settings
    AppConfigService, // App config/env settings

    // Global error handling
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    // HTTP interceptor for auth
    HttpInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    // App initializer for startup
    {
      provide: APP_INITIALIZER,
      useFactory: AppInit,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})


export class AppModule {}

/**
 * Check if environment settings are already present, if not load first before the rest of the app
 * @param settings - App settings
 * @param config - Config service
 */
export function AppInit(config: AppConfigService): () => Promise<any> {
  return (): Promise<any> => config.loadAppSettings();
}
