import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { IAssetViewModel, AssetTypeEnum, GiftandgrantsrelationshipEnum } from 'src/app/shared/models';

export enum AssetType {
  Financial,
  Gifts,
  Other,
  None
}
// TODO: Convert enum lookups to use the datafield from webapi
@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetDetailsComponent implements OnInit {
  @Input() isURLA2020: boolean;
  @Input() asset: IAssetViewModel;
  @Input() dataFields: CvFormBuilder.DataField;
  @Input() canEdit = true;
  @Output() edit = new EventEmitter<AssetType>();

  AssetType = AssetType;
  assetType: AssetType | null = null;

  constructor() {}

  ngOnInit() {
    // Determine asset type
    this.assetType = this.getAssetType();
  }

  private getAssetType() {
    if (this.asset.institiutionContactInfo.companyName) {
      return AssetType.Financial;
    }
    switch (this.asset.assetType) {
      // Gift or grant
      case AssetTypeEnum.GiftFunds:
      case AssetTypeEnum.GiftOfEquity:
      case AssetTypeEnum.Grant:
        return AssetType.Gifts;
      // Other Type
      case AssetTypeEnum.EscrowDeposit:
      case AssetTypeEnum.EmployerAssistance:
      case AssetTypeEnum.ProceedsfromaPropertySale:
      case AssetTypeEnum.SecuredBorrowerFundsNotDeposited:
      case AssetTypeEnum.CashOnHand:
      case AssetTypeEnum.NetEquity:
      case AssetTypeEnum.NetWorthOfBusinessOwned:
      case AssetTypeEnum.OtherLiquidAsset:
      case AssetTypeEnum.OtherNonLiquidAsset:
      case AssetTypeEnum.EmployerAssistance:
      case AssetTypeEnum.ProceedsFromSaleOfNonRealEstateAsset:
      case AssetTypeEnum.RentCredit:
      case AssetTypeEnum.SweatEquity:
      case AssetTypeEnum.TradeEquity:
      case AssetTypeEnum.UnsecuredBorrowedFunds:
      case AssetTypeEnum.LotEquity:
      case AssetTypeEnum.RelocationFunds:
      case AssetTypeEnum.Other:
        return AssetType.Other;
      default:
        return null;
    }
  }
  getAssetTypeDisplay() {
    switch (this.asset.assetType) {
      case AssetTypeEnum.Checking: return 'Checking';
      case AssetTypeEnum.Savings: return 'Savings';
      case AssetTypeEnum.CD: return this.isURLA2020 ? 'Certificate of Deposit' : 'CD';
      case AssetTypeEnum.MutualFunds: return this.isURLA2020 ? 'Mutual Fund' : 'Mutual Funds';
      case AssetTypeEnum.Bonds: return 'Bonds';
      case AssetTypeEnum.Stocks: return 'Stocks';
      case AssetTypeEnum.MoneyMarket: return 'Money Market';
      case AssetTypeEnum.RetirementFund: return this.isURLA2020 ? 'Retirement (e.g. 401K, IRA)' : 'Retirement Fund';
      case AssetTypeEnum.IRA: return 'IRA';
      case AssetTypeEnum.GiftFunds: return this.isURLA2020 ? 'Cash Gift' : 'Gift Funds';
      case AssetTypeEnum.EscrowDeposit: return this.isURLA2020 ? 'Earnest Money' : 'Escrow Deposit';
      case AssetTypeEnum.ProceedsfromaPropertySale: return 'Proceeds from a Property Sale';
      case AssetTypeEnum.TrustFunds: return this.isURLA2020 ? 'Trust Account' : 'Trust Funds';
      case AssetTypeEnum.LifeInsuranceCashValue: return 'Life Insurance Cash Value';
      case AssetTypeEnum.Other: return 'Other';
      case AssetTypeEnum.Automobile: return 'Automobile';
      case AssetTypeEnum.SecuredBorrowerFundsNotDeposited: return this.isURLA2020 ? 'Secured Borrower Funds' : 'Secured Borrower Funds Not Deposited';
      case AssetTypeEnum.BridgeLoan: return this.isURLA2020 ? 'Bridge Loan Proceeds' : 'Bridge Loan';
      case AssetTypeEnum.CashOnHand: return 'Cash On Hand';
      case AssetTypeEnum.GiftOfEquity: return 'Gift of Equity';
      case AssetTypeEnum.NetEquity: return 'Net Equity';
      case AssetTypeEnum.NetWorthOfBusinessOwned: return 'Net Worth Of Business Owned';
      case AssetTypeEnum.OtherLiquidAsset: return 'Other Liquid Asset';
      case AssetTypeEnum.OtherNonLiquidAsset: return 'Other Non Liquid Asset';
      case AssetTypeEnum.GiftedOtherNonLiquidAsset: return 'Gifted Other Non Liquid Asset';
      case AssetTypeEnum.IndividualDevelopmentAccount: return 'Individual Development Account';
      case AssetTypeEnum.StockOptions: return 'Stock Options';
      case AssetTypeEnum.Grant: return 'Grant';
      case AssetTypeEnum.EmployerAssistance: return 'Employer Assistance';
      case AssetTypeEnum.ProceedsFromSaleOfNonRealEstateAsset: return 'Proceeds from Sale of Non-Real Estate Assets';
      case AssetTypeEnum.RentCredit: return 'Rent Credit';
      case AssetTypeEnum.SweatEquity: return 'Sweat Equity';
      case AssetTypeEnum.TradeEquity: return 'Trade Equity';
      case AssetTypeEnum.UnsecuredBorrowedFunds: return 'Unsecured Borrower Funds';
      case AssetTypeEnum.LotEquity: return 'Lot Equity';
      case AssetTypeEnum.RelocationFunds: return 'Relocation Funds';
      case AssetTypeEnum.NotRequired: return 'Not Required';
      case AssetTypeEnum.SelectOneFinancial: return 'Financial Asset';
      case AssetTypeEnum.SelectOneOther: return 'Select One Other';
      case AssetTypeEnum.SelectOneGift: return 'Select One Gift';
      default: return null;
    }
  }
  getRelationshipTypeDisplay() {
    switch (this.asset.relationshipType) {
      case GiftandgrantsrelationshipEnum.Parent: return 'Parent';
      case GiftandgrantsrelationshipEnum.Child: return 'Child';
      case GiftandgrantsrelationshipEnum.Sibling: return 'Sibling';
      case GiftandgrantsrelationshipEnum.Grandparents: return 'Grandparents';
      case GiftandgrantsrelationshipEnum.Fiance: return 'Fiance';
      case GiftandgrantsrelationshipEnum.Spouse: return 'Spouse';
      case GiftandgrantsrelationshipEnum.Employer: return 'Employer';
      case GiftandgrantsrelationshipEnum.NonProfit: return 'Non Profit';
      case GiftandgrantsrelationshipEnum.CommunityNonprofit: return 'Community Nonprofit';
      case GiftandgrantsrelationshipEnum.FederalAgency: return 'Federal Agency';
      case GiftandgrantsrelationshipEnum.LocalAgency: return 'Local Agency';
      case GiftandgrantsrelationshipEnum.Relative: return 'Relative';
      case GiftandgrantsrelationshipEnum.ReligiousNonprofit: return 'Religious Nonprofit';
      case GiftandgrantsrelationshipEnum.StateAgency: return 'State Agency';
      case GiftandgrantsrelationshipEnum.UnmarriedPartner: return 'Unmarried Partner';
      case GiftandgrantsrelationshipEnum.Other: return 'Other';
      case GiftandgrantsrelationshipEnum.Lender: return 'Lender';
      default: return  null;
    }
  }
}
