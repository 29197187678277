import { Injectable, Renderer2 } from '@angular/core';
import { Unsubscribable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TcpaVerbiageService {

  constructor() { }

  applyVerbiage(renderer: Renderer2, target: HTMLElement, subscriptions: Unsubscribable[], options: TcpaVerbiageOptions) {
    if (options.tcpaDisclaimerMarkup) {
      this.applyDisclaimerMarkup(renderer, target, options.tcpaDisclaimerMarkup);
    }
    Array.prototype.forEach.call(target.querySelectorAll('*[data-tag]'), (element: Element) => {
      for (const tag of element.getAttribute('data-tag').split(/\s+/)) {
        this.applyTag(renderer, element, tag, subscriptions, options);
      }
    });
  }

  private applyTag(
    renderer: Renderer2,
    element: Element,
    tag: string,
    subscriptions: Unsubscribable[],
    {onTermsClick, onPrivacyClick, onThirdPartyClick, companyName}: TcpaVerbiageOptions
  ) {
    switch (tag) {
      case 'link-terms':
        return this.setTagLink(renderer, element, subscriptions, _ => onTermsClick && onTermsClick());
      case 'link-privacy':
        return this.setTagLink(renderer, element, subscriptions, _ => onPrivacyClick && onPrivacyClick());
      case 'link-thirdparty':
        return this.setTagLink(renderer, element, subscriptions, _ => onThirdPartyClick && onThirdPartyClick());
      case 'text-companyName':
        return this.setTagText(renderer, element, companyName);
    }
  }
  private unescapeHtml(renderer: Renderer2, str: string) {
    const div = renderer.createElement('div');
    div.innerHTML = str;
    return div.textContent;
  }
  private applyDisclaimerMarkup(renderer: Renderer2, element: Element, markup: string) {
    // we will assume this is valid markup
    element.innerHTML = this.unescapeHtml(renderer, markup);
  }
  private setTagLink(renderer: Renderer2, element: Element, subscriptions: Unsubscribable[], clickHandler: (event: any) => (boolean|void)) {
    subscriptions.push({ unsubscribe: renderer.listen(element, 'click', clickHandler) });
    renderer.setProperty(element, 'tabindex', 0);
  }
  private setTagText(_renderer: Renderer2, element: Element, text: string) {
    element.textContent = text;
  }
}

export interface TcpaVerbiageOptions {
  onTermsClick?: () => void;
  onPrivacyClick?: () => void;
  onThirdPartyClick?: () => void;
  tcpaDisclaimerMarkup?: string;
  companyName: string;
}
