import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface AutocompleteKeyUp {
  event: KeyboardEvent;
  value: string;
}

/**
 * Autocomplete extension for angular materials. Automatically handles the filtering and emits selected values
 * EXAMPLE: For a string array, returns string value
    <app-autocomplete [terms]="terms" placeholder="Select a user..." (optionSelected)="optionSelected($event)"></app-autocomplete>
 * EXAMPLE: For an object array, autocomplete returns the entire selected object
    <app-autocomplete [terms]="terms" placeholder="Select a user..." termLabel="name"
    (optionSelected)="optionSelected($event)"></app-autocomplete>
* EXAMPLE: For an object array, autocomplete returns the selected property value instead of the entire object
    <app-autocomplete [terms]="terms" placeholder="Select a user..." termLabel="name" termValue="name"
    (optionSelected)="optionSelected($event)"></app-autocomplete>
* EXAMPLE: For use with a reactive form. Returns the selected value name as the property name in the form
    <app-autocomplete [terms]="terms" placeholder="Select a user..." termLabel="name" termValue="name"
    [formControlRef]="form.controls.name"></app-autocomplete>
 */
@UntilDestroy()
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  /** Available autocomplete terms */
  @Input() terms: any[] = [];
  /** Default placeholder text */
  @Input() placeholder = '';
  /** Default hint text */
  @Input() hint: string;
  /** The property of the LABEL in the term object. Used to display the human readable text */
  @Input() termLabel: string;
  /** The property of the VALUE in the term object. Used to determine which value to return instead of the whole object */
  @Input() termValue: string;
  /** If autocomplete is part of a form group, pass the form control reference */
  @Input() formControlRef: FormControl;
  /** Available autocomplete terms */
  @Input() termSelected: any[] = [];
  /** If true, will emit the entire object selected intead of just the value */
  @Input() emitObject = false;
  /** If the field is required or not */
  @Input() required: boolean;
  /** The MAXIMUM number of characters allowed by this input */
  @Input() maxlength: number;
  /** The value as the user is typing */
  @Output() valueTyped = new EventEmitter<AutocompleteKeyUp>();
  /** The term that was selected from the autocomplete */
  @Output() optionSelected = new EventEmitter<any>();

  /** Internal form control used for input */
  public autoCompleteControl = new FormControl();
  /** Holds filtered list of terms */
  public filteredOptions$: Observable<string[]>;
  /** Term that was selected from the autocomplete */
  public selectedTerm: string;
  /** Only update async data if component has not loaded */
  private loaded = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    // If a term is preselected, patch it in
    if (this.termSelected) {
      this.autoCompleteControl.patchValue(this.termSelected);
    }

    // Set up filtering as a user types
    this.updateFilteredOptions();
    // If terms or form control ref is updated
    this.autoCompleteControl.patchValue(this.setInitialValue(this.terms, this.formControlRef.value));
    this.loaded = true;
  }

  ngOnChanges(model: SimpleChanges) {
    if (this.loaded) {
      // Set up filtering as a user types
      if (model.terms) {
        this.updateFilteredOptions();
      }
      // If terms or form control ref is updated
      if (model.formControlRef) {
        this.autoCompleteControl.patchValue(this.setInitialValue(this.terms, this.formControlRef.value));
      }
    }
  }

  /**
   * When this control loads or changes, update the visible control to hold the value from the form control ref
   */
  private setInitialValue(terms: any[], currentVal: any) {
    // If terms have been passed
    if (terms && terms.length && currentVal && currentVal !== '') {
      // Get the currently selected term
      const termSelected = terms.filter(term => {
        return (typeof term === 'string' && currentVal.value === term) || term.value === currentVal ? true : false;
      });
      if (termSelected && termSelected.length) {
        return termSelected[0];
      }
    }
    return currentVal;
  }

  /**
   * Update filter options
   */
  private updateFilteredOptions() {
    // Set up filtering as a user types
    this.filteredOptions$ = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value[this.termLabel])),
      map(value => this._filter(value)),
      untilDestroyed(this),
    );
  }

  /**
   * Determine which display label to use
   * @param option
   */
  public labelDisplay(option: any) {
    return this.termLabel && option && option[this.termLabel] ? option[this.termLabel] : option;
  }

  /**
   * Filter visible list of options
   * @param value - Filter term
   */
  private _filter(value: string | any): string[] {
    const filterValue = value.toLowerCase();
    if (this.terms && this.terms.length) {
      return this.terms.filter(option => {
        const optionNew = this.termLabel && option ? option[this.termLabel] : option;
        return optionNew.toLowerCase().includes(filterValue);
      });
    }
    return [];
  }

  /**
   * When an option was selected from the autocomplete
   * @param event
   */
  public selectedOption(event: MatAutocompleteSelectedEvent) {
    const value = this.termValue ? event.option.value[this.termValue] : event.option.value;
    this.selectedTerm = value;

    if (this.emitObject) {
      this.optionSelected.emit(event.option.value);
    } else {
      this.optionSelected.emit(value);
    }

    if (this.formControlRef) {
      this.formControlRef.patchValue(value);
    }
  }

  /**
   * Clear selecter/filtered term
   */
  public clearSelected() {
    this.autoCompleteControl.patchValue('');
    this.optionSelected.emit(null);
    if (this.formControlRef) {
      this.formControlRef.patchValue(null);
    }
    this.selectedTerm = null;
  }

  /**
   *
   * @param event
   */
  public keyUp(event: KeyboardEvent) {
    if (event) {
      this.valueTyped.emit({
        event: event,
        value: (<any>event).target.value,
      });
    }
  }

  public setTouched(){
    this.autoCompleteControl.markAsTouched();
    this.ref.markForCheck();
  }

  ngOnDestroy() {}
}
