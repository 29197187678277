import { ILookupItem } from 'src/app/shared/models';

export class CommonUtils {

  // Code copied from previous comsumer app
  static getStates(): Partial<ILookupItem>[] {
    return [
      {
        selected: false,
        text: 'AL',
        value: '0',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'AK',
        value: '1',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'AZ',
        value: '2',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'AR',
        value: '3',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'CA',
        value: '4',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'CO',
        value: '5',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'CT',
        value: '6',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'DC',
        value: '50',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'DE',
        value: '7',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'FL',
        value: '8',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'GA',
        value: '9',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'HI',
        value: '10',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'ID',
        value: '11',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'IL',
        value: '12',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'IN',
        value: '13',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'IA',
        value: '14',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'KS',
        value: '15',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'KY',
        value: '16',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'LA',
        value: '17',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'ME',
        value: '18',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MD',
        value: '19',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MA',
        value: '20',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MI',
        value: '21',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MN',
        value: '22',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MS',
        value: '23',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MO',
        value: '24',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'MT',
        value: '25',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NE',
        value: '26',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NV',
        value: '27',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NH',
        value: '28',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NJ',
        value: '29',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NM',
        value: '30',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NY',
        value: '31',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'NC',
        value: '32',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'ND',
        value: '33',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'OH',
        value: '34',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'OK',
        value: '35',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'OR',
        value: '36',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'PA',
        value: '37',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'RI',
        value: '38',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'SC',
        value: '39',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'SD',
        value: '40',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'TN',
        value: '41',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'TX',
        value: '42',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'UT',
        value: '43',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'VT',
        value: '44',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'VA',
        value: '45',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'WA',
        value: '46',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'WV',
        value: '47',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'WI',
        value: '48',
        disabled: false,
        contextFlags: null
      },
      {
        selected: false,
        text: 'WY',
        value: '49',
        disabled: false,
        contextFlags: null
      }
    ];
  }

  // Code copied from previous comsumer app
  static getExpirationMonthOptions(): {text: string, value: number}[] {
    return [
      { text: '01 - Jan', value: 1 },
      { text: '02 - Feb', value: 2 },
      { text: '03 - Mar', value: 3 },
      { text: '04 - Apr', value: 4 },
      { text: '05 - May', value: 5 },
      { text: '06 - Jun', value: 6 },
      { text: '07 - Jul', value: 7 },
      { text: '08 - Aug', value: 8 },
      { text: '09 - Sep', value: 9 },
      { text: '10 - Oct', value: 10 },
      { text: '11 - Nov', value: 11 },
      { text: '12 - Dec', value: 12 },
    ];
  }

  // Code copied from previous comsumer app
  static getExpirationYearOptions(): number[] {
    const expirationYearOptions = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 10; i++) {
      expirationYearOptions.push(currentYear + i);
    }
    return expirationYearOptions;
  }

  static findLsid(queryParams: { [key: string]: any; }): string {
    if (!queryParams) {
      return null;
    }
    for(var queryParam in queryParams) {
      if (queryParam.toLowerCase() === "lsid" || queryParam.toLowerCase() === "nmls") {
        return queryParams[queryParam];
      }
    }
    return null;
  }

  static getQueryParams() {
    let query: { [key: string]: any; } = {};
    let queryString = location.search;
    try
    {
      let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
      for (let i = 0; i < pairs.length; i++) {
          let pair = pairs[i].split('=');
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
    }
    catch(err) { }
    return query;
  }
}
