import { Component } from '@angular/core';

@Component({
  selector: 'app-header-simple',
  styleUrls: ['./header-simple.component.scss'],
  templateUrl: './header-simple.component.html',
})
export class HeaderSimpleComponent {

  constructor() {}

}
