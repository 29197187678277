import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UIStoreService } from '../stores/ui/ui.store.service';
import { ILoanViewModel } from '../models';
import { isDefined } from '../utils/data-validation-util';

/**
 * Manages navigating around the 1003 / application route
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationNavigationService {

  sections$: BehaviorSubject<CvFormBuilder.SectionControl[]> = new BehaviorSubject(null);
  appRouting$: BehaviorSubject<{ sectionId: string, pageId: string }> = new BehaviorSubject(null);
  private subject$ = new BehaviorSubject(null);
  canNavigateTo$ = this.subject$.asObservable();
  public navigatingTo: string;
  public sectionId: string = null;
  public pageId: string = null;

  constructor(
    private ui: UIStoreService,
  ) { }

  /**
   * Update the sections Subject
   * @param sections Updated section object
   */
  setSections(sections: CvFormBuilder.SectionControl[]): void {
    this.sections$.next(sections);
  }

  /**
   * Change a section
   * @param sectionId
   */
  public setCurrentSection(path: {sectionId: string, pageId: string}) {
    this.appRouting$.next(path);
  }

  public setCanNavigateTo(value = true) {
    this.subject$.next(value);
  }

  setActiveSection(sectionId: string, pageId?: string): void {
    // Get loan model from UI store
    const loanModel: ILoanViewModel = this.ui && this.ui.form1003.getRawValue().loan;
    // Manage custom overrides of routing logic
    switch (sectionId) {
      // Property section
      case 'property':
        // If non-spouse coborrower flow, do not allow user to go to summary screen
        if (isDefined(loanModel) && loanModel.$$custom.nonSpouseCoborrower && pageId === 'summary') {
          pageId = 'start';
        }
        break;
    }
    this.setCurrentSection({ sectionId: sectionId, pageId: pageId });
  }
}
