import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppStore } from '$shared';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UiSelectorsService {
  public saveState$ = this.store.select(store => store.ui.saveState);
  public modal$ = this.store.select(store => store.ui.modal);
  public formBuilder$ = this.store.select(store => store.ui.formBuilder);
  public config$ = this.store
    .select(store => store.ui.config)
    // Hash the config and only emit on changes
    .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev).replace(/&#39;/gi, "'") === JSON.stringify(curr)));
  public tabActive$ = (tabInstanceId: string) => this.store.select(store => store.ui.tabsActive[tabInstanceId]);
  public toggle$ = (toggleProp: string) => this.store.select(store => store.ui.toggles[toggleProp]);

  constructor(private store: Store<AppStore.Root>) {}
}
