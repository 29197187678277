import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Event } from '@angular/router';

@Component({
  selector: 'app-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNavigationComponent implements OnInit {

  @Input() showButtonBack?: boolean;
  @Input() showButtonNext?: boolean;
  @Input() isFirstPage?: boolean;
  @Input() isLastPage?: boolean;
  @Input() isEmbedded?: boolean;
  @Input() ariaDescribedById?: string;

  @Output() pageBack = new EventEmitter<Event>();
  @Output() pageNext = new EventEmitter<Event>();

  constructor() { }

  ngOnInit() {
  }

  get buttonCount() {
    const withBackButton = +(this.showButtonBack !== false);
    const withNextButton = +(this.showButtonNext !== false);
    return withBackButton + withNextButton;
  }

  get buttonClass() {
    switch (this.buttonCount) {
    case 1: return 'w-100';
    case 2:
    default: return 'w-50';
    }
  }

  get showSpacer() {
    return this.isEmbedded === true;
  }
  get navButtonRowClass() {
    return this.isEmbedded === true ? 'embedded' : null;
  }

  get showNavigationButtons() {
    return this.showButtonBack !== false || (!this.isLastPage && this.showButtonNext !== false);
  }

  get showBackNavigationButton() {
    return this.showButtonBack !== false;
  }

  get showNextNavigationButton() {
    return !this.isLastPage && this.showButtonNext !== false;
  }

  get showSubmitNavigationButton() {
    return this.isLastPage;
  }

  get disableBackButton() {
    return this.isFirstPage;
  }

  onPageBack(e: Event) {
    this.pageBack.next(e);
  }

  onPageNext(e: Event) {
    this.pageNext.next(e);
  }

}
