import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppSettings } from '$shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'authentication-status',
  templateUrl: './authentication-status.component.html',
  styleUrls: ['./authentication-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AuthenticationStatusComponent implements OnInit {
  errorContact: string;
  authenticationStatus: string;
  authenticationErrorMessage: string;

  constructor(private settings: AppSettings, private route: ActivatedRoute, private router: Router) {
    this.errorContact = this.settings.config["ConsumerSite.SSO.ErrorContact"]?.value || "";
    this.authenticationErrorMessage = this.router.getCurrentNavigation()?.extras?.state?.errorMessage;
  }

  ngOnInit() {
    this.route
      .data
      .subscribe(data =>
        this.authenticationStatus = data.authenticationStatus
      );
  }

}
