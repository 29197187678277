<ng-container *ngIf="(banner$ | async) as banner">
  <div id="site-banner"
    class="banner text-center"
    [style.background-color]="banner.backgroundcolor"
    [style.background-image]="banner.backgroundimage && 'url('+ banner.backgroundimage + ')'"
    [style.color]="banner.textcolor"
  >
    <p class="banner-title" *ngIf="banner.textline1"
      [innerHtml]="banner.textline1"
      [style.color]="banner.textcolor"></p>
    <p class="banner-title-sub" *ngIf="banner.textline2"
      [innerHtml]="banner.textline2"
      [style.color]="banner.textcolor"></p>
  </div>
</ng-container>
