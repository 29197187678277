import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '$api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from '../../shared/validators/authorization-form.validators';
import { UIStoreService } from '$ui';
import { AuthenticationRequestTypeEnum, AuthenticationStatusEnum } from 'src/app/shared/models';


interface ResetPasswordFormData {
  userName: string;
  password?: string;
  tempPassword?: string;
}

interface ResetRequestAuthentication {
  userAccountName: string;
  newPassword: string;
  password: string;
}

interface ResetRequest {
  authenticationRequestViewModel: {
    authentication: ResetRequestAuthentication;
    authenticationRequestType: AuthenticationRequestTypeEnum;
  };
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public config$ = this.ui.select.config$;
  formMain: FormGroup;
  waiting: boolean;
  /** Used to show the extended form with extra inputs */
  showingResetForm = true;
  /** Used to show the extended form with extra inputs */
  showCheckEmailMessage = false;
  /** Error shown in the UI */
  error: string;
  /** Toggle password visibility */
  showTempPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;
  /** Show confirm password errors */
  passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();
  /** Toggle password requirements */
  showPasswordRequirements = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private ui: UIStoreService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // Create and prepare the form
    this.formMain = this.fb.group({
      userName: [null, [Validators.required, AuthorizationFormValidators.validEmail]],
      tempPassword: [null, [Validators.required]],
      password: [null, [
        Validators.required,
        AuthorizationFormValidators.oneLowercase,
        AuthorizationFormValidators.oneUppercase,
        AuthorizationFormValidators.oneDigit,
        AuthorizationFormValidators.specialCharacters,
        AuthorizationFormValidators.eightCharacters,
      ]],
      passwordConfirm: [null, [Validators.required]],
    }, {validator: AuthorizationFormValidators.samePasswords});

    // Hide inputs based on query parameter
    const hideInputs = this.route.snapshot.queryParams['hideInputs'] === 'true';
    if (hideInputs) {
      this.showingResetForm = false;
    }
  }


  /**
   * Show the rest of the form on the page
   */
  showPasswordResetForm() {
    // Set boolean to update UI
    this.showingResetForm = true;
    this.showCheckEmailMessage = true;
  }

  /** Hide error messages in the UI */
  resetErrorMessages() {
    this.error = null;
  }

  /**
   * Send a temporary password to the borrower's email address
   */
  sendTemporaryPassword(form: FormGroup): void {

    const usernameField = form.get('userName');

    if (usernameField.invalid) return;
    this.waiting = true;
    this.resetErrorMessages();
    this.api.userAccount.sendTemporaryPassword(usernameField.value).subscribe((serverResponse) => {
      this.waiting = false;
      if (serverResponse && serverResponse.response) {
        // Email was successfully sent
        this.showPasswordResetForm();
      } else {
        // Show errors in UI
        this.formMain.get('userName').setErrors({notFound: true});
      }
    }, () => {
      this.error = `There was a problem sending a temporary password. Please try again.`;
      this.waiting = false;
    });
  }
  
  /**
   * Send a request to reset the password
   * @param userAccountName
   * @param data
   */
  resetPassword(form: FormGroup): void {

    if (form.invalid) return;
    const data: ResetPasswordFormData = form.value;
    this.resetErrorMessages();
    
    // Prepare the request data
    const resetData: ResetRequest = {
      authenticationRequestViewModel: {
        authentication: { 
          userAccountName: data.userName,
          newPassword: data.password,
          password: data.tempPassword,
        },
        authenticationRequestType: AuthenticationRequestTypeEnum.UpdateAccount
      }
    };
    
    // Send to API
    this.waiting = true;
    this.api.userAccount.resetPassword(resetData).subscribe((apiResponse) => {
      if (apiResponse && apiResponse.response && apiResponse.response.succeeded) {
        this.router.navigate(['/login']);
      } else if (apiResponse && apiResponse.response && apiResponse.response.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
        this.error = `Your account has been locked. Please contact your loan officer to unlock your account.`;
      } else {
        this.error = `An error occurred. Please check your password and try again.`;
      }
      this.waiting = false;
    }, () => {
      this.error = `An error occurred. Please check your password and try again.`;
      this.waiting = false;
    });
  }
  
}
