import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, of } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ApiService } from '$api';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';
import { LoanUtils } from '../utils/loan-utils';
import { AuthService } from './auth.service';
import {
  IBorrowerViewModel,
  ILoanViewModel,
  ConsentStatusEnum,
  ICPOSSrvApiResponse,
  DocDeliveryTypeEnum,
  DisclosureStatusEnum
 } from 'src/app/shared/models';

export interface EconsentResult {
  showStep?: number;
  borrowersToDecline?: IBorrowerViewModel[];
  noChanges?: boolean;
  wasEconsentSuccessful?: boolean;
  isCoborrowerTurn?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EconsentService {
  borrowersAccepted?: IBorrowerViewModel[];
  private _numberOfConsentingBorrowersFromLastEconsentUpdate$: BehaviorSubject<number>;
  private disclosureCreatedEnums = [DisclosureStatusEnum.DisclosuresCreated, DisclosureStatusEnum.DisclosuresSigned, DisclosureStatusEnum.WaitingToBeSigned];

  /** Observable for keeping track of whether or not all borrowers have consented*/
  private _haveAllRequiredborrowersConsented$: ReplaySubject<boolean>;
  /** Observable for keeping track of whether or not at least one borrower has consented*/
  private _atLeastOneBorrowerEconsented$: ReplaySubject<boolean>;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private api: ApiService,
    private auth: AuthService,
  ) {
    //Initialize haveAllRequiredborrowersConsented$ when service is loaded
    this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
      map((loan) => this.haveAllBorrowersEconsented(loan)),
      take(1))
      .subscribe((haveAllBorrowersEconsented: boolean) => {
        this.haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
    });
  }

  /**
   *
   */
  get haveAllRequiredborrowersConsented$(): ReplaySubject<boolean> {
    // This ensures that a new replay subject is used between different user sessions,
    // which prevents values from previous user session from being used in a subsequent session.
    const bufferReplaySize = 1;
    if (!this._haveAllRequiredborrowersConsented$) {
      this._haveAllRequiredborrowersConsented$ = new ReplaySubject<boolean>(bufferReplaySize);
      this.auth.attachPreLogOutAction(() => {
        // Close and remove the current replay subject
        this._haveAllRequiredborrowersConsented$.complete();
        this._haveAllRequiredborrowersConsented$ = undefined;
        //attachPreLogOutAction requires a non-empty observable
        return of(1);
      });

      this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
        map((loan) => this.haveAllBorrowersEconsented(loan)),
        take(1))
        .subscribe((haveAllBorrowersEconsented: boolean) => {
          this._haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
       });

    }
    return this._haveAllRequiredborrowersConsented$;
  }

  /**
   *
  */
  get atLeastOneBorrowerEconsented$(): ReplaySubject<boolean> {
    // This ensures that a new replay subject is used between different user sessions,
    // which prevents values from previous user session from being used in a subsequent session.
    const bufferReplaySize = 1;
    if (!this._atLeastOneBorrowerEconsented$) {
      this._atLeastOneBorrowerEconsented$ = new ReplaySubject<boolean>(bufferReplaySize);
      this.auth.attachPreLogOutAction(() => {
        // Close and remove the current replay subject
        this._atLeastOneBorrowerEconsented$.complete();
        this._atLeastOneBorrowerEconsented$ = undefined;
        //attachPreLogOutAction requires a non-empty observable
        return of(1);
      });

      var atLeastOneBorrowerEconsentedOnLoan$ = this.api.getApiStoreData(this.api.select.megaLoan$)
        .pipe(
          map((loan) => this.atLeastOneBorrowerEconsented(loan)),
        );

      atLeastOneBorrowerEconsentedOnLoan$
        .pipe(
          withLatestFrom(this.numberOfConsentingBorrowersFromLastEconsentUpdate$),
          map(([atLeastOneBorrowerEconsentedOnLoan, _]) => {
            return atLeastOneBorrowerEconsentedOnLoan || this.numberOfConsentingBorrowersFromLastEconsentUpdate$.value > 0;
          }))
        .subscribe((atLeastOneBorrowerEconsented: boolean) => {
          if(this._atLeastOneBorrowerEconsented$){
          this._atLeastOneBorrowerEconsented$.next(atLeastOneBorrowerEconsented);
        }});
    }
    return this._atLeastOneBorrowerEconsented$;
  }

  get numberOfConsentingBorrowersFromLastEconsentUpdate$(): BehaviorSubject<number> {
    if (!this._numberOfConsentingBorrowersFromLastEconsentUpdate$) {
      this._numberOfConsentingBorrowersFromLastEconsentUpdate$ = new BehaviorSubject<number>(0);
      this.auth.attachPreLogOutAction(() => {
        this._numberOfConsentingBorrowersFromLastEconsentUpdate$.complete();
        this._numberOfConsentingBorrowersFromLastEconsentUpdate$ = undefined;
        return of(1);
      });
    }

    return this._numberOfConsentingBorrowersFromLastEconsentUpdate$;
  }

  /**
   * Determination based on loan
   * @param loan
   */
  atLeastOneBorrowerEconsented(loan: ILoanViewModel): boolean {
    const borrowers = LoanUtils.getBorrowersByCurrentUserId(loan, +this.settings.userId);
    return borrowers.some((borrower) => {
      return this.hasBorrowerEconsented(borrower);
    }, false);
  }

  /**
   * Check if all required borrowers for a 1003 have eConsented on load
   * We only check this once on service load and when loan refreshes
   * @param loan
   */
  haveAllBorrowersEconsented(loan: ILoanViewModel): boolean {
    const borrowersEconsented = LoanUtils.getBorrowersEconsented(loan);
    this.borrowersAccepted = borrowersEconsented;
    const totalBorrowerCountRequired = LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1;
    return borrowersEconsented.length >= totalBorrowerCountRequired;
  }
  /**
   * Take an array of borrowers and save the eConsent status for each
   * @param borrowers Array of IBorrowers
   * @param acceptConsent Is eConsent being accepted
   * @param disclosureStatus Disclosure status of the loan
   * @param docDelivery DocDelivery of the loan
   * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
   */
  saveEconsentStatus(borrowers: IBorrowerViewModel[], showCommunicationConsentText: boolean, disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): Observable<any> {
    const callTimestamp = new Date().getTime().toString();
    const url = `${this.settings.apiUrl}/api/ConsumerSiteService/BorrowerEConsent`;
    const params = {
      callTimestamp,
      userId: 'User',
    };

    var consentingBorrowers = borrowers.filter(borrower => {
      return borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
    });

    // Create a API request for each borrower that needs to be saved
    const httpRequests = borrowers.map(borrower => {
      return this.http.post<ICPOSSrvApiResponse<null>>(url, {
        borrowerViewModel: borrower,
        documentDeliveryType: this.getDocDelivery(disclosureStatus, docDelivery, numberOfConsentingBorrowers),
        loanId: this.settings.loanId,
        communicationConsentTextAvailable: showCommunicationConsentText
      }, {params}).pipe(map((response) => {
        if (response && response.errorMsg) {
          throw new Error(response.errorMsg);
        } else {
          return response;
        }
      }));
    });

    return combineLatest(httpRequests)
      .pipe(tap(() => {
        // As a side affect, update the number of consenting borrowers
        if (this.settings.config['cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent'].value == true) {
          this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
            map((loan) => LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1),
            take(1)
          ).subscribe((borrowersRequired: number) => {
            this.borrowersAccepted = this.borrowersAccepted.concat(consentingBorrowers);
            if (this.borrowersAccepted.length >= borrowersRequired) {
              this.haveAllRequiredborrowersConsented$.next(true);
            }
          });
        } else {
          this.numberOfConsentingBorrowersFromLastEconsentUpdate$.next(numberOfConsentingBorrowers);
        }
      }));
  }

  /**
   * Decline consent for all provided borrowers
   * @param borrowers
   */
  declineEconsentForBorrowers(borrowers: IBorrowerViewModel[], disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): Observable<any> {
    // Update the borrowers before sending to back-end
    let borrowersToDecline = this.updateEconsentDate(borrowers);
    borrowersToDecline = this.setEconsentStatus(borrowersToDecline, ConsentStatusEnum.Decline);
    // Return update call
    return this.saveEconsentStatus(borrowersToDecline, false, disclosureStatus, docDelivery, numberOfConsentingBorrowers);
  }

  /**
   * Update the signed dates
   * @param borrowers Array of borrowers to update
   */
  updateEconsentDate(borrowers: IBorrowerViewModel[]): IBorrowerViewModel[] {
    return borrowers.map(borrower => {
      // Update the signed dates
      borrower.eConsent.statusAt = new Date().toISOString();
      return borrower;
    });
  }

  /**
   * Update the status
   * @param borrowers Array of borrowers to update
   * @param status New status to assign to all borrowers
   */
  setEconsentStatus(borrowers: IBorrowerViewModel[], status: ConsentStatusEnum): IBorrowerViewModel[] {
    return borrowers.map(borrower => {
      // Update the signed dates
      borrower.eConsent.consentStatus = status;
      return borrower;
    });
  }

  /**
   * Check if a single borrower has eConsented
   * @param borrower
   */
  hasBorrowerEconsented(borrower: IBorrowerViewModel): boolean {
    return !!borrower
      && !!borrower.eConsent
      && borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
  }

  /**
   * Set docDelivery in order to disclsoure status
   * @param disclosureStatus Disclosure status of the loan
   * @param docDelivery DocDelivery of the loan
   * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
   */
  getDocDelivery(disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): DocDeliveryTypeEnum {
    if (this.disclosureCreatedEnums.some(x => x == disclosureStatus)) {
      return docDelivery;
    } else {
      return numberOfConsentingBorrowers > 0 ? DocDeliveryTypeEnum.Electronic : DocDeliveryTypeEnum.Mail
    }
  }

  /**
   * Check number of consenting borrowers
   * @param borrowers one or more borowers
   */
  getNumberOfConsentingBorrowers(borrowers: IBorrowerViewModel[]) {
    return borrowers.filter(borrower => { return borrower.eConsent && borrower.eConsent.consentStatus == ConsentStatusEnum.Accept; }).length;
  }

  /*
   * reset _haveAllRequiredborrowersConsented$ observable,
   * so that previous buffered data/event doesn't show
   * on the next new loan application's e-consent page everytime a
   * new loan application is started in a single session
   */
  resetAllRequiredBorrowerConsentedSubject() {
    this._haveAllRequiredborrowersConsented$.complete();
    this._haveAllRequiredborrowersConsented$ = undefined;
  }

  /*
   * reset the _atLeastOneBorrowerEconsented$
   * and _numberOfConsentingBorrowersFromLastEconsentUpdate$ obsevables,
   * so that previous buffered data/event doesn't show on the next new loan application's
   * e-consent section everytime a new loan application is started in a single session
   */
  resetAtLeastOneBorrowerEconsentedSubject() {
    this._atLeastOneBorrowerEconsented$.complete();
    this._atLeastOneBorrowerEconsented$ = undefined;
    this._numberOfConsentingBorrowersFromLastEconsentUpdate$.complete();
    this._numberOfConsentingBorrowersFromLastEconsentUpdate$ = undefined;
  }
}
