import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import * as mixpanel from 'mixpanel-browser';
import { Title } from '@angular/platform-browser';
import { environment } from '$env';
import { Mixpanel } from 'mixpanel-browser';
import { AppSettings } from '../app.settings';
import { UIStoreService } from '$ui';
import { ICPOSAppConfig } from '../models/extended/viewmodels';

// npm install --save @types/mixpanel, npm install --save mixpanel-browser, npm install --save @types/mixpanel-browser

declare global {
  interface Window {
    mixpanel: Mixpanel;
    GOOGLE_TAG_MANAGER_ID: string;
  }
}

interface Options {
  mixpanel?: boolean;
  ga?: boolean;
  omniture?: boolean;
  googletm?: boolean;
}

const scriptUrls = {
  ga: '',
  omniture: '',
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  /** Reference to mixpanel object on window */
  public mixpanel: Mixpanel = window.mixpanel;
  private options: Options = {};

  constructor(
    private router: Router,
    private title: Title,
    private settings: AppSettings,
    private route: ActivatedRoute,
    private ui: UIStoreService,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(r => {
          while (r.firstChild) {
            r = r.firstChild;
          }
          return r;
        }),
        filter(r => r.outlet === 'primary'),
        mergeMap(r => r.data),
      )
      .subscribe(data => {
        if (!data.disableAutoTrack) {
          setTimeout(() => this.routeTrack(this.router.url), 100);
        }
      });
  }

  /**
   * Start tracking analytics
   * @param options
   */
  public start(options: Options) {
    this.options = options;
    if (options.mixpanel) {
      // Prod: 'f526969886702f6b20f12637430a8105'
      // Dev: 'e70937746dbfc4acd3fbe8452df98497';
      mixpanel.init(this.settings.mixpanelKey || 'e70937746dbfc4acd3fbe8452df98497');
    }
    if (options.ga) {
      this.scriptLoad(scriptUrls.ga);
    }
    if (options.omniture) {
      this.scriptLoad(scriptUrls.omniture);
    }
    if (options.googletm) {
      this.ui.select.config$.pipe(take(2)).subscribe(configs => this.scriptLoadGoogleTM(configs));
    }
  }

  /**
   * Load a script
   * @param url
   * @param callback
   */
  private scriptLoad(url: string, callback?: () => {}) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (callback) {
      script.onload = callback; // After load, callback
    }
    document.head.appendChild(script);
  }

  /**
   * Load Google Tag Manager scripts
   * @param configs CPOS configuration
   */
  private scriptLoadGoogleTM(configs: Record<string, ICPOSAppConfig>) {
    let googletmId: string = configs['clover.global.analytics.googletm.id']
      && configs['clover.global.analytics.googletm.id'].value
      || null;
    if (googletmId && this.isValidGoogleTMId(googletmId)) {
      // Pass parameter into script using global var to avoid XSS issue
      window['GOOGLE_TAG_MANAGER_ID'] = googletmId;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(document.createTextNode("(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',window['GOOGLE_TAG_MANAGER_ID']);"));
      document.head.appendChild(script);
    }
  }

  /**
   * Track an event
   * @param eventName
   * @param data
   */
  public trackEvent(eventName: string, data: any = {}) {
    // console.log('trackEvent', eventName, data, JSON.stringify(data), JSON.parse(JSON.stringify(data)));
    if (!eventName) {
      console.warn('Event Name is missing from mixpanel event');
    }
    if (this.options.mixpanel) {
      mixpanel.track(eventName, {
        User: 'Borrower',
        Experience: 'Consumer',
        ...data,
      });
    }
  }

  /**
   * Track route changes
   * Automatically add global and path properties
   * @param url
   */
  public routeTrack(url: string) {
    let title = this.title
      .getTitle()
      .split('-')[0]
      .trim();
    const data: Record<string, any> = {
      env: environment.production ? 'prod' : 'dev',
      url: url,
    };

    // Is on application section
    if (url.indexOf('application') !== -1) {
      title += ' BLC';
      data.flow = 'L';
      // Is on dashboard section
    } else if (url.indexOf('dashboard') !== -1) {
      title += ' BDC';
      data.flow = 'D';
      // Everywhere else
    } else {
      title += ' BC';
    }

    this.trackEvent(title, data);
  }

  /**
   * Maps the currently logged in user with the Alias Id created during registration - Feature 378152
   * @param uniqueId
   */
  public mixpanelIdentify(uniqueId: string) {
    mixpanel.identify(uniqueId);
  }

  /**
   * To allow for easy debugging in the dev console. This functionality will only be restricted to the Dev and QA Environments - Feature 378152
   */
  public mixpanelSetDebugTrue(value: boolean) {
    mixpanel.set_config({ debug: value });
  }


  /**
* Resets the distinct_id and removes existing super properties - Feature 378152
*/
  public mixpanelReset() {
    mixpanel.reset();
  }


  /**
   * Associate a uniqueID with an existing mixpanel profile
   * @param uniqueId
   */
  public mixpanelAlias(uniqueId: string) {
    mixpanel.alias(uniqueId);
  }

  /**
   * Identify the user to mixpanel with unique and consistent id
   * @param uniqueId
   */
  public mixpanelPeople(props: Record<string, any>) {
    // console.log('mixpanelIdentify', uniqueId);
    mixpanel.people.set(props);
    // mixpanel.people.set({'User': 'Borrower'});
  }

  /**
   * Set global super properties needed by mixpanel
   */
  public mixpanelSuperProps(props: Record<string, any>) {
    mixpanel.register(props);
  }

  /**
   * 
   * @param property Unregister the property which was registered earlier
   */
  public removeMixPanelProperty(property: string) {
    mixpanel.unregister(property);
  }

  /**
   * Check if provided value is valid Google Tag Manager ID
   * @param googletmId Google Tag Manager ID
   */
  private isValidGoogleTMId(googletmId: string) {
    return googletmId !== null && googletmId !== undefined
      && googletmId.length > 0
      // Google Tag Manager ID must be in form GTM-XXXX
      && /^GTM-([\w]+)$/gi.test(googletmId);
  }
}
