 /* tslint:disable:no-bitwise */

    /**
     * *
     * 'evaluate' creates a Conditional that provides a fluid syntax with ifTrue or ifFalse methods
     */
    export function evaluate(bool: boolean): Conditional {
        return new Conditional(bool);
    }

    // use for ifTrue or ifFalse as required
    export const trueBoolFunc = () => true;
    export const falseBoolFunc = () => false;

    export type BooleanFunc = {
        (...args: any[]): boolean;
        ruleName?: string;
    };

    // DO NOT EXPORT, Conditional is only created via calls to evaluate and itself
    class Conditional {
        constructor(readonly state: boolean) {
        }
            
        ifTrue = (func: BooleanFunc): Conditional => {
            return this.state ? new Conditional(func()) : new Conditional(false);
        }

        ifFalse = (func: BooleanFunc): Conditional => {
            return !this.state ? new Conditional(func()) : new Conditional(true);
        }
    }
    /**
    * *
    * 'any' returns true if any one of the input parameters are true
       ex
    */
    export function any(...bools: boolean[]): boolean {
        for (const val of bools) {
            if (val) {
                return true;
            }
        }
        return false;
    }

    /**
   * *
   * 'all' returns true if all of the input parameters are true
   */
    export function all(...bools: boolean[]): boolean {
        for (const val of bools) {
            if (!val) {
                return false;
            }
        }
        return true;
    }

    /**
    * *
    * 'isNull' is modeled after SQL's IsNull the first argument is returned if NOT null,
       otherwise second argument is returned 
    */
    export function isNull<T>(value: T, defaultValue: T): T {
        return value ? value : defaultValue;
    }

    // exported rule types, more can be added as required - KEEP the rules GENERIC!!!
    export enum ValueTypeRule {
        None = 0,
        GreaterThanZero = 1,
        GreaterOrEqualZero = 2,
        NotOnlyWhitepace = 4,
        NotFalse = 8,
        EqualToZero = 16,
        NotZero = 32,
        ArrayIsNotEmpty = 64,
        OnlyWhitespace = 128,
        NotEmpty = 256
    }

    // IsDefined is used to validate Dates, objects and arrays that have value
    const IsDefined = 64;

    // Undefined is to handle undefined values that made it to the runRules method
    const Undefined = 128;

    // DO NOT EXPORT - mapping rules types to methods
    type RuleMap = { [valueTypeRule: number]: (val: any) => boolean };
    const ruleMap: RuleMap = {};
    ruleMap[ValueTypeRule.GreaterOrEqualZero] = val => +val >= 0;
    ruleMap[ValueTypeRule.GreaterThanZero] = val => +val > 0;
    ruleMap[ValueTypeRule.NotOnlyWhitepace] = val => !isNullOrWhitespace(val);
    ruleMap[ValueTypeRule.NotFalse] = val => !!val;
    ruleMap[ValueTypeRule.EqualToZero] = val => val === 0;
    ruleMap[ValueTypeRule.NotZero] = val => val !== 0;
    ruleMap[IsDefined] = _ => true; // validation check made as first step of runRules
    ruleMap[Undefined] = _ => {
        throw 'a value must be defined to run rule on it';
    };
    ruleMap[ValueTypeRule.ArrayIsNotEmpty] = val => isNotEmpty(val);
    ruleMap[ValueTypeRule.OnlyWhitespace] = val => hasOnlyWhitespace(val);
    ruleMap[ValueTypeRule.NotEmpty] = val => isNotEmpty(val);

    // DO NOT EXPORT - mapping data types to ValueTypeRule rules
    type RuleTypeMap = { [type: string]: ValueTypeRule[] };
    const ruleTypeMap: RuleTypeMap = {};
    ruleTypeMap['string'] = [ValueTypeRule.NotOnlyWhitepace];
    ruleTypeMap['number'] = [ValueTypeRule.GreaterThanZero, ValueTypeRule.GreaterOrEqualZero, ValueTypeRule.EqualToZero, ValueTypeRule.NotZero];
    ruleTypeMap['boolean'] = [ValueTypeRule.NotFalse];
    ruleTypeMap['Date'] = [IsDefined];
    ruleTypeMap['object'] = [IsDefined];
    ruleTypeMap['undefined'] = [Undefined];

    // default rules to use be used for data validation
    export const defaultTypeRules: ValueTypeRule = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;

    function hasRuleDefined(allRules: ValueTypeRule, rule: ValueTypeRule): boolean {
       
        return (allRules & rule) > 0;
      
    }

    // DO NOT EXPORT - to be only used internally
    function runRules(value: any, valueTypeRule: ValueTypeRule): boolean {
        // check first if value is defined
        if (!isDefined(value)) {
            return false;
        }

       
        // always bit OR the default checks to the rule type
        valueTypeRule |= IsDefined | Undefined;
       

        // get the type
        const valueType = typeof value;

        // lookup the ValueTypeRule rules array
        const ruleTypeArray = ruleTypeMap[valueType];

        for (const ruleType of ruleTypeArray) {
            // if a requested rule fails, return false
            if (hasRuleDefined(valueTypeRule, ruleType) && !ruleMap[ruleType](value)) {
                return false;
            }
        }
        return true;
    }

    /**
     *
     * isNotEmpty is used to validate content for strings, arrays, and basically all data types
     */
    export function isNotEmpty<T>(value: T | T[]): boolean {
        return !isEmpty(value);
    }

    /**
    *
    * isEmpty is used to determine null object, empty string and arrays
    */
    export function isEmpty<T>(value: T | T[]): boolean {
        return !isDefined(value) || ((Array.isArray(value) || typeof value === 'string') && value.length === 0);
    }

    /**
    *
    * isDefinedAndHasValue can be used for strings, arrays, and basically any data type
    */
    export function isDefinedAndHasValue<T>(value: T | T[], valueTypeRules = defaultTypeRules): boolean {
        const hasValue = isNotEmpty(value) && runRules(value, valueTypeRules);
        return hasValue;
    }

    /**
     *
     * isNullOrWhitespace is used to determine whether a string is undefined or has a meaningless value
     */
    const allSpacesRegEx = new RegExp('/\S/');
    export function isNullOrWhitespace(val: string): boolean {
        return !val || allSpacesRegEx.test(val);
    }

    export function hasOnlyWhitespace(val: string): boolean {
        return allSpacesRegEx.test(val);
    }

    /**
     *
     * isDefined is used to determine whether T is actually T
     */
    export function isDefined<T>(value: T | undefined | null): value is T {
        return value !== undefined && value !== null;
    }

    /**
     *
     * getPropertyValue is used to access a property values by name in a typesafe manner
     */
    export function getPropertyValue<T, K extends keyof T>(obj: T, key: K) {
        return obj[key];
    }

    /**
     *
     * propertyIsDefined is a type safe way to determine that both an object and its named property are defined
     */
    export function propertyIsDefined<T, PropertyName extends keyof T>(value: T, propertyName: PropertyName): boolean {
        return value && isDefined<T[PropertyName]>(value[propertyName]);
    }

    /**
    *
    * allPropertiesAreDefined is a type safe way to determine that both an object and all of its named properties are defined
    */
    export function allPropertiesAreDefined<T, PropertyName extends keyof T>(value: T, ...propertyNames: PropertyName[]): boolean {
        for (const propertyName of propertyNames) {
            if (!propertyIsDefined(value, propertyName)) {
                return false;
            }
        }
        return true;
    }

    /**
    *
    * anyPropertyIsDefined is a type safe way to determine that both an object and at least one of its named properties are defined
    */
    export function anyPropertyIsDefined<T, PropertyName extends keyof T>(value: T, ...propertyNames: PropertyName[]): boolean {
        for (const propertyName of propertyNames) {
            if (propertyIsDefined(value, propertyName)) {
                return true;
            }
        }
        return false;
    }

    export function getNumericValue(value?: number | string, defaultValue = 0): number {
        return (isDefined(value) && +value) ? +value : defaultValue;
    }

    /**
    *
    * propertyHasValue is a type safe way to determine that the object is defined and its property is both defined and passes the input ValueTypeRule(s),
    * defaultTypeRules = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;
    */
    export function propertyHasValue<T, PropertyName extends keyof T>(value: T, propertyName: PropertyName, valueTypeRule = defaultTypeRules): boolean {
        return propertyIsDefined(value, propertyName) && runRules(value[propertyName], valueTypeRule);
    }

     /**
    *
    * grandChildPropertyHasValue is a type safe way to determine a child object and its property are defined 
    * its property is both defined and passes the input ValueTypeRule(s),
    * defaultTypeRules = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;
    */
    export function childObjectPropertyHasValue<T, ChildObjectName extends keyof T>(value: T, childObjectName: ChildObjectName, grandChildPropertyName: keyof T[ChildObjectName], valueTypeRule = defaultTypeRules): boolean {
        return propertyHasValue(value, childObjectName, valueTypeRule) && propertyHasValue(value[childObjectName], grandChildPropertyName, valueTypeRule);
    }

    /**
     * safeComparePropertyWithMatchingValue: is a type safe way to determine object's property is safe to access and can do comparison with matching value
     * @param value
     * @param propertyName
     * @param valueToMatch
     */
    export function safeComparePropertyWithMatchingValue<T, PropertyName extends keyof T>(value: T, propertyName: PropertyName, valueToMatch: T[PropertyName]): boolean {
        return propertyIsDefined(value, propertyName) && value[propertyName] === valueToMatch;
    }
    
    /**
    *
    * allPropertiesHaveValue is a type safe way to determine that the object is defined and all its properties are both defined and pass the input ValueTypeRule(s)
    */
    export function allPropertiesHaveValue<T, PropertyName extends keyof T>(value: T, valueTypeRule: ValueTypeRule, ...propertyNames: PropertyName[]): boolean {
        for (const propertyName of propertyNames) {
            if (!propertyHasValue(value, propertyName, valueTypeRule)) {
                return false;
            }
        }
        return true;
    }
    /**
    *
    * anyPropertyHasValue is a type safe way to determine that the object is defined if any its properties are defined and pass the input ValueTypeRule(s)
    */
    export function anyPropertyHasValue<T, PropertyName extends keyof T>(value: T, valueTypeRule: ValueTypeRule, ...propertyNames: PropertyName[]): boolean {
        for (const propertyName of propertyNames) {
            if (propertyHasValue(value, propertyName, valueTypeRule)) {
                return true;
            }
        }
        return false;
    }

    export function runRegexForMatches(sectionScript: string, regex: RegExp): string[] {
        const matches: string[] = [];
        let match;
        while (match = regex.exec(sectionScript)) {
            matches.push(match[1]);
        }
        return matches;
    }

 /* tslint:enable:no-bitwise */
