<div class="row summary-group mb-4" *ngIf="asset">
  <div class="d-none d-md-block col-md-2 group-icon">
    <!-- 2009 URLA icon
    <i aria-hidden="true" class="cvi cvi-application-summary"  *ngIf="assetType === null"></i>
        -->
    <!-- 2020 URLA icon -->
    <ng-container [ngSwitch]="assetType">
      <i aria-hidden="true" class="cvi cvi-bank-amp--financial-assets" *ngSwitchCase="AssetType.Financial"></i>
      <i aria-hidden="true" class="cvi cvi-gifts-or-grants" *ngSwitchCase="AssetType.Gifts"></i>
      <i aria-hidden="true" class="cvi cvi-other-assets" *ngSwitchCase="AssetType.Other"></i>
      <i aria-hidden="true" class="cvi cvi-application-summary" *ngSwitchDefault></i>
    </ng-container>
  </div>
  <div class="col-md-10">
    <div class="summary-page">
      <div class="heading">
        <!-- 2009 URLA title -->
        <ng-container *ngIf="assetType === null">
          <h3 class="section-heading">{{asset?.institiutionContactInfo?.companyName | htmlRemove}}</h3></ng-container>
        <!-- 2020 URLA title -->
        <ng-container [ngSwitch]="assetType">
          <ng-container *ngSwitchCase="AssetType.Financial">
            <h3 class="section-heading">{{asset?.institiutionContactInfo?.companyName | htmlRemove}}</h3></ng-container>
          <ng-container *ngSwitchCase="AssetType.Gifts">
            <h3 class="section-heading">Gifts & Grants</h3></ng-container>
          <ng-container *ngSwitchCase="AssetType.Other">
            <h3 class="section-heading">Other Assets</h3></ng-container>
          <ng-container *ngSwitchDefault>
            <h3 class="section-heading">Financial Asset</h3></ng-container>
        </ng-container>

        <div class="right-info">
          <div *ngIf="asset.voaBalanceDate"><i class="fa fa-lock" aria-hidden="true"></i> Linked</div>
          <div class="edit ms-3" *ngIf="canEdit">
            <a (click)="edit.emit(assetType)" (keydown.enter)="edit.emit(assetType)" tabindex="0" appTrack="Asset edit button clicked">
              <i class="fa fa-pencil" aria-hidden="true"></i> Edit
            </a>
          </div>
        </div>
      </div>
      <div class="values">
        <ul>
          <li *ngIf="asset?.assetType !== null && dataFields">
            {{ getAssetTypeDisplay() }}
            <span *ngIf="assetType === AssetType.Other && ( asset.assetType === 14 || asset.assetType === 22 || asset.assetType ===23 ) && asset.description" class="ms-1">({{ asset.description }})</span>
          </li>
          <li *ngIf="assetType === AssetType.Gifts && getRelationshipTypeDisplay()">
            {{ getRelationshipTypeDisplay() }}
            <span *ngIf="asset.relationshipType === 16 && asset.description" class="ms-1">({{ asset.description }})</span>
          </li>
          <li *ngIf="asset?.monthlyAmount !== null">
            <span>Balance:&nbsp;</span>
            {{ asset.monthlyAmount | currency }}
          </li>
          <li *ngIf="asset?.ownerId !== null && asset?.ownerId !== 'joint' && dataFields">
            <ng-container *ngFor="let field of dataFields['borrowers']">
              <ng-container *ngIf="field.value === asset.ownerId">
                {{ field.label }}
              </ng-container>
            </ng-container>
          </li>
          <li *ngIf="asset?.ownerId === 'joint'">
            Joint Account
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
