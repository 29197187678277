import { AppSettings } from './../../../../shared/app.settings';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ILoanViewModel, ICPOSAppConfig, URLAFormTypeEnum } from 'src/app/shared/models';
import { set } from 'lodash';
import { mapPages, getConfigValue, mapSectionPageContents } from '../utils/form-builder.utils';
import getClientCustomizations from '../utils/client-customizations';

@Injectable({
  providedIn: 'root',
})
export class LoanModelService {
  constructor(private settings: AppSettings) {}

  /**
   * Convert loan center dot notation into a proper object
   * @param config
   */
  public loanCenterConfigToObject(config: Record<string, ICPOSAppConfig>) {
    const result: any = {};
    Object.keys(config).forEach(key => set(result, key, config[key].value));
    return result;
  }

  /**
   * Map override configuration from the backend to replace local configuration
   * @param sections
   * @param loanCenterConfig
   */
  public applyLoanCenterConfig(sections: CvFormBuilder.Section[], loanCenterConfig: Record<string, ICPOSAppConfig>): CvFormBuilder.Section[] {
    const configValue = <T>(key: string, defaultValue: T = null) => getConfigValue(loanCenterConfig, key, defaultValue);
    return mapPages(sections, (page, [sectionId, pageId]) => {
      try {
        switch (sectionId) {
          case 'personal':      return applyPersonalLoanCenterConfig(page, pageId);
          case 'property':      return applyPropertyLoanCenterConfig(page, pageId);
          case 'income':        return applyIncomeLoanCenterConfig(page, pageId);
          case 'assets':        return applyAssetsLoanCenterConfig(page, pageId);
          case 'declarations':  return applyDeclarationsLoanCenterConfig(page, pageId);
          case 'demographics':  return applyDemographicsLoanCenterConfig(page, pageId);
          case 'credit':        return applyCreditLoanCenterConfig(page, pageId);
          case 'review':        return applyReviewLoanCenterConfig(page, pageId);
          default:              return page;
        }
      } catch (err) {
        console.error('Error occurred when attempting to map custom configuration to static configuration', pageId, err);
        return page;
      }
    });

    function applyPersonalLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.personal.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.personal.intro.image', c.data.sectionImage),
            },
          }));
        case 'applying-with-spouse':
        case 'applying-with-coborrower':
          return mapSectionPageContents(page, (c: any) => {
            if (configValue('config.area.joint.credit.authorization.enabled', true)) return c;
            if (c.field !== 'loan.$$custom.jointCredit') return c;
            return null; // filter out loan.$$custom.jointCredit
          });
        case 'contact-information-spouse':
        case 'contact-information-coborrower':
          return mapSectionPageContents(page, (c: any) => {
            if (configValue('config.area.joint.credit.authorization.enabled', true)) return c;
            if (c.field !== 'loan.$$custom.jointCredit2') return c;
            return null; // filter out loan.$$custom.jointCredit2
          });
        default:
          return page;
      }
    }
    function applyPropertyLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.property.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.property.intro.image', c.data.sectionImage),
            },
          }));
        case 'property-located':
          return mapSectionPageContents(page, (c: any) => {
            const disablePurchaseAddress = configValue('config.area.avoid.respa.purchase', '').toLocaleLowerCase() === 'disablepropertyaddress';
            const disableRefinanceAddress = configValue('config.area.avoid.respa.refinance', '').toLocaleLowerCase() === 'disablepropertyaddress';
            
            if ((disablePurchaseAddress && c.id === 'purchase') || (disableRefinanceAddress && c.id === 'refinance')) {
              if (c.featureId === 'address-autocomplete' || c.field === 'loan.$$custom.loan.addressSubject.streetName' || c.field === 'loan.$$custom.loan.addressSubject.unitNumber') {
                return null; // filter out street and unit fields
              }
            }
            return c; 
          });
        case 'loan-amount-refi':
          return mapSectionPageContents(page, (c: any) => {
            if (configValue('config.area.avoid.respa.refinance', '').toLocaleLowerCase() === 'disableestimatedvalue' && c.field === 'loan.$$custom.loan.addressSubject.currentEstimatedValue') {
              return null;
            }
            return c;
          });
        default:
          return page;
      }
    }
    function applyIncomeLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.income.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.income.intro.image', c.data.sectionImage),
            },
          }));
        case 'other':
          return mapSectionPageContents(page, (c: any) => {
            if (configValue('config.area.alimony.disclaimer.enabled', true)) return c;
            if (c.id !== 'alimony-description') return c;
            return null; // filter out alimony description
          });
        default:
          return page;
      }
    }
    function applyAssetsLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.assets.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.assets.intro.image', c.data.sectionImage),
            },
          }));
        default:
          return page;
      }
    }
    function applyDeclarationsLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.declarations.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.declarations.intro.image', c.data.sectionImage),
            },
          }));
        default:
          return page;
      }
    }
    function applyDemographicsLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.demographics.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.demographics.intro.image', c.data.sectionImage),
              sectionBody: configValue('sys.conf.demographics.disclaimer.enabled', true)
                ? configValue('sys.conf.demographics.disclaimer', c.data.sectionBody)
                : null,
            }
          }));
        default:
          return page;
      }
    }
    function applyCreditLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.credit.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.credit.intro.image', c.data.sectionImage),
            },
          }));
        default:
          return page;
      }
    }
    function applyReviewLoanCenterConfig(page: CvFormBuilder.Page, pageId: string) {
      switch (pageId) {
        case 'start':
          return mapSectionPageContents(page, (c: any) => ({ ...c,
            data: { ...c.data,
              sectionTitle: configValue('clover.review.intro.title', c.data.sectionTitle),
              sectionImage: configValue('clover.review.intro.image', c.data.sectionImage),
            },
          }));
        default:
          return page;
      }
    }
  }

  /**
   *  Perform client specific modification of the wizard experience
   * @param sections
   * @param client
   */
  public applyClientCustomizations(sections: CvFormBuilder.Section[], urlaType: URLAFormTypeEnum, clientId: string) {
    const clientCustomizations = getClientCustomizations(clientId);
    return clientCustomizations(sections, urlaType);
  }

  /**
   * Add page level validators to their corresponding entries in the configuration
   * @param config
   */
  public applyFormBuilderValidators(sections: CvFormBuilder.Section[], validators: Record<string, CvFormBuilder.PageValidator>) {
    return mapPages(sections, page => ({ ...page,
      validator: validators[page.validatorId]
    }));
  }

  /**
   * Attach page level events to the config. Page events can override default behavior
   * @param config
   */
  public applyFormBuilderEvents(
    sections: CvFormBuilder.Section[],
    events: Record<string, (pageControl: CvFormBuilder.PageControl, form: FormGroup) => Promise<CvFormBuilder.Button>>,
  ) {
    return mapPages(sections, page => ({ ...page,
      events: {
        onNext: events[(page.eventIds || {} as any).onNext],
      },
    }));
  }

  /**
   * Modify the configuration to accomodate the non-spouse coborrower flow
   * @param config
   */
  public nonSpouseCoborrowerFlow(config: CvFormBuilder.Section[]) {
    // console.log(config);
    return config.map(section => {
      switch (section.sectionId) {
        case 'loan-purpose':
          Object.keys(section.pages).forEach(key => {
            const page = section.pages[key];
            page.content.forEach(content => {
              if (content.type === 'formField') {
                (<any>content).disabled = true;
              }
            });
          });
          break;
        case 'personal':
          break;
        // case 'property':
        //   Object.keys(section.pages).forEach(key => {
        //     if (key === 'guidPageC') {
              // const page = section.pages[key];
              // page.isLastPage = true;
        //     }
        //   });
        //   break;
            }
      return section;
    });
  }

  /**
   * Sets and unsets defaults needed by the loan model
   * This method should only fire once per loan
   * @param loan
   */
  public setDefaults(loan: ILoanViewModel) {
    // Set purchase/refi to null
    loan.loanPurposeType = null;
    // Purchase process
    loan.homeBuyingType = null;
    // Reset have RE agent
    loan.$$custom.haveREAgent = null;

    // Set is spouse on the loan default to null
    loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan = null;
    // Set phone number type to default to CELL
    loan.transactionInfo.borrowers.forEach(borrower => borrower.phones.forEach(phone => (phone.type = 1)));
    // Set ownership to null
    loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership = null;

    // Reset marital status to null, default is -1
    if (loan.transactionInfo.borrowers[0].maritalStatus === -1) {
      loan.transactionInfo.borrowers[0].maritalStatus = null;
    }

    // Default time at address for months to 0
    if (loan.$$custom.loan.borrowerPrimary.addressCurrent.timeAtAddressMonths === null) {
      loan.$$custom.loan.borrowerPrimary.addressCurrent.timeAtAddressMonths = 0;
    }
    if (loan.$$custom.loan.borrowerSecondary.addressCurrent.timeAtAddressMonths === null) {
      loan.$$custom.loan.borrowerSecondary.addressCurrent.timeAtAddressMonths = 0;
    }

    // Default is military to null
    loan.$$custom.isMilitary = null;
    loan.$$custom.isMilitarySpouse = null;

    loan.transactionInfo.borrowers[0].numberOfDependents = null;
    loan.transactionInfo.borrowers[1].numberOfDependents = null;
  }

  public isDobRequired(): boolean {
    try {
      // If FAM, make DoB fields required
      if (this.settings.clientId && this.settings.clientId.toLowerCase() === 'fam') {
        return true;
      }

      return false;
    } catch (err) {
      console.error(
        'Error occurred when attempting to map custom configuration to static configuration',
        err,
      );
    }
  }
}
