import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CounselingFormatTypeEnum, CounselingProviderTypeEnum, CounselingWorkshopTypeEnum, CPOSdateTypeEnum, CPOSEventsEnum, CPOSPageValidatorsEnum, PreferredLanguageTypeEnum } from 'src/app/shared/models';

export const personalPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Personal Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Personal',
          sectionTitle: `Let's learn a few things <strong>about you</strong>`,
          sectionImage: `/assets/img/application/personal.png`,
        },
      },
    ],
  }, // end page

  //#region Primary Borrower
  guidPageB: {
    title: 'Please confirm your <strong>name</strong> and <strong>contact information</strong>',
    titleShort: 'Contact Info',
    pageId: 'contact-information',
    validatorId: CPOSPageValidatorsEnum.tcpaDisclaimer,
    eventIds: {
      onNext: CPOSEventsEnum.contactInfo
    },
    ariaDescribedById: 'consent-info',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'First Name',
        field: 'loan.transactionInfo.borrowers[0].firstName',
        formFieldType: 'text',
        hint: 'Please enter your full legal name',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Middle Name',
        field: 'loan.transactionInfo.borrowers[0].middleName',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Last Name',
        field: 'loan.transactionInfo.borrowers[0].lastName',
        formFieldType: 'text',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Suffix',
        field: 'loan.transactionInfo.borrowers[0].suffix',
        formFieldType: 'mask-alpha',
        hint: 'Jr, Sr, I, II, III',
        maxlength: 4,
        validators: {
          // required: true,
          maxLength: 4,
        },
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Email Address',
        field: 'loan.transactionInfo.borrowers[0].email',
        formFieldType: 'email',
        disabled: true,
        validators: {
          required: true,
          email: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Phone Number',
        field: 'loan.transactionInfo.borrowers[0].phones[0].number',
        formFieldType: 'phoneNumber',
        columns: 7,
        validators: {
          required: true,
          minLength: 10,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[0].phones[0].type',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Cell',
            value: 1,
          },
          {
            label: 'Home',
            value: 0,
          },
          {
            label: 'Work',
            value: 2,
          },
        ],
        columns: 5,
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'tcpa-disclaimer',
        // visible: {
        //   rules: [
        //     {
        //       field: 'loan.isLegalDisclaimerConfirmed',
        //       operator: RuleExprOp.EQ,
        //       value: false,
        //     },
        //   ],
        // },
        // validatorId: PageValidators.tcpaDisclaimer
      },
    ],
  }, // end page

  //#region Primary Military
  guidPageC: {
    title:
      'Are you currently <strong>active in the military, a veteran,</strong> and/or <strong>a surviving spouse</strong>?',
    titleShort: 'Military/Veteran',
    htmlTitle: 'Active military, veteran, and/or a surviving spouse',
    htmlTitlePunctuation: true,
    pageId: 'is-military',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What qualifies as military?`,
          body: `
            <p>Why are we asking this question? The US government has established mortgage programs that are only available to individuals who are currently serving, or who previously served in the military.</p>
            <h3>Active Duty</h3>
            <p>This applies to individuals currently working full-time for any of the branches of the U.S. Armed Forces and subject to deployment at any time.</p>
            <h3>Retired / Discharged / Veteran</h3>
            <p>Select this if you formerly served in the U.S Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and/or Navy) and have been discharged or retired.</p>
            <h3>Reserves</h3>
            <p>Indicates that you are a reservist with any of the U.S. Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and/or Navy).</p>
            <h3>National Guard</h3>
            <p>This option is used if you are with the Army National Guard, or Air National Guard (Air Force).</p>
            <h3>Surviving Spouse</h3>
            <p>This applies to surviving spouses of deceased veterans.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Are you Military-Affiliated?',
        // standalone: true,
        field: 'loan.$$custom.isMilitary', // isMilitary
        class: 'isMilitary',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for active military or veteran <strong>is required</strong>.',
        formFieldData: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<p class="text-center"><img src="assets/img/flag.png" alt=""/></p><h2 class="text-center">Thank you for your service!</h2>',
        visible: {
          rules: [{ field: 'loan.$$custom.isMilitary', operator: RuleExprOp.EQ, value: true }],
        },
      },
    ],
  }, // end page

  guidPageC2: {
    title: 'What is your <strong>current military status</strong>?',
    htmlTitle: 'Military Status',
    pageId: 'military-status',
    eventIds: {
      onNext: CPOSEventsEnum.militaryStatus
    },
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `About military statuses`,
          body: `
            <h3>Active Duty</h3>
            <p>If you're working full-time for the U.S. Armed Forces which includes: Air Force, Army, Coast Guard, Marine Corps, and Navy.</p>
            <h3>Retired / Discharged / Veteran</h3>
            <p>You formerly served in the U.S Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and Navy) and have been discharged or retired.</p>
            <h3>Reserves</h3>
            <p>You are a reservist with any of the U.S. Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and Navy).</p>
            <h3>National Guard</h3>
            <p>You are with the Army National Guard, or Air National Guard (Air Force).</p>
            <h3>Surviving Spouse</h3>
            <p>Surviving military spouses can sometimes receive veteran's disability compensation. This benefit is called Dependency and Indemnity Compensation (DIC), and it is paid on a monthly basis. DIC is available to a surviving military spouse (a widow or widower) and his or her dependent children.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Military service type',
        field: 'loan.transactionInfo.borrowers[0].militaryServiceType',
        formFieldType: 'iconGroup',
        isDeselectable: true,
        // dataField: 'df-42',
        // errorCustom not needed. The validatorId already provides a suitable message.
        formFieldData: [
          {
            label: 'Active Duty',
            value: 2,
          },
          {
            label: 'Retired / Discharged',
            value: 3,
          },
          {
            label: 'National Guard / Reserves',
            value: 4,
          },
        ],
        validators: {
          required: true,
        },
      },
      {
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[0].survivingSpouse',
        placeholder: `I'm a surviving spouse of a veteran`,
        formFieldType: 'checkbox',
        parentClass: 'text-center',
      },
    ],
  }, // end page
  //#endregion

  //#region Primary Address
  guidPageD: {
    title: 'Where do you <strong>currently live</strong>?',
    titleShort: 'Addresses',
    pageId: 'currently-live',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.streetName',
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
        placeholder: 'My mailing address is the same as my current address',
        formFieldType: 'checkbox',
      },
    ],
  }, // end page

  guidPageD2: {
    title: 'What is your <strong>mailing address</strong>?',
    pageId: 'mailing-address',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.streetName',
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerPrimary.addressMailing.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressMailing.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
    ],
  }, // end page

  guidPageE: {
    title: 'Do you <strong>rent</strong> or <strong>own</strong> at this address?',
    titleShort: 'Rent Or Own',
    pageId: 'rent-or-own',
    eventIds: {
      onNext: CPOSEventsEnum.timeAtAddressPrimary,
    },
    content: [
      // no street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="line-height-2x text-left"><strong>
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.cityName}}
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.stateName}}
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.streetName',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="text-left"><strong>
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.displayStreetName}}
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.displayUnitNumber}} <br/>

              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.cityName}}
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.stateName}}
              {{loan.$$custom.loan.borrowerPrimary.addressCurrent.zipCode}}
            </strong></span>
           </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.streetName',
              operator: RuleExprOp.NE,
              value: '',
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Current address ownership',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for rent or own at this address <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Own',
            value: 0,
          },
          {
            label: 'Rent',
            value: 1,
          },
          {
            label: 'Living Rent Free',
            value: 2,
          },
        ],
        validators: {
          required: true,
        },
      },

      {
        type: 'html',
        html: '<h3 class="text-center">How long have you lived here?</h3>',
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.timeAtAddressYears',
        formFieldType: 'number',
        placeholder: 'Years',
        columns: 6,
        maxlength: 2,
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.timeAtAddressMonths',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.monthlyRent',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        placeholder: 'Monthly Rent',
        // format: '1.2-2',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageE2: {
    title: 'What is your <strong>previous address</strong>?',
    pageId: 'previous-address',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="alert alert-info icon">We need a 2 year history of your residential address.</div>`,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.streetName',
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.cityName',
          statePath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.stateName',
          zipPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerPrimary.addressHistory.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
    ],
  }, // end page

  guidPageE3: {
    title: 'Did you <strong>rent</strong> or <strong>own</strong> at this address?',
    titleShort: 'Rent Or Own',
    pageId: 'prev-rent-or-own',
    content: [
      // no street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="text-left"><strong>
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.cityName}}
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.stateName}}
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.streetName',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="text-left"><strong>
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.displayStreetName}}
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.displayUnitNumber}}<br/>

              {{loan.$$custom.loan.borrowerPrimary.addressHistory.cityName}}
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.stateName}}
              {{loan.$$custom.loan.borrowerPrimary.addressHistory.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.streetName',
              operator: RuleExprOp.NE,
              value: '',
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'History address ownership',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.ownership',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for rent or own at this address <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Own',
            value: 0,
          },
          {
            label: 'Rent',
            value: 1,
          },
          {
            label: 'Living Rent Free',
            value: 2,
          },
        ],
        validators: {
          required: true,
        },
      },

      {
        type: 'html',
        html: '<h3 class="text-center">How long did you live there?</h3>',
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.timeAtAddressYears',
        formFieldType: 'number',
        placeholder: 'Years',
        columns: 6,
        validators: {
          // maxLength: 2,
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.timeAtAddressMonths',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.monthlyRent',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        formFieldType: 'currency',
        // format: '1.2-2',
        placeholder: 'Monthly Rent',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerPrimary.addressHistory.ownership',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion

  //#region Primary Dependents
  guidPageF: {
    title: 'How many <strong>dependents</strong> do you have?',
    titleShort: 'Dependents',
    pageId: 'dependents',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[0].numberOfDependents',
        placeholder: 'Number of dependents',
        formFieldType: 'select',
        formFieldData: [
          { label: '0', value: 0 },
          { label: '1', value: 1 },
          { label: '2', value: 2 },
          { label: '3', value: 3 },
          { label: '4', value: 4 },
          { label: '5+', value: 5 },
        ],
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Html>{
        id: 'dependents-warning',
        type: 'html',
        html: '<p>If child is less than 1 year old, enter 1</p>',
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].numberOfDependents', operator: RuleExprOp.GT, value: 0 }],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'dependents',
        data: { index: 0 },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Age of dependents',
        field: 'loan.transactionInfo.borrowers[0].agesOfDependents',
        formFieldType: 'text',
        hidden: true,
      },
    ],
  },
  //#endregion

  //#region Primary Alimony
  guidPageG: {
    title: 'Do you have to pay for <strong>Alimony, Child Support or Separate Maintenance</strong>?',
    titleShort: 'Alimony',
    pageId: 'has-alimony',
    analyticsId: 'Borrower pays for Alimony, Child Support or Sep. Maint.',

    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Have alimony?',
        // standalone: true,
        field: 'loan.$$custom.alimonyEtc.hasAlimony', // hasAlimony
        class: 'hasAlimony',
        formFieldType: 'iconGroup',
        // The validator does not actually check for no selection, so we need the errorCustom
        errorCustom: 'Selecting an option for alimony, child support or separate maintenance <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-left pb-4">Enter all that apply: </h3>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimony',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Alimony Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.alimonyMonthlyAmount',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimony',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Child Support Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.ChildSupportMonthlyAmount',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimony',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Separate Maintenance Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmount',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimony',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion
  //#region Homeownership Education/Counseling
  guidPageHEC: {
    title: '{{loan.transactionInfo.borrowers[0].firstName}}, have you completed homeownership education or housing counseling within the last 12 months?',
    titleShort: 'Homeownership Education/Housing Counseling',
    pageId: 'homeownershipeducation-housingcounseling',
    ariaDescribedById: 'homeownership-counseling',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Program Type',
        // standalone: true,
        field: 'loan.$$custom.programTypeBorrower',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Homeownership Education',
                value: 1,
              },
              {
                label: 'Housing Counseling',
                value: 2,
              },
              {
                label: 'Both',
                value: 3,
              },
              {
                label: 'None',
                value: 0,
              }
            ],
        validators: {
          required: true
        }
      }
    ]
  },
  guidPageHE: {
    title: 'How did you attend the homeownership education program?',
    titleShort: 'Homeownership Education',
    pageId: 'homeownershipeducation',
    validatorId: CPOSPageValidatorsEnum.dateOfCompletionValidation,
    ariaDescribedById: 'homeownership-education',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Workshop Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.counselingWorkshopType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Attended Workshop in Person',
                value: CounselingWorkshopTypeEnum.InPerson,
              },
              {
                label: 'Completed Web-Based Workshop',
                value: CounselingWorkshopTypeEnum.WebBased,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Date of Completion',
        data: {
          dateType: CPOSdateTypeEnum.dateOfCompletion,
          monthId: 'docMonthB',
          dayId: 'docDayB',
          yearId: 'docYearB'
        },
        fields: [
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.counselingCompletedDate',
            validators: {
              //required: true,
            },
          },
        ],
      }
    ]
  },
  guidPageHE1: {
    title: 'Who provided the program training?',
    titleShort: 'Homeownership Education Provider',
    pageId: 'educationprovider',
    ariaDescribedById: 'homeownership-education-provider',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is a HUD approved agency?`,
          body: `<p>For a list of HUD approved agencies go to:<a target="_blank" href="https://www.hud.gov/program_offices/housing/sfh/hcc">https://www.hud.gov/program_offices/housing/sfh/hcc</a></p>`,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agency Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.agencyType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'HUD approved agency',
                value: CounselingProviderTypeEnum.HudApprovedAgency,
              },
              {
                label: 'Not a HUD approved agency',
                value: CounselingProviderTypeEnum.NotHudApprovedAgency,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Housing Counseling Agency ID #',
        field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.providerAgencyId',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.HudApprovedAgency }],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Name of Housing Counseling Agency',
        field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.providerAgencyName',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].borrowerEducationCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.NotHudApprovedAgency }],
        },
      },
    ]
  },
  guidPageHC: {
    title: 'How did you complete the housing counseling program?',
    titleShort: 'Housing Counseling',
    pageId: 'housingcounseling',
    validatorId: CPOSPageValidatorsEnum.dateOfCompletionValidation,
    ariaDescribedById: 'housing-counseling',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Communication Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.counselingFormatType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Face To Face',
                value: CounselingFormatTypeEnum.FaceToFace,
              },
              {
                label: 'Telephone',
                value: CounselingFormatTypeEnum.Telephone,
              },
              {
                label: 'Internet',
                value: CounselingFormatTypeEnum.Internet,
              },
              {
                label: 'Hybrid',
                value: CounselingFormatTypeEnum.Hybrid,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Date of Completion',
        data: {
          dateType: CPOSdateTypeEnum.dateOfCompletion,
          monthId: 'docMonthB',
          dayId: 'docDayB',
          yearId: 'docYearB'
        },
        fields: [
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.counselingCompletedDate',
            validators: {
              //required: true,
            },
          },
        ],
      }
    ]
  },
  guidPageHC1: {
    title: 'Who provided the program training?',
    titleShort: 'Homeownership Education Provider',
    pageId: 'housingprovider',
    ariaDescribedById: 'housing-counseling-provider',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is a HUD approved agency?`,
          body: `<p>For a list of HUD approved agencies go to:<a target="_blank" href="https://www.hud.gov/program_offices/housing/sfh/hcc">https://www.hud.gov/program_offices/housing/sfh/hcc</a></p>`,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agency Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.agencyType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'HUD approved agency',
                value: CounselingProviderTypeEnum.HudApprovedAgency,
              },
              {
                label: 'Not a HUD approved agency',
                value: CounselingProviderTypeEnum.NotHudApprovedAgency,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Housing Counseling Agency ID #',
        field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.providerAgencyId',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.HudApprovedAgency }],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Name of Housing Counseling Agency',
        field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.providerAgencyName',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].borrowerHousingCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.NotHudApprovedAgency }],
        },
      },
    ]
  },
  //#endregion

  //#region Language Preferences
  guidPagePL: {
    title: 'Do you have a preferred language you wish to use for assistance and communication?',
    titleShort: 'Language Info',
    pageId: 'language-information',
    validatorId: CPOSPageValidatorsEnum.languageInformationValidation,
    ariaDescribedById: 'language-info',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `About communication and language preference`,
          body: `
            <p>Be aware that non-English languages may not be available from your lender. Your answer will NOT negatively affect your mortgage application. Your answer does not mean the Lender or Other Loan Participants agree to communicate or provide documents in your preferred language. However, it may let them assist you or direct you to persons who can assist you.</p>
            <br/>
            <p>Language assistance and resources may be available through housing counseling agencies approved by the U.S. Department of Housing and Urban Development. To find a housing counseling agency, contact one of the following Federal government agencies:</p>
            <p>
              <ul>
                <li>U.S. Department of Housing and Urban Development (HUD) at (888) 569-4287 or <a target="_blank" href="https://www.hud.gov/counseling">www.hud.gov/counseling</a></li>
                <li>Consumer Financial Protection Bureau (CFPB) at (855) 411-2372 or <a target="_blank" href="https://www.consumerfinance.gov/find-a-housing-counselor/">www.consumerfinance.gov/find-a-housing-counselor</a></li>
              </ul>
            </p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Language Preference',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[0].preferredLanguage',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Select one',
                value: PreferredLanguageTypeEnum.SelectOne,
              },
              {
                label: 'English',
                value: PreferredLanguageTypeEnum.English,
              },
              {
                label: 'Spanish',
                value: PreferredLanguageTypeEnum.Spanish,
              },
              {
                label: 'Chinese',
                value: PreferredLanguageTypeEnum.Chinese,
              },
              {
                label: 'Korean',
                value: PreferredLanguageTypeEnum.Korean,
              },
              {
                label: 'Vietnamese',
                value: PreferredLanguageTypeEnum.Vietnamese,
              },
              {
                label: 'Tagalog',
                value: PreferredLanguageTypeEnum.Tagalog,
              },
              {
                label: 'Other',
                value: PreferredLanguageTypeEnum.Other,
              },
              {
                label: 'I do not wish to respond',
                value: PreferredLanguageTypeEnum.IDontWishToRespond,
              }
            ]
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Language Name',
        field: 'loan.transactionInfo.borrowers[0].preferredLanguageOtherDesc',
        formFieldType: 'text',
        validators: {
          required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].preferredLanguage', operator: RuleExprOp.EQ, value: PreferredLanguageTypeEnum.Other }],
        },
      },
    ],
  }, // end page
  //#region Primary Marital Status
  guidPageH: {
    title: 'What is your <strong>marital status</strong>?',
    titleShort: 'Marital Status',
    pageId: 'marital-status',
    eventIds: {
      onNext: CPOSEventsEnum.maritalStatus,
    },
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What do these marital statuses mean?`,
          body: `
            <h3>Married</h3>
            <p>You are legally married and carry a marriage certificate registered from any U.S. State or territory.</p>
            <h3>Separated</h3>
            <p>A member of a formerly married couple who has obtained a legal separation agreement (not a divorce).</p>
            <h3>Unmarried</h3>
            <p>Select unmarried if you are:
              <ul>
                <li>Single</li>
                <li>Engaged to be married</li>
                <li>Divorced</li>
                <li>Widowed</li>
              </ul>
            </p>
            <h3>Other</h3>
            <p>
              <ul>
                <li>Civil Union - same sex couples legally recognized with rights similar to those of married couples. These states recognize Civil Union: Colorado, Hawaii, Illinois, Vermont and New Jersey.</li>
                <li>Domestic Partnership - any legally recognized couple with limited state rights who live together and remain unmarried. These states currently recognize Domestic Partnership: California, District of Columbia, Maine, Nevada, Oregon, Washington and Wisconsin.</li>
                <li>Registered Reciprocal Beneficiary Relationship - allows family relatives who are unmarried adult share benefits and property rights. This could be a parent sharing benefits with their adult child, or unmarried siblings sharing property rights. Registered Reciprocal Beneficiary Relationship is recognized in Hawaii.</li>
                <li>Other (legal relationship) - where some states may not recognize a civil union, domestic partnership, or registered reciprocal reneficiary, a county or city within that state may recognize a status with property rights. If you select this, please provide a description.</li>
              </ul>
            </p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Marital status',
        class: 'maritalStatus icon-group-4',
        field: 'loan.transactionInfo.borrowers[0].maritalStatus',
        errorCustom: 'Selecting an option for marital status <strong>is required</strong>.',
        formFieldType: 'iconGroup',
        formFieldData: [
          { label: 'Married', value: 0 },
          { label: 'Separated', value: 1 },
          { label: 'Unmarried', value: 2 },
          { label: 'Other', value: 3 },
        ],
        // dataField: 'df-150',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Relationship Type',
        field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther',
        formFieldType: 'select',
        class: 'icon-group-4',
        errorCustom: 'Selecting an option <strong>is required</strong>',
        formFieldData: [
          { label: 'Civil Union', value: 1 },
          { label: 'Domestic Partnership', value: 2 },
          { label: 'Registered Reciprocal Beneficiary Relationship', value: 3 },
          { label: 'Other', value: 4 },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 3 }],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Please describe this relationship',
        field: 'loan.transactionInfo.borrowers[0].domesticRelationshipOtherDesc',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [
            { field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther', operator: RuleExprOp.EQ, value: 4 },
          ],
        },
      },
      // <CvFormBuilder.Html>{
      //   type: 'html',
      //   html: `<p><strong>Married</strong><br/>You are legally married and carry a marriage license.</p><p>If you are engaged to be married, or are in a domestic partnership, please select "Umarried"</p>`,
      //   visible: {
      //     rules: [
      //       { field: 'loan.borrowerPrimary.maritalStatus', operator: RuleExprOp.EQ value: 0 }
      //     ]
      //   }
      // },
      // <CvFormBuilder.Html>{
      //   type: 'html',
      //   html: `<p><strong>Separated</strong><br/>If you are married and have a legal separation agreement (not a divorce), being legally separated deals with issues of alimony, child support, child custody, and property division.</p><p>If you are divorced, please select "Unmarried"</p>`,
      //   visible: {
      //     rules: [
      //       { field: 'loan.borrowerPrimary.maritalStatus', operator: RuleExprOp.EQ, value: 1 }
      //     ]
      //   }
      // },
      // <CvFormBuilder.Html>{
      //   type: 'html',
      //   html: `<p><strong>Unmarried</strong><br/>
      //   <p>If you are "unmarried" you are any of the following:</p>
      //   <ul>
      //     <li>Single</li>
      //     <li>Engaged to be married</li>
      //     <li>Divorced</li>
      //     <li>Widowed</li>
      //     <li>Civil Union</li>
      //     <li>Domestic Partnership</li>
      //     <li>Registered Reciprocal Beneficiary</li>
      //   </ul>`,
      //   visible: {
      //     rules: [
      //       { field: 'loan.borrowerPrimary.maritalStatus', operator: RuleExprOp.EQ, value: 2 }
      //     ]
      //   }
      // }
    ],
  },

  guidPageH2: {
    title: `Are you in a relationship with someone who's not your legal spouse but who has real <strong>property rights</strong> similar to those of a legal spouse?`,
    titleShort: 'Property Rights',
    pageId: 'property-rights',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are property rights?`,
          body: `
            <h3>Property Rights similar to a spouse</h3>
            <p>If you and your partner:
              <ol>
                <li>Reside in a U.S. State or U.S. Territory that has property laws that recognizes: (civil unions, domestic partnerships, registered reciprocal beneficiaries, or other relationship statuses that may be recognized in a city or county).</li>
                <li>or the subject property that you are financing is in a state that recognizes shared property rights.</li>
              </ol>
              Shared property includes: houses, land, assets, income, and debts that you and your legally recognized partner acquired while being together.
            </p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'propertyRights',
        placeholder: 'Domestic relationship rights',
        field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
        errorCustom: 'Selecting an option for property rights<strong>is required</strong>',
        formFieldType: 'iconGroup',
        formFieldData: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }, { label: "I don't know", value: -1 }],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[0].domesticRelationshipState',
        placeholder: 'State where relationship formed',
        formFieldType: 'select',
        errorCustom: 'Selecting a State <strong>is required</strong>',
        dataField: 'df-219',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  },
  //#endregion
  //#endregion

  //#region Secondary Borrower
  guidPageI: {
    title: 'Adding a co-borrower to the loan?',
    titleShow: false,
    pageId: 'applying-with-coborrower',
    eventIds: {
      onNext: CPOSEventsEnum.applyingWithCoborrower,
    },
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center page-title">Are you applying with <strong>your spouse</strong>?</h2>',
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 0 }],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center page-title">Are you applying with <strong>your spouse</strong>?</h2>',
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 1 }],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">Are you applying with your <strong>civil union</strong> partner?</h2>',
        visible: {
          rules: [
            { field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 3 },
            { field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther', operator: RuleExprOp.EQ, value: 1 },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center page-title">Are you applying with your <strong>domestic partner</strong>?</h2>',
        visible: {
          rules: [
            { field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 3 },
            { field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther', operator: RuleExprOp.EQ, value: 2 },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">Are you applying with your <strong>registered reciprocal beneficiary</strong> family relative?</h2>',
        visible: {
          rules: [
            { field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 3 },
            { field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther', operator: RuleExprOp.EQ, value: 3 },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">Are you applying with your partner with <strong>property rights</strong>?</h2>',
        visible: {
          rules: [
            { field: 'loan.transactionInfo.borrowers[0].maritalStatus', operator: RuleExprOp.EQ, value: 3 },
            { field: 'loan.$$custom.loan.borrowerPrimary.maritalStatusOther', operator: RuleExprOp.EQ, value: 4 },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Are you applying with your spouse?',
        class: 'isSpouseOnTheLoan',
        field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
        formFieldType: 'iconGroup',
        formFieldData: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.jointCredit',
        placeholder: 'By checking this box you agree that you intend to apply for joint credit together',
        formFieldType: 'checkbox',
        errorCustom: 'To proceed you must check the box. If you do not agree, please contact your loan officer.',
        // showError: false,
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  //#region Secondary Info
  guidPageJ: {
    title: `Please have the person you're applying with provide their <strong>contact information</strong>`,
    htmlTitle: `Co-Borrower's Contact Information`,
    pageId: 'contact-information-coborrower',
    analyticsId: `What is your <strong>spouse's contact information</strong>?`,
    eventIds: {
      onNext: CPOSEventsEnum.contactInfo
    },
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'First Name',
        field: 'loan.transactionInfo.borrowers[1].firstName',
        formFieldType: 'text',
        hint: 'Please enter your full legal name',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Middle Name',
        field: 'loan.transactionInfo.borrowers[1].middleName',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Last Name',
        field: 'loan.transactionInfo.borrowers[1].lastName',
        formFieldType: 'text',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Suffix',
        field: 'loan.transactionInfo.borrowers[1].suffix',
        formFieldType: 'mask-alpha',
        hint: 'Jr, Sr, I, II, III',
        maxlength: 4,
        validators: {
          // required: true,
          maxLength: 4,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Phone Number',
        field: 'loan.transactionInfo.borrowers[1].phones[0].number',
        formFieldType: 'phoneNumber',
        columns: 7,
        validators: {
          required: true,
          minLength: 10,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[1].phones[0].type',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Cell',
            value: 1,
          },
          {
            label: 'Home',
            value: 0,
          },
          {
            label: 'Work',
            value: 2,
          },
        ],
        columns: 5,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.jointCredit2',
        placeholder: 'By checking this box you agree that you intend to apply for joint credit together',
        formFieldType: 'checkbox',
        errorCustom: 'To proceed you must check the box. If you do not agree, please contact your loan officer.',
        // showError: false,
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page
  guidPageJ2: {
    pageId: 'email-coborrower',
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, please provide your <strong>email address</strong>. We will use this to send notifications and it will be the username for your account.`,
    titleShort: '{{loan.transactionInfo.borrowers[1].firstName}}, please provide your email address - Personal',
    showButtonBack: false,
    showButtonNext: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is this email used for?`,
          body: `
            <p>
              You will use your email address to sign into your account. Once signed in, you can
              complete your tasks throughout the loan process, such as uploading, viewing, and signing
              documents electronically. You will also receive email notifications about your loan as well
              as tasks that need your attention.
            </p>
            <p>
              If you share an email account with the primary borrower, you can use the same email address and
              share an account.
            </p>
          `,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'userAccountEmail',
        data: {
          targetBorrower: 'loan.transactionInfo.borrowers.1',
          otherBorrower: 'loan.transactionInfo.borrowers.0',
          emailField: 'loan.$$custom.loan.borrowerSecondary.usernameStaged',
          jointAccountField: 'loan.$$custom.borrowerJointAccount',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.usernameStaged',
            validators: {
              required: true,
              email: true,
            },
          },
          {
            field: 'loan.$$custom.borrowerJointAccount',
            validators: {
              // required: true,
            },
          },
        ],
      },
    ],
  },
  guidPageJ3: {
    title:
      '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, set up a security question below to secure your account',
    titleShort:
      '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, please set up a security question for account activation - Personal',
    pageId: 'security-question-coborrower',
    analyticsId: 'Spouse Co-Borrower Security Question',
    canSave: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="alert alert-info  icon">An email will be sent to you with a link to setup a password and activate the account. You will be prompted to provide the answer to the security question chosen below.</div>`,
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p>Please answer the following from {{loan.transactionInfo.borrowers[1].firstName}}'s perspective.</p>`,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Security Question',
        field: 'loan.$$custom.securityQuestionId',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'What are the last 4 digits of your cell phone number?',
            value: 5,
          },
          {
            label: 'What city were you married in?',
            value: 6,
          },
          {
            label: 'What month is your anniversary?',
            value: 7,
          },
          {
            label: 'What city do you currently live in?',
            value: 8,
          },
          {
            label: 'What city were you born in?',
            value: 9,
          },
          {
            label: 'What is your favorite hobby?',
            value: 0,
          },
          {
            label: 'Who was your favorite teacher?',
            value: 1,
          },
          {
            label: 'What is the name of your favorite pet?',
            value: 2,
          },
          {
            label: 'In what year was your mother born?',
            value: 3,
          },
          {
            label: 'What is the name of the model of your first car?',
            value: 4,
          },
        ],
        columns: 12,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Security Answer',
        field: 'loan.transactionInfo.borrowers[1].userAccount.securityAnswer',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
    ],
  }, // end page
  //#endregion

  //#region Secondary Military
  guidPageK: {
    title:
      '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, are you currently <strong>active in the military, a veteran</strong>, and/or <strong>a surviving spouse</strong>?',
    titleShort: 'Military/Veteran',
    htmlTitle: 'Active military, veteran, and/or a surviving spouse',
    htmlTitlePunctuation: true,
    pageId: 'is-military-coborrower',
    analyticsId:
      'Is {{loan.transactionInfo.borrowers[1].firstName}} <strong>currently active in the military or a veteran</strong>?',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What qualifies as military?`,
          body: `
            <p>Why are we asking this question? The US government has established mortgage programs that are only available to individuals who are currently serving, or who previously served in the military.</p>
            <h3>Active Duty</h3>
            <p>This applies to individuals currently working full-time for any of the branches of the U.S. Armed Forces and subject to deployment at any time.</p>
            <h3>Retired / Discharged / Veteran</h3>
            <p>Select this if you formerly served in the U.S Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and/or Navy) and have been discharged or retired.</p>
            <h3>Reserves</h3>
            <p>Indicates that you are a reservist with any of the U.S. Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and/or Navy).</p>
            <h3>National Guard</h3>
            <p>This option is used if you are with the Army National Guard, or Air National Guard (Air Force).</p>
            <h3>Surviving Spouse</h3>
            <p>This applies to surviving spouses of deceased veterans.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Is Spouse Military-Affiliated?',
        field: 'loan.$$custom.isMilitarySpouse', // isMilitarySpouse
        class: 'isMilitarySpouse',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for active military or veteran <strong>is required</strong>.',
        formFieldData: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<p class="text-center"><img src="assets/img/flag.png" alt=""/></p><h2 class="text-center">{{loan.transactionInfo.borrowers[1].firstName}}, thank you for your service!</h2>',
        visible: {
          rules: [{ field: 'loan.$$custom.isMilitarySpouse', operator: RuleExprOp.EQ, value: true }],
        },
      },
    ],
  }, // end page

  guidPageK2: {
    title:
      '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, what is your current <strong>military status</strong>?',
    htmlTitle: 'Military Status',
    pageId: 'military-status-coborrower',
    validatorId: 'borrowerSecondaryMilitaryServiceType',
    analyticsId: `What is {{loan.transactionInfo.borrowers[1].firstName}}'s <strong>military status</strong>?`,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `About military statuses`,
          body: `
            <h3>Active Duty</h3>
            <p>If you're working full-time for the U.S. Armed Forces which includes: Air Force, Army, Coast Guard, Marine Corps, and Navy.</p>
            <h3>Retired / Discharged / Veteran</h3>
            <p>You formerly served in the U.S Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and Navy) and have been discharged or retired.</p>
            <h3>Reserves</h3>
            <p>You are a reservist with any of the U.S. Armed Forces (Air Force, Army, Coast Guard, Marine Corps, and Navy).</p>
            <h3>National Guard</h3>
            <p>You are with the Army National Guard, or Air National Guard (Air Force).</p>
            <h3>Surviving Spouse</h3>
            <p>Surviving military spouses can sometimes receive veteran's disability compensation. This benefit is called Dependency and Indemnity Compensation (DIC), and it is paid on a monthly basis. DIC is available to a surviving military spouse (a widow or widower) and his or her dependent children.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Military service type',
        field: 'loan.transactionInfo.borrowers[1].militaryServiceType',
        formFieldType: 'iconGroup',
        isDeselectable: true,
        // dataField: 'df-42',
        // errorCustom not needed. The validatorId already provides a suitable message.
        formFieldData: [
          { label: 'Active Duty', value: 2 },
          { label: 'Retired / Discharged', value: 3 },
          { label: 'National Guard / Reserves', value: 4 },
        ],
      },
      {
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[1].survivingSpouse',
        placeholder: `I'm a surviving spouse of a veteran`,
        formFieldType: 'checkbox',
        parentClass: 'text-center',
      },
    ],
  }, // end page
  //#endregion

  //#region Secondary Address
  guidPageL: {
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, what is your <strong>current address</strong>?`,
    titleShort: 'Spouse Address',
    pageId: 'currently-live-coborrower',
    analyticsId: `What is {{loan.transactionInfo.borrowers[1].firstName}}'s <strong>current address</strong>?`,
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
        placeholder: 'Same address as {{loan.transactionInfo.borrowers[0].firstName}}',
        formFieldType: 'checkbox',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.streetName',
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
        placeholder: 'Mailing address is same as current address',
        formFieldType: 'checkbox',
      },
    ],
  }, // end page

  guidPageL2: {
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, what is your <strong>mailing address</strong>?`,
    pageId: 'mailing-address-coborrower',
    analyticsId: `What is {{loan.transactionInfo.borrowers[1].firstName}}'s <strong>mailing address</strong>?`,
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.streetName',
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerSecondary.addressMailing.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressMailing.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
    ],
  }, // end page

  guidPageM: {
    title: '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you <strong>own</strong> or <strong>rent</strong> at this address?',
    titleShort: 'Spouse Rent Or Own',
    pageId: 'rent-or-own-coborrower',
    analyticsId: 'Does {{loan.transactionInfo.borrowers[1].firstName}} <strong>rent or own</strong> at this address?',
    eventIds: {
      onNext: CPOSEventsEnum.timeAtAddressSecondary,
    },
    content: [
      // no street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="line-height-2x text-left"><strong>
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.cityName}}
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.stateName}}
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.streetName',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="text-left"><strong>
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.displayStreetName}}
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.displayUnitNumber}}<br/>

              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.cityName}}
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.stateName}}
              {{loan.$$custom.loan.borrowerSecondary.addressCurrent.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.streetName',
              operator: RuleExprOp.NE,
              value: '',
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Current address ownership',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for rent or own at this address <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Own',
            value: 0,
          },
          {
            label: 'Rent',
            value: 1,
          },
          {
            label: 'Living Rent Free',
            value: 2,
          },
        ],
        validators: {
          required: true,
        },
      },

      {
        type: 'html',
        html: '<h3 class="text-center">{{loan.transactionInfo.borrowers[1].firstName}}, how long have you lived here?</h3>',
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.timeAtAddressYears',
        formFieldType: 'number',
        placeholder: 'Years',
        columns: 6,
        validators: {
          // maxLength: 2,
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.timeAtAddressMonths',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.monthlyRent',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        placeholder: 'Monthly Rent',
        // format: '1.2-2',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressCurrent.ownership',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageM2: {
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, what is your <strong>previous address</strong>?`,
    pageId: 'previous-address-coborrower',
    analyticsId: `What is {{loan.transactionInfo.borrowers[1].firstName}}'s <strong>previous address</strong>?`,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="alert alert-info icon">We need a 2 year history of your residential address.</div>`,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.streetName',
        placeholder: 'Street Address',
        formFieldType: 'text',
        hidden: true,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          addressPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.streetName',
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.streetName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.cityName',
        placeholder: 'City',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.stateName',
        placeholder: 'State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.cityName',
          statePath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.stateName',
          zipPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode',
          countyPath: 'loan.$$custom.loan.borrowerSecondary.addressHistory.countyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode',
            placeholder: 'Zip',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode',
        placeholder: 'Zip',
        formFieldType: 'text',
        columns: 6,
        validators: {
          required: true,
        },
      },
       */
    ],
  }, // end page

  guidPageM3: {
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, did you <strong>own</strong> or <strong>rent</strong> at this address?`,
    titleShort: 'Rent Or Own',
    pageId: 'prev-rent-or-own-coborrower',
    analyticsId: `Did {{loan.transactionInfo.borrowers[1].firstName}} <strong>rent</strong> or <strong>own</strong> at this address?`,
    content: [
      // no street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="line-height-2x text-left"><strong>
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.cityName}}
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.stateName}}
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.streetName',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      // with street addr
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<h3 class="text-center mb-4 title-sub d-flex justify-content-center">
            <span><i class="fa fa-map-pin mr-2 fa-2x" aria-hidden="true"></i></span>
            <span class="text-left"><strong>
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.displayStreetName}}
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.displayUnitNumber}}<br/>

              {{loan.$$custom.loan.borrowerSecondary.addressHistory.cityName}}
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.stateName}}
              {{loan.$$custom.loan.borrowerSecondary.addressHistory.zipCode}}
            </strong></span>
          </h3>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.streetName',
              operator: RuleExprOp.NE,
              value: '',
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.ownership',
        formFieldType: 'iconGroup',
        errorCustom: 'Selecting an option for rent or own at this address <strong>is required</strong>.',
        formFieldData: [
          {
            label: 'Own',
            value: 0,
          },
          {
            label: 'Rent',
            value: 1,
          },
          {
            label: 'Living Rent Free',
            value: 2,
          },
        ],
        validators: {
          required: true,
        },
      },

      {
        type: 'html',
        html: `<h3 class="text-center">{{loan.transactionInfo.borrowers[1].firstName}}, how long have you lived there?</h3>`,
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.timeAtAddressYears',
        formFieldType: 'number',
        placeholder: 'Years',
        columns: 6,
        validators: {
          // maxLength: 2,
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.timeAtAddressMonths',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.monthlyRent',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        placeholder: 'Monthly Rent',
        // format: '1.2-2',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.borrowerSecondary.addressHistory.ownership',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion

  //#region Secondary Dependents
  guidPageN: {
    title: '<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, how many <strong>dependents</strong> do you have?',
    titleShort: 'Spouse Dependents',
    pageId: 'dependents-coborrower',
    analyticsId: 'How many <strong>dependents</strong> does {{loan.transactionInfo.borrowers[1].firstName}} have?',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="alert alert-info  icon">Enter different dependents other than what {{loan.transactionInfo.borrowers[0].firstName}} has entered. If {{loan.transactionInfo.borrowers[1].firstName}} has no other dependents enter 0.</div>`,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.transactionInfo.borrowers[1].numberOfDependents',
        placeholder: 'Number of dependents',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5+',
            value: 5,
          },
        ],
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'dependents',
        data: {
          index: 1,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Age of dependents',
        field: 'loan.transactionInfo.borrowers[1].agesOfDependents',
        formFieldType: 'text',
        hidden: true,
      },

      <CvFormBuilder.Html>{
        id: 'dependents-warning',
        type: 'html',
        html: '<p>If child is less than 1 year old, enter 1</p>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].numberOfDependents',
              operator: RuleExprOp.GT,
              value: 0,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion

  //#region Secondary Alimony
  guidPageO: {
    title: `<strong>{{loan.transactionInfo.borrowers[1].firstName}}</strong>, do you have to pay for <strong>Alimony, Child Support or Seperate Maintenance</strong>?`,
    titleShort: 'AlimonySpouse',
    pageId: 'has-alimony-coborrower', //spouse
    analyticsId: 'Co-Borrower pays for Alimony, Child Support or Sep. Maint.',

    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Has spouse alimony?',
        // standalone: true,
        field: 'loan.$$custom.alimonyEtc.hasAlimonySpouse', // hasAlimony - Spouse
        class: 'hasAlimonySpouse',
        // The validator does not actually check for no selection, so we need the errorCustom
        errorCustom: 'Selecting an option for alimony, child support or separate maintenance <strong>is required</strong>.',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-left pb-4">Enter all that apply: </h3>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimonySpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: '  Alimony Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.alimonyMonthlyAmountSpouse',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimonySpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: '  Child Support Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.ChildSupportMonthlyAmountSpouse',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimonySpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Separate Maintenance Monthly Amount',
        field: 'loan.$$custom.alimonyEtc.SeperateMaintenanceMonthlyAmountSpouse',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.alimonyEtc.hasAlimonySpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion
  //#endregion

  //#region Other Borrower
  guidPageP: {
    title: 'Are you applying with <strong>anyone else</strong>?',
    titleShort: 'Applying with Anyone Else',
    pageId: 'applying-with-anyone-else',
    // This form is shown once for both borrower and co-borrower, so flag is added to the primary borrower
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How many people can I apply with?`,
          body: `
            <p>You can invite one additional applicant. If you are applying with more than one additional applicant, then please contact your loan team.</p>
          `,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Are you applying with anyone else?',
        class: 'anyoneElse',
        field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
        formFieldType: 'iconGroup',
        // errorCustom not needed. The validatorId already provides a suitable message.
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
           required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.loanApplicationId',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'anyoneElse',
        field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          // required: true,
        },
        disabled: true,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.loanApplicationId',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'html',
        html:
          '<div class="alert alert-info">Please contact your loan officer, {{loan.$$custom.loanOfficer.name}} at <a href="tel:{{loan.$$custom.loanOfficer.phone}}" target="_blank">{{loan.$$custom.loanOfficer.phone}}</a> and let them know you would like to add someone to your loan. Your loan officer will collect their information and can send them an invite to complete their loan application online. You can proceed with your loan application by clicking "Next".</div>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'html',
        html:
          '<div class="alert alert-info">The additional applicant has been added to the loan. If you need to remove {{loan.$$custom.loan.additionalApplicant.firstName}} {{loan.$$custom.loan.additionalApplicant.lastName}} from the loan, please contact your loan team.</div>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.loanApplicationId',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion

  guidPagePA: {
    title: `Please provide the additional applicant's <strong>contact information</strong>`,
    titleShort: `Please provide the additional applicant's contact information - Personal`,
    pageId: 'contact-information-additional-applicant',
    analyticsId: `Please provide the additional applicant's contact information`,
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'First Name',
        field: 'loan.$$custom.loan.additionalApplicant.firstName',
        formFieldType: 'text',
        hint: 'Please enter your full legal name',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'First Name',
        field: 'loan.$$custom.loan.additionalApplicant.firstName',
        formFieldType: 'text',
        hint: 'Please enter your full legal name',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
        disabled: true,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Middle Name',
        field: 'loan.$$custom.loan.additionalApplicant.middleName',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Middle Name',
        field: 'loan.$$custom.loan.additionalApplicant.middleName',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
        disabled: true,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Last Name',
        field: 'loan.$$custom.loan.additionalApplicant.lastName',
        formFieldType: 'text',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Last Name',
        field: 'loan.$$custom.loan.additionalApplicant.lastName',
        formFieldType: 'text',
        validators: {
          required: true,
          pattern: '^\\s*\\S+.*$',
        },
        disabled: true,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Suffix',
        field: 'loan.$$custom.loan.additionalApplicant.suffix',
        formFieldType: 'mask-alpha',
        hint: 'Jr, Sr, I, II, III',
        maxlength: 4,
        validators: {
          // required: true,
          maxLength: 4,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Suffix',
        field: 'loan.$$custom.loan.additionalApplicant.suffix',
        formFieldType: 'mask-alpha',
        hint: 'Jr, Sr, I, II, III',
        maxlength: 4,
        validators: {
          // required: true,
          maxLength: 4,
        },
        disabled: true,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.ContentColumns>{
        columns: [
          {
            width: 7,
            content: [
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Phone Number',
                field: 'loan.$$custom.loan.additionalApplicant.phoneNumber',
                formFieldType: 'phoneNumber',
                validators: {
                  required: true,
                  minLength: 10,
                },
                visible: {
                  rules: [
                    {
                      field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
                      operator: RuleExprOp.NE,
                      value: true,
                    },
                  ],
                },
              },
              <CvFormBuilder.FormField>{
                type: 'formField',
                placeholder: 'Phone Number',
                field: 'loan.$$custom.loan.additionalApplicant.phoneNumber',
                formFieldType: 'phoneNumber',
                validators: {
                  required: true,
                  minLength: 10,
                },
                disabled: true,
                visible: {
                  rules: [
                    {
                      field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
                      operator: RuleExprOp.EQ,
                      value: true,
                    },
                  ],
                },
              },
            ],
          },
          {
            width: 5,
            content: [
              <CvFormBuilder.FormField>{
                type: 'formField',
                field: 'loan.$$custom.loan.additionalApplicant.phoneType',
                formFieldType: 'select',
                formFieldData: [
                  {
                    label: 'Cell',
                    value: 1,
                  },
                  {
                    label: 'Home',
                    value: 0,
                  },
                  {
                    label: 'Work',
                    value: 2,
                  },
                ],
                validators: {
                  required: true,
                },
                visible: {
                  rules: [
                    {
                      field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
                      operator: RuleExprOp.NE,
                      value: true,
                    },
                  ],
                },
              },
              <CvFormBuilder.FormField>{
                type: 'formField',
                field: 'loan.$$custom.loan.additionalApplicant.phoneType',
                formFieldType: 'select',
                formFieldData: [
                  {
                    label: 'Cell',
                    value: 1,
                  },
                  {
                    label: 'Home',
                    value: 0,
                  },
                  {
                    label: 'Work',
                    value: 2,
                  },
                ],
                validators: {
                  required: true,
                },
                disabled: true,
                visible: {
                  rules: [
                    {
                      field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
                      operator: RuleExprOp.EQ,
                      value: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  }, // end page

  guidPagePB: {
    title: `Please provide <strong>{{loan.$$custom.loan.additionalApplicant.firstName}}'s email address</strong>. We will use this to send notifications and it will be the username for {{loan.$$custom.loan.additionalApplicant.firstName}}</strong>'s account.`,
    titleShort: `Please provide {{loan.$$custom.loan.additionalApplicant.firstName}}'s email address - Personal`,
    pageId: 'email-additional-applicant',
    analyticsId: `[SKIP]`,
    showButtonBack: false,
    showButtonNext: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'additionalApplicantEmail',
        data: {
          additionalApplicant: 'loan.$$custom.loan.additionalApplicant',
          borrowerPrimary: 'loan.transactionInfo.borrowers.0',
          borrowerSecondary: 'loan.transactionInfo.borrowers.1',
          emailField: 'loan.$$custom.loan.additionalApplicant.username',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.username',
            validators: {
              required: true,
              email: true,
            },
          },
        ],
      },
    ],
  }, // end page

  //#region Homeownership Education/Counseling CoBorrower
  guidPageHECCo: {
    title: '{{loan.transactionInfo.borrowers[1].firstName}}, have you completed homeownership education or housing counseling within the last 12 months?',
    titleShort: 'Homeownership Education/Housing Counseling',
    pageId: 'homeownershipeducation-housingcounseling-coborrower',
    ariaDescribedById: 'homeownership-counseling-coborrower',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Program Type',
        // standalone: true,
        field: 'loan.$$custom.programTypeCoBorrower',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Homeownership Education',
                value: 1,
              },
              {
                label: 'Housing Counseling',
                value: 2,
              },
              {
                label: 'Both',
                value: 3,
              },
              {
                label: 'None',
                value: 0,
              }
            ],
        validators: {
          required: true
        }
      }
    ]
  },
  guidPageHECo: {
    title: 'How did you attend the homeownership education program?',
    titleShort: 'Homeownership Education',
    pageId: 'homeownershipeducation-coborrower',
    validatorId: CPOSPageValidatorsEnum.dateOfCompletionValidation,
    ariaDescribedById: 'homeownership-education-coborrower',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Workshop Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.counselingWorkshopType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Attended Workshop in Person',
                value: CounselingWorkshopTypeEnum.InPerson,
              },
              {
                label: 'Completed Web-Based Workshop',
                value: CounselingWorkshopTypeEnum.WebBased,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Date of Completion',
        data: {
          dateType: CPOSdateTypeEnum.dateOfCompletion,
          monthId: 'docMonthB',
          dayId: 'docDayB',
          yearId: 'docYearB'
        },
        fields: [
          {
            field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.counselingCompletedDate',
            validators: {
              //required: true,
            },
          },
        ],
      }
    ]
  },
  guidPageHECo1: {
    title: 'Who provided the program training?',
    titleShort: 'Homeownership Education Provider',
    pageId: 'educationprovider-coborrower',
    ariaDescribedById: 'homeownership-education-provider-coborrower',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is a HUD approved agency?`,
          body: `<p>For a list of HUD approved agencies go to:<a target="_blank" href="https://www.hud.gov/program_offices/housing/sfh/hcc">https://www.hud.gov/program_offices/housing/sfh/hcc</a></p>`,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agency Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.agencyType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'HUD approved agency',
                value: CounselingProviderTypeEnum.HudApprovedAgency,
              },
              {
                label: 'Not a HUD approved agency',
                value: CounselingProviderTypeEnum.NotHudApprovedAgency,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Housing Counseling Agency ID #',
        field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.providerAgencyId',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.HudApprovedAgency }],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Name of Housing Counseling Agency',
        field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.providerAgencyName',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[1].borrowerEducationCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.NotHudApprovedAgency }],
        },
      },
    ]
  },
  guidPageHCCo: {
    title: 'How did you complete the housing counseling program?',
    titleShort: 'Housing Counseling',
    pageId: 'housingcounseling-coborrower',
    validatorId: CPOSPageValidatorsEnum.dateOfCompletionValidation,
    ariaDescribedById: 'housing-counseling-coborrower',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Communication Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.counselingFormatType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'Face To Face',
                value: CounselingFormatTypeEnum.FaceToFace,
              },
              {
                label: 'Telephone',
                value: CounselingFormatTypeEnum.Telephone,
              },
              {
                label: 'Internet',
                value: CounselingFormatTypeEnum.Internet,
              },
              {
                label: 'Hybrid',
                value: CounselingFormatTypeEnum.Hybrid,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Date of Completion',
        data: {
          dateType: CPOSdateTypeEnum.dateOfCompletion,
          monthId: 'docMonthB',
          dayId: 'docDayB',
          yearId: 'docYearB'
        },
        fields: [
          {
            field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.counselingCompletedDate',
            validators: {
              //required: true,
            },
          },
        ],
      }
    ]
  },
  guidPageHCCo1: {
    title: 'Who provided the program training?',
    titleShort: 'Homeownership Education Provider',
    pageId: 'housingprovider-coborrower',
    ariaDescribedById: 'housing-counseling-provider-coborrower',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is a HUD approved agency?`,
          body: `<p>For a list of HUD approved agencies go to:<a target="_blank" href="https://www.hud.gov/program_offices/housing/sfh/hcc">https://www.hud.gov/program_offices/housing/sfh/hcc</a></p>`,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agency Type',
        // standalone: true,
        field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.agencyType',
        formFieldType: 'select',
        formFieldData: [
              {
                label: 'HUD approved agency',
                value: CounselingProviderTypeEnum.HudApprovedAgency,
              },
              {
                label: 'Not a HUD approved agency',
                value: CounselingProviderTypeEnum.NotHudApprovedAgency,
              }
            ],
        validators: {
          //required: true
        }
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Housing Counseling Agency ID #',
        field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.providerAgencyId',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.HudApprovedAgency }],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Name of Housing Counseling Agency',
        field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.providerAgencyName',
        formFieldType: 'text',
        validators: {
          //required: true
        },
        visible: {
          rules: [{ field: 'loan.transactionInfo.borrowers[1].borrowerHousingCounseling.agencyType', operator: RuleExprOp.EQ, value: CounselingProviderTypeEnum.NotHudApprovedAgency }],
        },
      },
    ]
  },
  //#endregion
//#region Language Preferences
guidPagePLC: {
  title: 'Do you have a preferred language you wish to use for assistance and communication?',
  titleShort: 'Language Info',
  pageId: 'language-information-coborrower',
  validatorId: CPOSPageValidatorsEnum.languageInformationValidation,
  ariaDescribedById: 'language-info-coborrower',
  content: [
    <CvFormBuilder.Feature>{
      type: 'feature',
      featureId: 'hint',
      data: {
        title: `About communication and language preference`,
        body: `
          <p>Be aware that non-English languages may not be available from your lender. Your answer will NOT negatively affect your mortgage application. Your answer does not mean the Lender or Other Loan Participants agree to communicate or provide documents in your preferred language. However, it may let them assist you or direct you to persons who can assist you.</p>
          <br/>
          <p>Language assistance and resources may be available through housing counseling agencies approved by the U.S. Department of Housing and Urban Development. To find a housing counseling agency, contact one of the following Federal government agencies:</p>
          <p>
            <ul>
              <li>U.S. Department of Housing and Urban Development (HUD) at (888) 569-4287 or <a target="_blank" href="https://www.hud.gov/counseling">www.hud.gov/counseling</a></li>
              <li>Consumer Financial Protection Bureau (CFPB) at (855) 411-2372 or <a target="_blank" href="https://www.consumerfinance.gov/find-a-housing-counselor/">www.consumerfinance.gov/find-a-housing-counselor</a></li>
            </ul>
          </p>
        `,
      },
    },
    <CvFormBuilder.FormField>{
      type: 'formField',
      placeholder: 'Language Preference',
      // standalone: true,
      field: 'loan.transactionInfo.borrowers[1].preferredLanguage',
      formFieldType: 'select',
      formFieldData: [
            {
              label: 'Select one',
              value: PreferredLanguageTypeEnum.SelectOne,
            },
            {
              label: 'English',
              value: PreferredLanguageTypeEnum.English,
            },
            {
              label: 'Spanish',
              value: PreferredLanguageTypeEnum.Spanish,
            },
            {
              label: 'Chinese',
              value: PreferredLanguageTypeEnum.Chinese,
            },
            {
              label: 'Korean',
              value: PreferredLanguageTypeEnum.Korean,
            },
            {
              label: 'Vietnamese',
              value: PreferredLanguageTypeEnum.Vietnamese,
            },
            {
              label: 'Tagalog',
              value: PreferredLanguageTypeEnum.Tagalog,
            },
            {
              label: 'Other',
              value: PreferredLanguageTypeEnum.Other,
            },
            {
              label: 'I do not wish to respond',
              value: PreferredLanguageTypeEnum.IDontWishToRespond,
            }
          ]
    },
    <CvFormBuilder.FormField>{
      type: 'formField',
      placeholder: 'Language Name',
      field: 'loan.transactionInfo.borrowers[1].preferredLanguageOtherDesc',
      formFieldType: 'text',
      validators: {
        required: true
      },
      visible: {
        rules: [{ field: 'loan.transactionInfo.borrowers[1].preferredLanguage', operator: RuleExprOp.EQ, value: PreferredLanguageTypeEnum.Other }],
      },
    },
  ],
}, // end page
  guidPagePC: {
    title:
      'Setup a security question that <strong>{{loan.$$custom.loan.additionalApplicant.firstName}}</strong> would know the answer to.',
    titleShort:
      'Setup a security question that {{loan.$$custom.loan.additionalApplicant.firstName}} would know the answer to - Personal',
    pageId: 'security-question-additional-applicant',
    analyticsId: 'Additional Applicant Security Question',
    canSave: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="alert alert-info  icon">An email will be sent to {{loan.$$custom.loan.additionalApplicant.firstName}} with a link to setup a password and activate the account. {{loan.$$custom.loan.additionalApplicant.firstName}} will be prompted to provide the answer to the security question chosen below.</div>`,
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p>Please answer the following from {{loan.$$custom.loan.additionalApplicant.firstName}}'s perspective.</p>`,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Security Question',
        field: 'loan.$$custom.loan.additionalApplicant.securityQuestionId',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'What are the last 4 digits of your cell phone number?',
            value: 5,
          },
          {
            label: 'What city were you married in?',
            value: 6,
          },
          {
            label: 'What month is your anniversary?',
            value: 7,
          },
          {
            label: 'What city do you currently live in?',
            value: 8,
          },
          {
            label: 'What city were you born in?',
            value: 9,
          },
          {
            label: 'What is your favorite hobby?',
            value: 0,
          },
          {
            label: 'Who was your favorite teacher?',
            value: 1,
          },
          {
            label: 'What is the name of your favorite pet?',
            value: 2,
          },
          {
            label: 'In what year was your mother born?',
            value: 3,
          },
          {
            label: 'What is the name of the model of your first car?',
            value: 4,
          },
        ],
        columns: 12,
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Security Answer',
        field: 'loan.$$custom.loan.additionalApplicant.securityAnswer',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageZ: {
    // title: 'Summary',
    title: 'Personal Review Section',
    titleShow: false,
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-personal',
      },
      /**
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary',
      },
       */
    ],
  }, // end page
};
