import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { LoanPurposeTypeEnum } from 'src/app/shared/models';

export const personalRouting: CvFormBuilder.Routes = {
  guidRouteA: {
    pageGuid: 'guidPageA', // start
    routeNext: 'guidRouteB',
  },

  //#region Primary Borrower
  //#region Primary Info
  guidRouteB: {
    pageGuid: 'guidPageB', // contact-information
    routeNext: 'guidRouteC',
  },
  //#endregion

  //#region Primary Military
  guidRouteC: {
    pageGuid: 'guidPageC', // is-military
    ruleGroups: [
      {
        routeNext: 'guidRouteC2',
        rules: [
          {
            field: 'loan.$$custom.isMilitary',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteD',
        rules: [
          {
            field: 'loan.$$custom.isMilitary',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteC2: {
    pageGuid: 'guidPageC2', // military status
    routeNext: 'guidRouteD',
  },
  //#endregion

  //#region Primary Address
  guidRouteD: {
    pageGuid: 'guidPageD', // currently-live
    ruleGroups: [
      {
        routeNext: 'guidRouteD2',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteE',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteE',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
    ],
  },

  guidRouteD2: {
    pageGuid: 'guidPageD2', // mailing-address
    routeNext: 'guidRouteE',
  },

  guidRouteE: {
    pageGuid: 'guidPageE', // rent-or-own
    routeNext: 'guidRouteF',
    // routing handled by Events.timeAtAddressPrimary
    // routeNext: 'guidRoutePrevAddressPrimary', < 2 years
  },

  guidRoutePrevAddressPrimary: {
    pageGuid: 'guidPageE2', // previous-address
    routeNext: 'guidRouteE3',
  },

  guidRouteE3: {
    pageGuid: 'guidPageE3', // prev-rent-or-own
    routeNext: 'guidRouteF',
  },
  //#endregion

  //#region Primary Dependents
  guidRouteF: {
    pageGuid: 'guidPageF', // dependents
    ruleGroups: [
      {
        routeNext: 'guidRouteG',
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteH',
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },
  //#endregion

  //#region Primary Alimony
  guidRouteG: {
    pageGuid: 'guidPageG', // alimony
    ruleGroups: [
      {
        routeNext: 'guidRouteHEC', //homeownership
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          }
        ],
      },
      {
        routeNext: 'guidRoutePL', //language information
        rules: [
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ],
      },
      {
        routeNext: 'guidRouteH',
        rules: [
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          }
        ],
      },
      {
        routeNext: 'guidRouteH',
        rules: [
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: true
          }
        ],
      }
    ]

  },
  //#endregion
  //#region Homeownership Education/Counseling
  guidRouteHEC: {
    pageGuid: 'guidPageHEC',
    ruleGroups: [
      {
        routeNext: 'guidRoutePL', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 0
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      },
      {
        routeNext: 'guidRoutePL', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 0
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteH',
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 0
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteHE', //education
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          }
        ]
      },
      {
        routeNext: 'guidRouteHE', //education
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 3
          }
        ]
      },
      {
        routeNext: 'guidRouteHC', //counseling
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 2
          }
        ]
      }
    ]
  },
  guidRouteHE: {
    pageGuid: 'guidPageHE',
    routeNext: 'guidRouteHE1',
  },
  guidRouteHE1: {
    pageGuid: 'guidPageHE1',
    ruleGroups: [
      {
        routeNext: 'guidRoutePL', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      },
      {
        routeNext: 'guidRoutePL', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteHC', //House Counseling
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 3
          }
        ]
      },
      {
        routeNext: 'guidRouteH',
        rules: [
          {
            field: 'loan.$$custom.programTypeBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      }
    ]
  },
  guidRouteHC: {
    pageGuid: 'guidPageHC',
    routeNext: 'guidRouteHC1',
  },
  guidRouteHC1: {
    pageGuid: 'guidPageHC1',
    ruleGroups: [
     {
        routeNext: 'guidRoutePL',
        rules: [
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
     },
     {
      routeNext: 'guidRoutePL',
      rules: [
        {
          field: 'config.TPOEnvironment',
          operator: RuleExprOp.EQ,
          value: true,
        }
      ]
   },
     {
      routeNext: 'guidRouteH',
      rules: [
        {
          field: 'config.HidePersonalPageLanguageField',
          operator: RuleExprOp.EQ,
          value: true,
        },
        {
          field: 'config.TPOEnvironment',
          operator: RuleExprOp.EQ,
          value: false,
        }
      ]
   }
    ]
  },
  //#endregion
  //#region Preferred Language
  guidRoutePL: {
    pageGuid: 'guidPagePL', //language information
    routeNext: 'guidRouteH',
  },//#endregion
  //#region Primary Marital Status
  guidRouteH: {
    pageGuid: 'guidPageH', // marital-status
    ruleGroups: [
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 2, // unmarried
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 2, // unmarried
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteI',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 0, // married
          },
        ],
      },
      {
        routeNext: 'guidRouteI',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 1, // separated
          },
        ],
      },
      {
        routeNext: 'guidRouteP',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 2, // unmarried
          },
        ],
      },
      {
        routeNext: 'guidRouteH2',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 3, // other
          },
        ],
      },
    ],
  },

  guidRouteH2: {
    pageGuid: 'guidPageH2', // property-rights
    ruleGroups: [
      {
        routeNext: 'guidRouteI',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
            operator: RuleExprOp.EQ,
            value: 1,
          },
        ],
      },
      {
        routeNext: 'guidRouteP',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
            operator: RuleExprOp.NE,
            value: 1,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
            operator: RuleExprOp.NE,
            value: 1,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].domesticRelationshipRights',
            operator: RuleExprOp.NE,
            value: 1,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true
          },
        ],
      },
    ],
  },
  //#endregion
  //#endregion

  //#region Secondary Borrower
  guidRouteI: {
    pageGuid: 'guidPageI', // applying-with-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteJ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteP',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  //#region Secondary Info
  guidRouteJ: {
    pageGuid: 'guidPageJ', // contact-information-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteJ2',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteK',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteJ2: {
    pageGuid: 'guidPageJ2', // email-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteJ3',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.spouseSecurityAnswerProvided',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.borrowerJointAccount',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteK',
        rules: [],
      },
    ],
  },

  guidRouteJ3: {
    pageGuid: 'guidPageJ3', // security-question-coborrower
    routeNext: 'guidRouteK',
  },
  //#endregion

  //#region Secondary Military
  guidRouteK: {
    pageGuid: 'guidPageK', // is-military-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteK2',
        rules: [
          {
            field: 'loan.$$custom.isMilitarySpouse', // isMilitarySpouse,
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteL',
        rules: [
          {
            field: 'loan.$$custom.isMilitarySpouse', // isMilitarySpouse,
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteK2: {
    pageGuid: 'guidPageK2', // military-status-coborrower
    routeNext: 'guidRouteL',
  },
  //#endregion

  //#region Secondary Address
  guidRouteL: {
    pageGuid: 'guidPageL', // currently-live-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteN', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteL2', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },

      {
        routeNext: 'guidRouteL2', // Mailing address spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteM', // rent-or-own-coborrower
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteM', // rent-or-own-coborrower
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
    ],
  },

  guidRouteL2: {
    pageGuid: 'guidPageL2', // mailing-address-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteN', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteM', // rent-or-own-coborrower
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteM: {
    pageGuid: 'guidPageM', // rent-or-own-coborrower
    routeNext: 'guidRouteN',
    // routing handled by Events.timeAtAddressSecondary
    // routeNext: 'guidRoutePrevAddressSecondary', < 2 years
  },

  guidRoutePrevAddressSecondary: {
    pageGuid: 'guidPageM2', // 	previous-address-coborrower
    routeNext: 'guidRouteM3',
  },

  guidRouteM3: {
    pageGuid: 'guidPageM3', // prev-rent-or-own-coborrower
    routeNext: 'guidRouteN',
  },
  //#endregion

  //#region Secondary Dependents
  guidRouteN: {
    pageGuid: 'guidPageN',
    ruleGroups: [
      {
        routeNext: 'guidRouteO',
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteP', // anyone-else
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },
  //#endregion

  //#region Secondary Alimony
  guidRouteO: {
    pageGuid: 'guidPageO',
    ruleGroups: [
      {
        routeNext: 'guidRouteHECCo', //Homeownership
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          }
        ],
      },
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ],
      },
      {
        routeNext: 'guidRouteP', // anyone-else
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },
  //#endregion
  //#region Homeownership Education/Counseling CoBorrower
  guidRouteHECCo: {
    pageGuid: 'guidPageHECCo',
    ruleGroups: [
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 0
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      },
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 0
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteHECo', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          }
        ]
      },
      {
        routeNext: 'guidRouteHECo', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 3
          }
        ]
      },
      {
        routeNext: 'guidRouteHCCo', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 2
          }
        ]
      }
    ]
  },
  guidRouteHECo: {
    pageGuid: 'guidPageHECo',
    routeNext: 'guidRouteHECo1',
  },
  guidRouteHECo1: {
    pageGuid: 'guidPageHECo1',
    ruleGroups: [
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          },
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      },
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 1
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteHCCo', //House Counseling
        rules: [
          {
            field: 'loan.$$custom.programTypeCoBorrower',
            operator: RuleExprOp.EQ,
            value: 3
          }
        ]
      }
    ]
  },
  guidRouteHCCo: {
    pageGuid: 'guidPageHCCo',
    routeNext: 'guidRouteHCCo1',
  },
  guidRouteHCCo1: {
    pageGuid: 'guidPageHCCo1',
    ruleGroups: [
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'config.HidePersonalPageLanguageField',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: false,
          }
        ]
      },
      {
        routeNext: 'guidRoutePLC', //language information
        rules: [
          {
            field: 'config.TPOEnvironment',
            operator: RuleExprOp.EQ,
            value: true,
          }
        ]
      },
      {
        routeNext: 'guidRouteP', // anyone-else
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ]
  },
  //#endregion
  //#endregion
  guidRoutePLC: {
    pageGuid: 'guidPagePLC', //language information-coborrower
    ruleGroups: [
      {
        routeNext: 'guidRouteP', // anyone-else
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },
  //#endregion

  //#region Other Borrower
  guidRouteP: {
    pageGuid: 'guidPageP', // anyone-else
    ruleGroups: [
      {
        routeNext: 'guidRoutePA', // contact-information-additional-applicant
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },
  //#endregion

  guidRoutePA: {
    pageGuid: 'guidPagePA', // contact-information-additional-applicant
    routeNext: 'guidRoutePB',
  },

  guidRoutePB: {
    pageGuid: 'guidPagePB', // email-additional-applicant
    ruleGroups: [
      {
        routeNext: 'guidRoutePC', // security-question-additional-applicant
        rules: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.securityAnswer',
            operator: RuleExprOp.IN,
            value: ['', null],
          },
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.NE,
            value: true,
          },
        ]
      },
      {
        routeNext: 'guidRoutePC', // security-question-additional-applicant
        rules: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.securityQuestionId',
            operator: RuleExprOp.IN,
            value: [null, -1],
          },
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.NE,
            value: true,
          },
        ]
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.loan.additionalApplicant.securityAnswer',
            operator: RuleExprOp.NI,
            value: ['', null],
          },
          {
            field: 'loan.$$custom.loan.additionalApplicant.securityQuestionId',
            operator: RuleExprOp.NI,
            value: [null,-1],
          },
        ]
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ]
      }
    ]
  },

  guidRoutePC: {
    pageGuid: 'guidPagePC', // security-question-additional-applicant
    routeNext: 'guidRouteZ',
  },

  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
