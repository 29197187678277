<app-header-simple>
  <div class="header-link" right-column>
    <a routerLink="/login" queryParamsHandling="merge">
      <i aria-hidden="true" role="presentation" class="fa fa-user-circle-o me-1"></i>
      <span class="d-none d-md-inline">Sign-in</span>
    </a>
  </div>
</app-header-simple>

<main automationid="Page_Broker">
  <div class="container">
    <div class="row">
      <div class="col col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div class="card">
          <div class="card-body">
            <app-api-state [state]="brokerInfo" *ngIf="brokerInfo$ | async as brokerInfo">
              <!-- LOADING -->
              <ng-container *ngIf="isLoading && !error">
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
              </ng-container>

              <!-- ERRORS -->
              <div class="my-5" *ngIf="!!error && !isLoading">
                <p class="mb-5" style="font-size: 3em;font-weight: bold">
                  Hey there, we can't seem to find the page you're looking for.
                </p>
                <p style="font-size: 1.5em">
                  Double check the link, and if it still doesn't work, please request a new one from your Loan Officer.
                </p>
              </div>

              <!-- PAGE CONTENT -->
              <ng-container *ngIf="!isLoading && !error && brokerInfo.data">
                <div class="row" automationid="Section_PageContent">
                  <div class="col-12">
                    <h1 class="page-title text-center border-color-primary color-primary" automationid="FullName">
                      {{ brokerInfo.data?.firstName }} {{ brokerInfo.data?.lastName }}
                    </h1>
                    <div class="broker-info text-center">
                      <p>Please contact your loan officer listed below, to<br> complete your mortgage application online.</p>
                      <div class="broker-type" *ngIf="brokerInfo.brokerCompanyName" automationid="CompanyName">
                        {{ brokerInfo.data?.brokerCompanyName }}
                      </div>
                      <div class="broker-nmls" automationid="BrokerNMLS">Broker NMLS# {{ brokerInfo.data?.nmlsNumber }}</div>
                      <div class="company-nmls" automationid="CompanyNMLS">Company NMLS# {{ brokerInfo.data?.brokerCompanyNMLSNumber }}</div>
                    </div>
                    <!-- .broker-info -->

                    <div class="contact-methods">
                      <a
                        *ngIf="brokerInfo.data?.phones?.length"
                        class="contact-method cell-phone"
                        href="tel:{{ brokerInfo.data?.phones[0].cleanNumber }}"
                        aria-label="Call your broker's cell phone"
                        automationid="CellPhone"
                      >
                        <span class="icon">
                          <span class="icon-container color-primary">
                            <i class="fa fa-mobile" aria-hidden="true"></i>
                          </span>
                        </span>
                        <span class="value">
                          <span class="type">Cell: </span>{{ brokerInfo.data?.phones[0].cleanNumber | phoneNumber }}
                        </span>
                      </a>
                      <a
                        *ngIf="brokerInfo?.phones?.length && (brokerInfo?.phones)[1]"
                        href="tel:{{ brokerInfo.data?.phones[1].cleanNumber }}"
                        class="contact-method office-phone"
                        aria-label="Call your broker's office phone"
                        automationid="OfficePhone"
                      >
                        <span class="icon">
                          <span class="icon-container color-primary">
                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                          </span>
                        </span>
                        <span class="value">
                          <span class="type">Office: </span>{{ brokerInfo.data?.phones[1].cleanNumber | phoneNumber }}
                        </span>
                      </a>
                      <a
                        class="contact-method email"
                        href="mailto:{{ brokerInfo.data?.emailAddress }}"
                        aria-label="Email your broker"
                        automationid="Email"
                      >
                        <div class="icon">
                          <div class="icon-container color-primary">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div class="value">{{ brokerInfo.data?.emailAddress }}</div>
                      </a>
                      <a
                        class="contact-method address"
                        href="#"
                        (click)="openAddress(brokerInfo.data)"
                        aria-label="view business address on Google maps"
                        automationid="Address"
                      >
                        <span class="icon">
                          <span class="icon-container color-primary">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                          </span>
                        </span>
                        <span class="value">
                          {{ brokerInfo.data?.brokerCompanyStreetAddress }}<br />
                          {{ brokerInfo.data?.brokerCompanyCity }}, {{ brokerInfo.data?.brokerCompanyState }}
                          {{ brokerInfo.data?.brokerCompanyZip }}
                        </span>
                      </a>
                    </div>

                    <div role="alert" class="alert alert-danger icon" *ngIf="error">{{ error }}</div>
                  </div>
                </div>
              </ng-container>
            </app-api-state>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
