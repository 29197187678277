<div class="container" automationid="Page_Home">
  <div class="row">
    <div class="col col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body">
          <ng-container
            *ngIf="{ megaLoan: megaLoan$ | async, appState: appState$ | async, config: config$ | async } as route"
          >
            <app-api-state [state]="route.megaLoan" [errorShow]="false" [showCustomLoadingContent]="true">
              <app-loading-graphic loadingContent>
                <h2>Please wait while we prepare <strong>your loan information</strong></h2>
              </app-loading-graphic>
              <app-api-state [state]="route.appState" [errorShow]="false" [showCustomLoadingContent]="true">
                <div class="row">
                  <div class="col-md-6">
                    <!------------------
                        APPLICATION
                    ------------------->
                    <ng-container *ngIf="milestoneState === milestoneStateEnum.Application">
                      <div class="text-center mb-5">
                        <h1 *ngIf="route?.config && (route?.config)['clover.milestones.application.title'];else applicationPageTitle" [innerHtml]="(route?.config)['clover.milestones.application.title'].value"></h1>
                      <ng-template #applicationPageTitle>Let's begin your application</ng-template>
                    </div>
                      <div class="welcome-graphic">
                        <ng-container
                          *ngIf="
                            route?.config && (route?.config)['clover.milestones.application.image'];
                            else applicationMainImage
                          "
                        >
                          <img [src]="(route?.config)['clover.milestones.application.image'].value" alt="" />
                        </ng-container>
                        <ng-template #applicationMainImage>
                          <img src="/assets/img/milestones/apply.png" alt="" />
                        </ng-template>
                      </div>
                    </ng-container>
                    <!------------------
                        DOCUMENTATION
                    ------------------->
                    <ng-container *ngIf="milestoneState === milestoneStateEnum.Documentation">
                      <div class="text-center mb-5">
                      <h1 *ngIf="
                          route?.config && (route?.config)['clover.milestones.documentation.title'];
                          else documentationPageTitle" [innerHtml]="(route?.config)['clover.milestones.documentation.title'].value"></h1>
                      <ng-template #documentationPageTitle>
                        You're doing great!�Let's continue...
                      </ng-template>
                    </div>
                      <div class="welcome-graphic">
                        <ng-container
                          *ngIf="
                            route?.config && (route?.config)['clover.milestones.documentation.image'];
                            else documentationMainImage
                          "
                        >
                          <img [src]="(route?.config)['clover.milestones.documentation.image'].value" alt="" />
                        </ng-container>
                        <ng-template #documentationMainImage>
                          <img src="/assets/img/milestones/documentation.png" alt="" />
                        </ng-template>
                      </div>
                    </ng-container>
                    <!------------------
                        CLOSING
                    ------------------->
                    <ng-container *ngIf="milestoneState === milestoneStateEnum.Closing">
                      <div
                        class="text-center mb-5">
                        <h1 *ngIf="
                          route?.config && (route?.config)['clover.milestones.closing.title'];
                          else closingPageTitle" [innerHtml]="(route?.config)['clover.milestones.closing.title'].value"></h1>
                      <ng-template #closingPageTitle>
                        You're doing great! Let's continue...
                      </ng-template>
                    </div>
                      <div class="welcome-graphic">
                        <ng-container
                          *ngIf="
                            route?.config && (route?.config)['clover.milestones.closing.image'];
                            else closingMainImage
                          "
                        >
                          <img [src]="(route?.config)['clover.milestones.closing.image'].value" alt="" />
                        </ng-container>
                        <ng-template #closingMainImage>
                          <img src="/assets/img/start-home.png" alt="" />
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-md-6">
                    <div class="sr-only">The application process can be broken down into the following steps:</div>
                    <ul class="application-nav" *ngIf="appState?.form1003">
                      <!------------------
                          APPLICATION
                      ------------------->
                      <li
                        [ngClass]="{
                          active: milestoneState === milestoneStateEnum.Application,
                          complete:
                            milestoneState === milestoneStateEnum.Documentation ||
                            milestoneState === milestoneStateEnum.Closing
                        }"
                        [attr.aria-current]="milestoneState === milestoneStateEnum.Application ? 'step' : ''"
                        automationid="Section_Application"
                      >
                        <h2>
                          <span
                            *ngIf="
                              route?.config && (route?.config)['clover.milestones.application.name'];
                              else applicationName
                            "
                            [innerHtml]="(route?.config)['clover.milestones.application.name'].value"
                          ></span>
                          <ng-template #applicationName>Application</ng-template>
                        </h2>
                        <p *ngIf="!route.appState.form1003.completedDate">
                          <span
                            *ngIf="
                              route?.config && (route?.config)['clover.milestones.application.text'];
                              else applicationText
                            "
                            [innerHtml]="(route?.config)['clover.milestones.application.text'].value"
                          ></span>
                          <ng-template #applicationText
                            >Tell us a little about yourself so we can find you the right loan.</ng-template
                          >
                        </p>
                        <p *ngIf="route.appState.form1003.completedDate">
                          Submitted on {{ route.appState.form1003.completedDate | date: 'short' }}
                        </p>
                        <div class="pb-3" *ngIf="milestoneState === milestoneStateEnum.Application">
                          <button mat-flat-button color="accent" (click)="appStateChange('form1003', route?.megaLoan?.data)" automationid="BtnAppStateChange">
                            <span *ngIf="!route.appState.form1003.isStarted">Begin</span>
                            <span *ngIf="route.appState.form1003.isStarted">Continue</span>
                          </button>
                        </div>
                      </li>
                      <!------------------
                          DOCUMENTATION
                      ------------------->
                      <li
                        [ngClass]="{
                          active: milestoneState === milestoneStateEnum.Documentation,
                          complete: milestoneState === milestoneStateEnum.Closing
                        }"
                        [attr.aria-current]="milestoneState === milestoneStateEnum.Documentation ? 'step' : ''"
                        automationid="Section_Documentation"
                      >
                        <h2>
                          <span
                            *ngIf="
                              route?.config && (route?.config)['clover.milestones.documentation.name'];
                              else documentationTitle
                            "
                            [innerHtml]="(route?.config)['clover.milestones.documentation.name'].value"
                          ></span>
                          <ng-template #documentationTitle>Documentation and Review</ng-template>
                        </h2>
                        <p>
                          <span
                            *ngIf="
                              route?.config && (route?.config)['clover.milestones.documentation.text'];
                              else documentationText
                            "
                            [innerHtml]="(route?.config)['clover.milestones.documentation.text'].value"
                          ></span>
                          <ng-template #documentationText>
                            We'll need supporting documents to help us verify your information. We may request
                            additional items to get your loan approved.</ng-template
                          >
                        </p>
                        <div class="pb-3" *ngIf="milestoneState === milestoneStateEnum.Documentation">
                          <button mat-flat-button color="accent" (click)="appStateChange('dashboard')">
                            <span *ngIf="!route.appState.dashboard.isStarted">Begin</span>
                            <span *ngIf="route.appState.dashboard.isStarted">Continue</span>
                          </button>
                        </div>
                      </li>
                      <!------------------
                          CLOSING
                      ------------------->
                      <li
                        [ngClass]="{ active: milestoneState === milestoneStateEnum.Closing }"
                        [attr.aria-current]="milestoneState === milestoneStateEnum.Closing ? 'step' : ''"
                        automationid="Section_Closing"
                      >
                        <h2>
                          <span
                            *ngIf="
                              route?.config && (route?.config)['clover.milestones.closing.name'];
                              else closingTitle
                            "
                            [innerHtml]="(route?.config)['clover.milestones.closing.name'].value"
                          ></span>
                          <ng-template #closingTitle>Closing your loan</ng-template>
                        </h2>
                        <p>
                          <span
                            *ngIf="route?.config && (route?.config)['clover.milestones.closing.text']; else closingText"
                            [innerHtml]="(route?.config)['clover.milestones.closing.text'].value"
                          >
                          </span>
                          <ng-template #closingText>This is your last step to getting your mortgage.</ng-template>
                        </p>
                        <div class="pb-3" *ngIf="milestoneState === milestoneStateEnum.Closing">
                          <button mat-flat-button color="accent" (click)="appStateChange('dashboard')">
                            Continue
                          </button>
                        </div>
                      </li>
                    </ul>

                    <!--  -->
                    <div *ngIf="!isProd">
                      <hr />
                      <h3>QA Tools</h3>
                      <ul>
                        <li><a (click)="appStateClear()">Clear App State</a></li>
                      </ul>
                      <ul>
                        <li><a (click)="configChange(isURLA2020())" class="me-2">URLA 2009</a> <strong *ngIf="!isURLA2020()">Active</strong></li>
                        <li><a (click)="configChange(isURLA2020())" class="me-2">URLA 2020</a> <strong *ngIf="isURLA2020()">Active</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </app-api-state>
            </app-api-state>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
