import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const declarationsPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Declarations Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Declarations',
          sectionTitle: `Next up, a little more about your financial history`,
          sectionImage: `/assets/img/application/declarations.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Declarations',
    titleShow: false,
    pageId: 'declarations',
    validatorId: CPOSPageValidatorsEnum.declarations,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do we need this information?`,
          body: `
            <p>These questions are part of the standard loan application (Uniform Residential Loan Application) that all lenders have adopted.</p>
          `,
        },
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you been declared bankrupt within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder:
              'Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you a party to a lawsuit?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you directly or indirectly been obligated on any loan
                        which resulted in foreclosure, transfer in title in lieu of foreclosure, or judgement?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.obligatedLoanIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you presently delinquent or in default on any Federal debt or any
            other loan, mortgage, financial obligation, bond, or loan guarantee?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you obligated to pay alimony, child support, or separate maintenance?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.alimonyChildSupportObligation',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Is any part of the down payment borrowed?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-maker or endorser on a note?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a US Citizen?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a Permanent Resident Alien?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in a property in the last three years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Solely by yourself',
                value: 0,
              },
              {
                label: 'Jointly with your spouse',
                value: 1,
              },
              {
                label: 'Jointly with another person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          },
        ],
      },
    ],
  }, // end page

  guidPageBPrimary: {
    // title: 'Summary',
    pageId: 'declarations-primary',
    validatorId: CPOSPageValidatorsEnum.declarations,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you been declared bankrupt within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder:
              'Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you a party to a lawsuit?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you directly or indirectly been obligated on any loan
                        which resulted in foreclosure, transfer in title in lieu of foreclosure, or judgement?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.obligatedLoanIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you presently delinquent or in default on any Federal debt or any
            other loan, mortgage, financial obligation, bond, or loan guarantee?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you obligated to pay alimony, child support, or separate maintenance?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.alimonyChildSupportObligation',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Is any part of the down payment borrowed?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-maker or endorser on a note?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a US Citizen?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a Permanent Resident Alien?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in a property in the last three years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Solely by yourself',
                value: 0,
              },
              {
                label: 'Jointly with your spouse',
                value: 1,
              },
              {
                label: 'Jointly with another person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          },
        ],
      },
    ],
  }, // end page

  guidPageBSecondary: {
    // title: 'Summary',
    pageId: 'declarations-secondary',
    validatorId: CPOSPageValidatorsEnum.declarations,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you been declared bankrupt within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder:
              'Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you a party to a lawsuit?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you directly or indirectly been obligated on any loan
                        which resulted in foreclosure, transfer in title in lieu of foreclosure, or judgement?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.obligatedLoanIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you presently delinquent or in default on any Federal debt or any
            other loan, mortgage, financial obligation, bond, or loan guarantee?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you obligated to pay alimony, child support, or separate maintenance?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.alimonyChildSupportObligation',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Is any part of the down payment borrowed?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-maker or endorser on a note?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a US Citizen?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a Permanent Resident Alien?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.permanentResidentAlienIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in a property in the last three years?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Solely by yourself',
                value: 0,
              },
              {
                label: 'Jointly with your spouse',
                value: 1,
              },
              {
                label: 'Jointly with another person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          },
        ],
      },
    ],
  }, // end page

  guidPageZ: {
    title: 'Summary',
    pageId: 'summary',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary',
      },
    ],
  }, // end page
};
