import {  Injectable} from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';
import { AppSettings } from '../app.settings';


@Injectable({
  providedIn: 'root',
})
export class RegisterGuard implements CanActivate {
  constructor(
    private settings: AppSettings,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
   if (this.settings && this.settings.clientId && this.settings.clientId.toLowerCase() !== 'arv') {
     return true;
    }
    let navigationExtras: NavigationExtras = { queryParamsHandling: 'preserve', queryParams: route.queryParams };
    this.router.navigateByUrl('/login', navigationExtras);
   return false;

  }

}
