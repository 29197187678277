import famCustomizations from './fam-customizations';
import fimCustomizations from './fim-customizations';
import envCustomizations from './env-customizations';
import { URLAFormTypeEnum } from 'src/app/shared/models';

export type ClientCustomizations = (
  sections: CvFormBuilder.Section[],
  urlaType: URLAFormTypeEnum,
) => CvFormBuilder.Section[];

function getClientCustomizations(clientId: string): ClientCustomizations {
  switch (clientId.toLocaleLowerCase()) {
    case 'fam':
      return famCustomizations;
    case 'fim':
      return fimCustomizations;
    case 'env':
      return envCustomizations;
    default:
      return sections => sections;
  }
}

export default getClientCustomizations;
