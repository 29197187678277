<!-- Display global/app errors -->
<div class="container p-3" *ngIf="error$ | async as error">
  <app-error [error]="error" [canClose]="false" [showError]="true">
    <p class="d-inline-block">
      Unfortunately you have run in to an issue with the pre-release version of Clover. Please try reloading it by
      pressing <strong>Control + F5.</strong>
    </p>
    <p>
      If that doesn't fix the issue then please copy+paste the contents of this page and email it to the administrators
      responsible for maintaining this app.
    </p>
  </app-error>
</div>
<!-- Main Router -->
<router-outlet></router-outlet>
