<form>
  <mat-form-field>
    <a
      class="autocomplete-remove"
      (click)="clearSelected()"
      *ngIf="autoCompleteControl.value && autoCompleteControl.value !== '' && autoCompleteControl?.value?.length !== 0"
    >
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </a>
    <input
      [required]="required"
      type="text"
      matInput
      [placeholder]="placeholder"
      [formControl]="autoCompleteControl"
      [matAutocomplete]="auto"
      (keyup)="keyUp($event)"
      [attr.automationid]="'AutoComplete_' + placeholder"
      [maxlength]="maxlength"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="labelDisplay.bind(this)"
      (optionSelected)="selectedOption($event)"
    >
      <mat-option *ngFor="let option of (filteredOptions$ | async)" [value]="option">
        {{ option[this.termLabel] || option }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="hint">
      {{ hint }}
    </mat-hint>
    <mat-error
      *ngIf="autoCompleteControl.invalid && autoCompleteControl.touched && autoCompleteControl.errors"
      class="anim-fade-in text-center"
      style="padding-top: 6px;"
    >
      <div *ngIf="autoCompleteControl?.errors?.required" class="small" style="font-size:11.6px;">
        {{ placeholder || 'This field' }} is <strong>required</strong>
      </div>
    </mat-error>
  </mat-form-field>
</form>
