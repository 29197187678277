import actionCreatorFactory from 'typescript-fsa';
import { AppStore } from '$shared';
import { ICPOSAppConfig } from '../../models';

const actionCreator = actionCreatorFactory('ui');

export const UIStoreActions = {
  RESET: actionCreator<null>('RESET'),
  REHYDRATE: actionCreator<AppStore.Ui>('REHYDRATE'),
  MODAL_OPEN: actionCreator<{
    modalId: string;
    options: { size: 'sm' | 'lg' | 'xl' | 'full' };
    data: any;
  }>('MODAL_OPEN'),
  MODAL_UNLOAD: actionCreator<null>('MODAL_UNLOAD'),
  TAB_CHANGE: actionCreator<{ tabInstanceId: string; tabId: number }>('TAB_CHANGE'),
  TOGGLES: actionCreator<AppStore.Toggles>('TOGGLES'),
  FORM_BUILDER_CHANGE: actionCreator<AppStore.FormBuilder>('FORM_BUILDER_CHANGE'),
  CONFIG: actionCreator<Record<string, ICPOSAppConfig>>('APP_CONFIG_STYLING'),
};
