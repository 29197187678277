import { ButtonActions, RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const reviewPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'You are ready to <strong>submit</strong> your loan information!',
    pageId: 'final',
    analyticsId: 'You are ready to <strong>submit</strong> your loan application!',
    isLastPage: true,
    showButtonBack: false,
    showButtonNext: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="text-center mb-3"><i style="font-size: 5.4rem" class="cvi cvi-submit-ready color-primary"></i></div>`,
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p class="text-center mb-5">Once you submit, your loan officer will review your loan information and contact you to talk about your loan.</p>`,
      },

      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Submit',
            class: 'mat-accent',
            actions: [
              {
                type: ButtonActions.action,
                params: {
                  submit: true,
                },
              },
            ],
          },
        ],
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Thanks for submitting! How was your <strong>experience</strong>?',
    pageId: 'nps',
    isLastPage: true,
    // showButtonBack: false,
    // showButtonNext: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="d-flex justify-content-center mb-3"><i style="font-size: 5.4rem" class="cvi cvi-submit-done color-primary"></i></div>`,
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div class="mb-5">On a scale from 0 through 10, 0 being the worst and 10 being the best, how likely are you to recommend this experience to a colleague or friend?</div>`,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'nps',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        hidden: true,
        placeholder: 'Rating',
        field: 'loan.transactionInfo.loanApplications[0].npsScore',
        formFieldType: 'number',
      },
    ],
  }, // end page

  
  guidPageC: {
    pageId: 'ia',
    isLastPage: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div style="text-align: center; color: #046492!important; font-size: 1.36rem;"><span style="color: #6ca942;"><i class="fa fa-trophy fa-lg mr-2 fa-2x" aria-hidden="true"></i></span><strong>Congratulations {{loan.transactionInfo.borrowers[0].firstName}}! You\'ve been Pre-Approved!</strong></div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].firstName',
              operator: RuleExprOp.NE,
              value: true,
            }
          ]
        }
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div style="text-align: center; color: #046492!important; font-size: 1.36rem;"><span style="color: #6ca942;"><i class="fa fa-trophy fa-lg mr-2 fa-2x" aria-hidden="true"></i></span><strong>Congratulations {{loan.transactionInfo.borrowers[0].firstName}} & {{loan.transactionInfo.borrowers[1].firstName}}! You\'ve been Pre-Approved!</strong></div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.borrowers[1].firstName',
              operator: RuleExprOp.NE,
              value: false,
            }
          ]
        }
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div style="text-align: center;">Your loan officer will be in contact soon to help you on your journey.</div><div style="text-align:center;">You can log back in to view your pre-approval letter at any time.</div>`,
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'View Pre-Approval Letter',
            class: 'mat-accent',
            actions: [
              {
                type: ButtonActions.viewLetter,
                params: {
                  submit: true,
                },
              },
            ],
          },
        ]
      },
      <CvFormBuilder.FormField>{
        type: 'html',
        html: `<div class="mat-card mat-card-review ">
        <div class="section-label section-label-review"><strong>Loan Summary</strong></div>
        <div class="row page-review-row">
          <div class="col-md-4">
            <div class="row-label">Loan Purpose:</div>
            <div>
              Purchase
            </div>
          </div>
          <div class="col-md-4">
            <div class="row-label">Loan Amount:</div>
            <div>
              {{loan.loanAmount | currency}}
            </div>
          </div>
          <div class="col-md-4">
            <div class="row-label">Purchase Price:</div>
            <div>
              {{loan.$$custom.loan.addressSubject.purchasePrice | currency}}
            </div>
          </div>
        </div>
        <div class="row page-review-row">
          <div class="col-md-4">
            <div class="row-label">Est. Monthly Payment</div>
            <div style="font-style:italic; font-size: 10px; color: #767676;">*Taxes & insurance included</div>
            <div>
              {{loan.$$custom.newMonthlyPayment | currency}}
            </div>
          </div>
          <div class="col-md-4">
            <div class="row-label">Est. Cash to Close</div>
            <div>
              {{loan.$$custom.cashToClose | currency}}
            </div>
          </div>
        </div>
        </div>`
      },
    ],
    
  }, // end page
};
