<div class="modal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header color-primary">
        <h1 class="page-title text-center">
          APPLY FOR NEW LOAN</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="close" 
         (click)="submit()">
          <span aria-hidden="false">&times;</span>
        </button>
      </div>

      <div class="modal-body" id="modal-body" focusContain automationid="Section_ModalBody">
        <p class="body-content-1 ">It looks like we don't have a Loan Team Member for you to create a loan.</p>
        <!-- <i class="cvi-new-loan-no-lo color-primary" style="font-size:15em;display: block;"></i> -->
        <p>Please contact the Loan Team you're working with and ask them to send you a new applicaton invite.</p>
      </div>

      <div class="modal-footer justify-content-between" automationid="Section_Footer">
        <button
        type="button"
        mat-flat-button
        color="primary-outline"
        class="dismiss color-primary"
        tabindex="1"
        mat-dialog-close
        automationid="BtnClose"
        (click)="submit()"
        aria-label="Close">
          Close
      </button>
      </div>
    </div>
  </div>
</div>
