import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const declarationsRouting: CvFormBuilder.Routes = {
  // start
  guidRouteA: {
    pageGuid: 'guidPageA',
    ruleGroups: [
      {
        routeNext: 'guidRouteBPrimary',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  // start
  guidRouteB: {
    pageGuid: 'guidPageB',
    sectionComplete: true,
    // routeNext: 'guidRouteZ',
  },

  guidRouteBPrimary: {
    pageGuid: 'guidPageBPrimary',
    routeNext: 'guidRouteBSecondary',
  },

  guidRouteBSecondary: {
    pageGuid: 'guidPageBSecondary',
    sectionComplete: true,
    // routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
