import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '$shared';
import { ApiService } from '$api';
import { ModalsService } from '$modals';
import { LoanUtils } from '../utils/loan-utils';

@Injectable({
  providedIn: 'root',
})
export abstract class LoanLockGuard implements CanActivate {
  constructor(
    private api: ApiService,
    private modals: ModalsService,
    protected settings: AppSettings,
  ) { }

  // Used only for the 1003. Can be used in the future for any 1003-specific logic
  canActivate() {
    if (this.settings.loanId) {
      return this.api.megaLoan.get().pipe(map((loan) => {
        if (loan) {
          /* If the loanAccessByUserId is not null and different from the current user
              open the loan lock modal
          */
          const canOpenLoanLockModal = LoanUtils.isLockedExclusivelyByOtherUser(loan, this.settings);
          if (canOpenLoanLockModal) {
            this.modals.open('LoanLockModalComponent', false, 'lg', {
              loanAccessByBorrowerFullName: LoanUtils.getLoanAccessByBorrowerFullName(loan),
              isTaskUnavailable: this.getIsTaskUnavailable(),
              isHeaderCloseButtonVisible: this.isHeaderCloseButtonVisible()
            }, null, { disableClose: true })
            .afterClosed()
            .subscribe(() => {
              this.logout();
            });
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }));
    } else {
      return of(false);
    }
  }

  abstract logout(): void;
  abstract getIsTaskUnavailable(): boolean;
  abstract isHeaderCloseButtonVisible(): boolean;
}
