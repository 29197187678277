<div id="modal" aria-modal="true" aria-describedby="modal-title" role="alertdialog" focusContain
  automationid="Page_LoanLock">
  <div class="modal-header" automationid="Section_Header">
    <h2 class="modal-title color-primary" tabindex="1" id="modal-title">
      <ng-container *ngIf="isTaskUnavailable; else loanLockTitleContent">TASK IS CURRENTLY UNAVAILABLE</ng-container>
      <ng-template #loanLockTitleContent>YOUR LOAN IS BEING WORKED ON</ng-template>
    </h2>
    <button *ngIf="isHeaderCloseButtonVisible" type="button" class="close" aria-label="Close" mat-dialog-close tabindex="1" automationid="BtnClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="modal-body" id="modal-body">
    <span id="primary-info-text" class="info-text">
      <ng-container *ngIf="isTaskUnavailable; else loanLockMessageContent">
        It appears your Loan Team is currently working on your
        loan. As a result, this task is currently unavailable to you.
        Please try again later.
      </ng-container>
      <ng-template #loanLockMessageContent>
        <ng-container *ngIf="loanAccessIsBorrower; else loanTeamInLoanContent">
          Another borrower <ng-container *ngIf="loanAccessByBorrowerFullName">({{loanAccessByBorrowerFullName}})</ng-container> on this loan is currently logged in and working on the loan.
          Please try again later.
        </ng-container>
      </ng-template>
      <ng-template #loanTeamInLoanContent>
        It appears your Loan Team is currently working on your loan.
        Please come back
        later.
      </ng-template>
    </span>
    <!-- Put the loan lock icon in here -->
    <div class="loan-lock-icon-container">
      <div class="icon color-primary">
        <i aria-hidden="true" class="cvi cvi-loan-locked"></i>
      </div>
    </div>
    <!-- If loan has a loan team, loop through and display relevant info -->
    <ng-container *ngIf="{
      loanContacts: loanContacts$ | async
      } as route">
      <ng-container *ngIf="route?.loanContacts?.length > 0">
        <span class="info-text">If you have any questions, please contact your Loan Team:</span>
        <ng-container *ngFor="let contact of route?.loanContacts">
          <div class="loan-team-container">
            <span id="team-member-name" class="loan-team-field">{{contact?.name}}</span>
            <span id="team-member-position"
              class="loan-team-field">{{getLoanContactTypeText(contact?.loanContactType)}}</span>
            <a id="team-member-number" class="loan-team-field" [href]="'tel:' + (contact.phone | phoneNumber: true)" target="_blank">{{contact?.phone}}</a>
            <a id="team-member-email" class="loan-team-field" [href]="'mailto:' + contact.email" target="_blank">{{contact?.email}}</a>
            <span></span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>
  <div class="close-button-container">
    <button class="btn btn-outline-secondary w-50" aria-label="Close" tabindex="1" (click)="determineSessionStatus()"
      (keydown.enter)="determineSessionStatus()" appFocus>
      Close
    </button>
  </div>
</div>
