import { RuleExprOp } from "src/app/features/cvFormBuilder/shared/cvFormBuilder.enums";

export const reviewRouting: CvFormBuilder.Routes = {
  // Final review
  guidRouteA: {
    pageGuid: 'guidPageA',
    ruleGroups: [
      {
        routeNext: 'guidRouteC',
        rules: [
          {
            field: 'loan.$$custom.instantApprovalStatus',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.$$custom.instantApprovalStatus',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ]
  },

  guidRouteB: {
    pageGuid: 'guidPageB',
    sectionComplete: true,
  },

  guidRouteC: {
    pageGuid: 'guidPageC',
    routeNext: 'guidRouteB',
  },
};
