import { Component, OnInit } from '@angular/core';
import { AppSettings, AuthService } from '$shared';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ModalsService } from '$modals';
import { UIStoreService } from '$ui';
import { ApiService } from '$api';
import { combineLatest } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { BusinessContactPhoneTypeEnum, CPOSNavigationPathEnum } from 'src/app/shared/models';
import { ApplicationNavigationService } from 'src/app/shared/services/application-navigation.service';


enum WhichNavEnum {
  Neither = 1,
  Application = 2,
  Dashboard = 3,
}

@Component({
  selector: 'app-nav',
  styleUrls: ['./nav.component.scss'],
  templateUrl: './nav.component.html',
})
export class NavComponent implements OnInit {
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  /** Company name */
  public companyName = this.settings.clientName;
  /** Is the main menu open on mobile */
  public isMainNavOpen = false;
  /** Is the communications menu open on mobile */
  public isCommNavOpen = false;
  /** Is the section menu open on mobile */
  public isSectionNavOpen = false;
  /**   Does the app have an update */
  // public hasUpdate$ = this.vm.hasUpdate$;
  /** App version */
  public version = this.settings.version;
  /** Used to determine which dropdown nav to show */
  public whichNavToShow: WhichNavEnum = WhichNavEnum.Neither;
  /** Used to compare in the UI */
  whichNavEnum = WhichNavEnum;
  /** Work phone number from the Lead Source, if there is one */
  private leadSourceWorkPhone$ = this.api.getApiStoreData(this.api.select.broker$).pipe(map((broker) => {
    // Error handling
    if (!(broker && broker.phones && broker.phones.length)) return null;

    const allWorkPhones = broker.phones.filter(((phoneNumber: any) => {
      return phoneNumber.type === BusinessContactPhoneTypeEnum.Work
        && phoneNumber.number !== 'about:blank';
    }));
    return allWorkPhones.length
      ? allWorkPhones[0].number
      : null;
  }));
  /** Get the loan officer phone number */
  private wordpressPhoneNumber$ = this.ui.select.config$.pipe(
    map(config => {
      if (config
        && config['config.leadsource.default.phone']
        && config['config.leadsource.default.phone'].value
        && config['config.leadsource.default.phone'].value !== 'about:blank'
      ) {
        return config['config.leadsource.default.phone'].value;
      }
      return null;
    }));
  public loPhoneNumber$ = combineLatest(this.leadSourceWorkPhone$, this.wordpressPhoneNumber$)
    .pipe(map(([leadSourcePhone, wordpressPhone]) => {
      return leadSourcePhone || wordpressPhone || null;
    }));

  constructor(
    private auth: AuthService,
    private settings: AppSettings,
    public modals: ModalsService,
    // private vm: VersionManagementService,
    private router: Router,
    private ui: UIStoreService,
    private api: ApiService,
    private analytics: AnalyticsService,
    private navService: ApplicationNavigationService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isMainNavOpen = false;
      this.isCommNavOpen = false;
      this.isSectionNavOpen = false;

      // Determine which nav should appear in the mobile dropdown
      const currentUrl = this.router.url;
      if (currentUrl.startsWith('/application')) {
        this.whichNavToShow = WhichNavEnum.Application;
      } else if (currentUrl.startsWith('/tasks')) {
        this.whichNavToShow = WhichNavEnum.Dashboard;
      } else {
        this.whichNavToShow = WhichNavEnum.Neither;
      }
    });
  }

  ngOnInit(): void {
  }

  public track() {
    this.analytics.trackEvent('Hamburger');
  }


  public logOut() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('esign')) {
      this.analytics.trackEvent('ESign Back');
    }
    this.auth.unsubscribeServices();

    this.auth.logOut(true);
  }

  public onMyLoansClicked() {
    this.changeDetectedPopup(`/${CPOSNavigationPathEnum['my-loans']}`);
  }

  public onMyAccountClicked() {
    this.changeDetectedPopup(`/${CPOSNavigationPathEnum['my-account']}`);
  }

  private changeDetectedPopup(navigateUrl: string) {
    if (this.ui.form1003 && this.ui.form1003.get('loan.$$custom.loan.isSubjectPropertyInValid') &&
      this.ui.form1003.get('loan.$$custom.loan.isSubjectPropertyInValid').value) {
      this.navService.navigatingTo = navigateUrl;
      this.isMainNavOpen = false;
      this.navService.setCanNavigateTo(false);
    } else {
      this.router.navigateByUrl(navigateUrl);
    }

  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
          const swipe = direction[0] < 0 ? 'left' : 'right';
          if(swipe === 'left')
          {
            this.isMainNavOpen = false;
          }
          if(swipe === 'right')
          {
            this.isCommNavOpen = false;
          }
      }
    }
  }
}
