import { Injectable } from '@angular/core';
import { ICPOSExternalLeadImport, CPOSExternalLeadImport, ExternalLeadImportViewModel } from 'src/app/shared/models';
import { AppSettings } from "../app.settings";
import { HttpClient } from "@angular/common/http";
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  registrationInfo: FormGroup
  registrationPerformedFromExternalImport: boolean = false
  readonly loanPurposeTypeKey: string = "loanPurposeType"
  readonly zipCodeKey: string = "zip"
  readonly stateNameKey: string = "stateName"
  readonly preferredPhoneKey: string = "preferredPhone"
  readonly preferredPhoneTypeKey: string = "preferredPhoneType"
  readonly emailKey: string = "email"
  readonly lastNameKey: string = "lastName"
  readonly firstNameKey: string = "firstName"

  constructor(
    private settings: AppSettings,
    private http: HttpClient,
  ) { }

  getExternalLeadImport(leadId: string): Promise<ICPOSExternalLeadImport> { 
    return this.getExternalLeadImportAsync(leadId).then(result => {
      if (result != null) {
        return new CPOSExternalLeadImport(result)
      }
      else {
        return null
      }
    })
  }

  async getExternalLeadImportAsync(leadId: string) {
    const url = `${this.settings.apiUrl}/api/ConsumerSiteService/GetExternalLeadImport`;
    const params = {
      leadId: leadId
    };
    
    let retrievedResult = await this.http.get<IGetExternalLeadImportResponse>(url, { params }).toPromise<IGetExternalLeadImportResponse>().then(result => {
      return result
    })
      .catch(error => {
        console.log("An error occurred trying to retrieve external lead import: " + error)
        return null
      })

    return retrievedResult != null ? retrievedResult.Response : null
  }
}

export interface IGetExternalLeadImportResponse {
  Response: ExternalLeadImportViewModel
}
