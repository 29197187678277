

import {
  ILiabilityViewModel,
  IIncomeInfoViewModel,
  PeriodTypeEnum,
  IncomeTypeEnum,
  IAssetViewModel,
  ICPOSEmployment
} from 'src/app/shared/models';

export const defaultModels = {
  'loanParticipants.loanParticipants': <any>{
    hasEmailChanged: false,
    companyName: '',
    contactFullname: '',
    companyLicenseNumber: '',
    /**
    companyPhoneNumber: null,
    */

    companyPhoneNumber: {
      parentId: null,
      recordId: null,
      value: {
        phoneNumber: '',
        isDeleted: false,
        phoneNumberType: 3,
      },
      isDeleted: false,
      isPreferred: false,
    },
    companyFaxNumber: {
      parentId: null,
      recordId: null,
      value: {
        phoneNumber: '',
        isDeleted: false,
        phoneNumberType: 3,
      },
      isDeleted: false,
      isPreferred: false,
    },
    companyType: 11,
    realtorType: 1,
    licenseNumber: '',
    notes: '',
    isRealtor: false,
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    firstName: '',
    middleName: '',
    lastName: '',
    title: '',
    propertyList: [
      {
        loanId: null,
        loanApplicationId: null,
        propertyId: null,
        buyingStage: null,
        cltv: null,
        costOfImprovements: null,
        currentEstimatedValue: null,
        currentMortgageBalance: null,
        describeImprovements: null,
        downPayment: 0,
        downPaymentSource: 0,
        downPaymentType: 0,
        doYouPayMortgageInsurance: null,
        doYouPayMortgageInsuranceDescription: null,
        fips: null,
        firstMortgageHolder: null,
        firstMortgageAmount: null,
        grossRentalIncome: null,
        hasAppraisedValueHistory: false,
        householdMembersEx: [],
        hcltv: null,
        improvementsCompleted: null,
        includeTaxes: null,
        isChildProperty: false,
        isNonWarrantableCondo: false,
        isRemoved: false,
        isSubjectProperty: false,
        ltv: null,
        mortgagePaymentMethod: null,
        mortgagePaymentTimePeriod: null,
        negativeCashFlowAmount: 0,
        needPreApproval: false,
        occupancyType: 0,
        ownershipPercentage: null,
        purchaseDate: null,
        purchasePrice: null,
        secondMortgageAmount: null,
        structure: null,
        totalAmount: null,
        vacancyPercentage: null,
        yearAcquired: null,
        yearBuilt: null,
        propertyExpenses: [],
        propertyExpensesOther: null,
        appraisedValue: null,
        attachmentType: null,
        cpmProjectID: null,
        formNumber: null,
        fullLegal: null,
        numberOfStories: 0,
        numberOfUnits: 0,
        propertyInspectionWaiver: null,
        projectType: null,
        propertyType: null,
        projectId: null,
        projectName: null,
        propertyAuxInfo: null,
        propertyFormType: null,
        propertyReview: null,
        propertyRights: null,
        shortLegal: null,
        propertyProjectInfo: {
          propertyID: null,
          condoStatus: null,
          attachmentType: null,
          designType: null,
          projectTotalUnits: null,
          unitsSold: null,
        },
        addressTypeId: 1,
        cityName: '',
        counties: [],
        countyName: '',
        isLicensedStates: false,
        region: 0,
        stateId: 0,
        stateName: '',
        streetName: '',
        unitNumber: '',
        zipCode: '',
        borrowerId: null,
        isSameAsPropertyAddress: null,
        isSameAsPrimaryBorrowerCurrentAddress: null,
        isSameMailingAsBorrowerCurrentAddress: null,
        monthlyRent: 0,
        occupyStartDate: null,
        ownership: 0,
        previousAddressOrdinal: null,
        timeAtAddressYears: null,
        timeAtAddressMonths: null,
        isUSDAEligible: null,
        lotSizeInAcres: null,
        lotSizeLessThan1Acre: null,
        sizeOfHomeSqFeet: null,
      },
    ],
    active: false,
    emailAddressList: {
      id: null,
      list: [
        {
          isDeleted: false,
          isPreferred: true,
          parentId: null,
          recordId: null,
          value: {
            emailAddress: '',
          },
        },
      ],
    },
    parentId: null,
    recordId: null,
    phoneList: {
      id: null,
      list: [
        {
          parentId: null,
          recordId: null,
          value: {
            phoneNumber: '',
            isDeleted: false,
            phoneNumberType: 3,
          },
          isDeleted: false,
          isPreferred: true,
        },
        {
          parentId: null,
          recordId: null,
          value: {
            phoneNumber: '',
            isDeleted: false,
            phoneNumberType: 6,
          },
          isDeleted: false,
          isPreferred: true,
        },
      ],
    },
    isDeleted: false,
  },
  'loan.transactionInfo.liabilities': <ILiabilityViewModel>{
    accountNumber: null,
    accountOpenDate: null, // Date;
    accountOwnershipTypeIndicator: null,
    accountOwnershipTypeToolTipText: null,
    amount: null,
    borrowerDebtCommentId: null,
    borrowerId: null,
    calculatedMinPayment: null,
    calculatedMonthlyAmount: null,
    clientId: null,
    companyData: null,
    currentratingType: null,
    debtCommentId: null,
    debtsAccountOwnershipType: null,
    debtType: null,
    identityKey: null,
    includeInDTI: null,
    includeInLiabilitiesTotal: null,
    includeInTotalPayment: null,
    incomeDeduction: null,
    isJoint: null,
    isJointWithSingleBorrowerID: null,
    isLastUserModifiedRecord: null,
    isLenderSectionVisible: null,
    isPledged: null,
    isRemoved: null,
    isSecondaryPartyRecord: null,
    isSwitchedBetweenBorrowers: null,
    isUserEntry: null,
    liabilitiesAccountOwnershipType: null,
    liabilityDisabled: null,
    liabilityInfoId: null,
    liabilityInfoType: null,
    lienPosition: null,
    loanOriginationDate: null, // Date;
    maximumCreditLine: null,
    minPayment: null,
    monthsLeft: null,
    notMyLoan: null,
    originalClientId: null,
    originalLiabilityInfoId: null,
    originalMinAmount: null,
    originalUnpaidBalance: null,
    payee: null,
    payoffDisplayValue: null,
    payoffLender: null,
    pledgedAssetLoanType: null,
    priorAdverseRatings: null,
    property: null,
    propertyAddressDisplayValue: null,
    propertyId: null,
    reoInfo: null,
    selectedImpound: null,
    systemCalculatedMinPayment: null,
    totalPaymentDisplayValue: null,
    typeDisplayValue: null,
    typeId: null,
    unpaidBalance: null,
    userEntryNotSavedInDatabaseYet: null,
  },
  'loan.transactionInfo.borrowers.phones': <any>{
    cleanNumber: null,
    isPreferred: null,
    number: null,
    phoneId: null,
    preferredContactOrderType: 1,
    type: null,
  },
  'loan.transactionInfo.borrowers.borrowerDetail': <any>{
    borrowerId: null,
    creditAuthorizedBy: null,
    creditAuthorizedDate: null,
    creditAuthorizedMethod: null,
    creditAuthorizedSsn: null,
  },
  'loan.transactionInfo.borrowers.employments': <any>{
    address: {
      addressTypeId: null,
      appraisedValue: null,
      attachmentType: null,
      borrowerId: null,
      buyingStage: null,
      cityName: null,
      cltv: null,
      costOfImprovements: null,
      counties: [],
      countyName: null,
      cpmProjectID: null,
      currentEstimatedValue: null,
      currentMortgageBalance: null,
      describeImprovements: null,
      doYouPayMortgageInsurance: null,
      doYouPayMortgageInsuranceDescription: null,
      downPayment: null,
      downPaymentSource: null,
      downPaymentType: null,
      fips: null,
      firstMortgageAmount: null,
      firstMortgageHolder: null,
      formNumber: null,
      fullLegal: null,
      grossRentalIncome: null,
      hasAppraisedValueHistory: null,
      hcltv: null,
      householdMembersEx: [],
      improvementsCompleted: null,
      includeTaxes: null,
      isChildProperty: null,
      isLicensedStates: null,
      isRemoved: null,
      isSameAsPrimaryBorrowerCurrentAddress: null,
      isSameAsPropertyAddress: null,
      isSameMailingAsBorrowerCurrentAddress: null,
      isSubjectProperty: null,
      isUSDAEligible: null,
      loanApplicationId: null,
      loanId: null,
      lotSizeInAcres: null,
      lotSizeLessThan1Acre: null,
      ltv: null,
      monthlyRent: null,
      mortgagePaymentMethod: null,
      mortgagePaymentTimePeriod: null,
      needPreApproval: null,
      negativeCashFlowAmount: null,
      numberOfStories: null,
      numberOfUnits: null,
      occupancyType: null,
      occupyStartDate: null,
      ownership: null,
      ownershipPercentage: null,
      previousAddressOrdinal: null,
      projectId: null,
      projectName: null,
      projectType: null,
      propertyAuxInfo: null,
      propertyExpenses: [],
      propertyExpensesOther: null,
      propertyFormType: null,
      propertyId: null,
      propertyInspectionWaiver: null,
      propertyProjectInfo: null,
      propertyReview: null,
      propertyRights: null,
      propertyType: null,
      purchaseDate: null,
      purchasePrice: null,
      region: null,
      secondMortgageAmount: null,
      shortLegal: null,
      sizeOfHomeSqFeet: null,
      stateId: null,
      stateName: null,
      streetName: null,
      structure: null,
      timeAtAddressMonths: null,
      timeAtAddressYears: null,
      totalAmount: null,
      unitNumber: null,
      vacancyPercentage: null,
      yearAcquired: null,
      yearBuilt: null,
      zipCode: null,
    },
    borrowerId: null,
    branchOfService: null,
    businessPhone: null,
    employmentEndDate: null,
    employmentInfoId: null,
    employmentStartDate: null,
    employmentStatusId: null,
    employmentTypeId: null,
    isAdditional: null,
    isPresent: null,
    isRemoved: null,
    legalEntityType: null,
    name: null,
    positionDescription: null,
    totalMonthlyAmount: null,
    typeOfBusiness: null,
    yearsInThisProfession: null,
    yearsOnThisJob: null,
  },
  'loan.transactionInfo.borrowers.incomes': <IIncomeInfoViewModel>{
    canProvideDocumentation: null,
    description: null,
    hourPerWeek: null,
    incomeHistory: [],
    incomeTypeDisabled: null,
    isSubjectProperty: null,
    manualChange: null,
    originalAmount: null,
    payPerHour: null,
    propertyId: null,
    preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
    incomeInfoId: null,
    amount: 0,
    incomeTypeId: null,
    incomeType: null,
    employmentInfoId: null,
    isRemoved: null,
    borrowerId: null,
    calculatedMonthlyAmount: null,
    loanType: null,
  },
  'loan.transactionInfo.borrowers.incomeMatrix': [
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.BaseEmployment,
      incomeType: 'Base Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.Overtime,
      incomeType: 'Overtime Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.Bonuses,
      incomeType: 'Bonuses',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.Commissions,
      incomeType: 'Commissions',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryBasePay,
      incomeType: 'Military Base Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryClothesAllows,
      incomeType: 'Military Clothes Allows',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryCombatPay,
      incomeType: 'Military Combat Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryFlightPay,
      incomeType: 'Military Flight Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryHazardPay,
      incomeType: 'Military Hazard Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryOverseasPay,
      incomeType: 'Military Overseas Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryPropPay,
      incomeType: 'Military Prop Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryQuartersAllowance,
      incomeType: 'Military Quarters Allowance',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryRationsAllowance,
      incomeType: 'Military Rations Allowance',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.MilitaryVariableHousingAllowance,
      incomeType: 'Military Variable Housing Allowance',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
    <IIncomeInfoViewModel>{
      canProvideDocumentation: null,
      description: null,
      hourPerWeek: null,
      incomeHistory: null,
      incomeTypeDisabled: null,
      isSubjectProperty: null,
      manualChange: null,
      originalAmount: null,
      payPerHour: null,
      propertyId: null,
      preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
      incomeInfoId: null,
      amount: 0,
      incomeTypeId: IncomeTypeEnum.SelfEmployedIncome,
      incomeType: 'Base Pay',
      employmentInfoId: null,
      isRemoved: null,
      borrowerId: null,
      calculatedMonthlyAmount: null,
      loanType: null,
    },
  ],
  'loan.transactionInfo.borrowers.assets': <IAssetViewModel>{
    accountNumber: null,
    assetId: null,
    assetType: null,
    assetValue: null,
    automobileMake: null,
    automobileYear: null,
    description: null,
    financialInstitutionAddress: {
      addressTypeId: null,
      appraisedValue: null,
      attachmentType: null,
      borrowerId: null,
      buyingStage: null,
      cityName: null,
      cltv: null,
      costOfImprovements: null,
      counties: [],
      countyName: null,
      cpmProjectID: null,
      currentEstimatedValue: null,
      currentMortgageBalance: null,
      describeImprovements: null,
      doYouPayMortgageInsurance: null,
      doYouPayMortgageInsuranceDescription: null,
      downPayment: null,
      downPaymentSource: null,
      downPaymentType: null,
      fips: null,
      firstMortgageAmount: null,
      firstMortgageHolder: null,
      formNumber: null,
      fullLegal: null,
      grossRentalIncome: null,
      hasAppraisedValueHistory: null,
      hcltv: null,
      householdMembersEx: [],
      improvementsCompleted: null,
      includeTaxes: null,
      isChildProperty: null,
      isLicensedStates: null,
      isNonWarrantableCondo: null,
      isRemoved: null,
      isSameAsPrimaryBorrowerCurrentAddress: null,
      isSameAsPropertyAddress: null,
      isSameMailingAsBorrowerCurrentAddress: null,
      isSubjectProperty: null,
      isUSDAEligible: null,
      loanApplicationId: null,
      loanId: null,
      lotSizeInAcres: null,
      lotSizeLessThan1Acre: null,
      ltv: null,
      monthlyRent: null,
      mortgagePaymentMethod: null,
      mortgagePaymentTimePeriod: null,
      needPreApproval: null,
      negativeCashFlowAmount: null,
      numberOfStories: null,
      numberOfUnits: null,
      occupancyType: null,
      occupyStartDate: null,
      ownership: null,
      ownershipPercentage: null,
      previousAddressOrdinal: null,
      projectId: null,
      projectName: null,
      projectType: null,
      propertyAuxInfo: null,
      propertyExpenses: [],
      propertyExpensesOther: null,
      propertyFormType: null,
      propertyId: null,
      propertyInspectionWaiver: null,
      propertyProjectInfo: null,
      propertyReview: null,
      propertyRights: null,
      propertyType: null,
      pudIndicator: null,
      purchaseDate: null,
      purchasePrice: null,
      region: null,
      secondMortgageAmount: null,
      shortLegal: null,
      sizeOfHomeSqFeet: null,
      stateId: null,
      stateName: null,
      streetName: null,
      structure: null,
      timeAtAddressMonths: null,
      timeAtAddressYears: null,
      totalAmount: null,
      unitNumber: null,
      vacancyPercentage: null,
      yearAcquired: null,
      yearBuilt: null,
      zipCode: null,
    },
    financialInstitutionId: null,
    financialInstitutionName: null,
    giftOrGrantDeposited: null,
    imageUrl: null,
    institiutionContactInfo: {
      addressViewModel: null,
      assetId: null,
      attn: null,
      companyId: null,
      companyName: null,
      email: null,
      fax: null,
      liabillityFor: null,
      phone: null,
      streetAddress1: null,
      streetAddress2: null,
    },
    isDownPayment: null,
    isRemoved: null,
    jointAccount: null,
    lifeInsuranceFaceValue: null,
    liquidAsset: null,
    monthlyAmount: null,
    ownerId: null,
    relationshipType: null,
    unpaidBalance: null,
    varified: null,
    voaBalanceDate: null,
    voaWarningMessage: null,
  },
  'loan.transactionInfo.properties': <any>{
    loanId: null,
    loanApplicationId: null,
    propertyId: null,
    buyingStage: null,
    cltv: null,
    costOfImprovements: null,
    currentEstimatedValue: null,
    currentMortgageBalance: null,
    describeImprovements: null,
    downPayment: null,
    downPaymentSource: null,
    downPaymentType: null,
    doYouPayMortgageInsurance: null,
    doYouPayMortgageInsuranceDescription: null,
    fips: null,
    firstMortgageHolder: null,
    firstMortgageAmount: null,
    grossRentalIncome: null,
    hasAppraisedValueHistory: null,
    householdMembersEx: [],
    hcltv: null,
    improvementsCompleted: null,
    includeTaxes: null,
    isChildProperty: null,
    isNonWarrantableCondo: null,
    isRemoved: null,
    isSubjectProperty: null,
    ltv: null,
    mortgagePaymentMethod: null,
    mortgagePaymentTimePeriod: null,
    negativeCashFlowAmount: null,
    needPreApproval: null,
    occupancyType: null,
    ownershipPercentage: null,
    purchaseDate: null,
    purchasePrice: null,
    secondMortgageAmount: null,
    structure: null,
    totalAmount: null,
    vacancyPercentage: null,
    yearAcquired: null,
    yearBuilt: null,
    propertyExpenses: [],
    propertyExpensesOther: {
      propertyId: null,
      specialAssessment: null,
      maintenanceAndUtilities: null,
      includeUtilities: null,
      other: null,
      totalNewMonthlyHousingExpenses: null,
    },
    appraisedValue: null,
    attachmentType: null,
    cpmProjectID: null,
    formNumber: null,
    fullLegal: null,
    numberOfStories: null,
    numberOfUnits: null,
    propertyInspectionWaiver: null,
    projectType: null,
    propertyType: 2,
    projectId: null,
    projectName: null,
    propertyAuxInfo: {
      specialFloodHazardAreaIndicator: null,
      propertyEstateType: null,
      propertyFloodInsuranceIndicator: null,
      propertyUnitNo1Rent: null,
      propertyUnitNo1BedRooms: null,
      propertyUnitNo2Rent: null,
      propertyUnitNo2BedRooms: null,
      propertyUnitNo3Rent: null,
      propertyUnitNo3BedRooms: null,
      propertyUnitNo4Rent: null,
      propertyUnitNo4BedRooms: null,
      constructionMethodType: null,
      appraisalMethod: null,
      avmModelNameType: null,
    },
    propertyFormType: null,
    propertyReview: null,
    propertyRights: null,
    shortLegal: null,
    propertyProjectInfo: {
      propertyID: null,
      condoStatus: null,
      attachmentType: null,
      designType: null,
      projectTotalUnits: null,
      unitsSold: null,
    },
    addressTypeId: null,
    cityName: null,
    counties: [],
    countyName: null,
    isLicensedStates: null,
    region: null,
    stateId: null,
    stateName: null,
    streetName: null,
    unitNumber: null,
    zipCode: null,
    borrowerId: null,
    isSameAsPropertyAddress: null,
    isSameAsPrimaryBorrowerCurrentAddress: null,
    isSameMailingAsBorrowerCurrentAddress: null,
    monthlyRent: null,
    occupyStartDate: null,
    ownership: null,
    previousAddressOrdinal: null,
    timeAtAddressYears: null,
    timeAtAddressMonths: null,
    isUSDAEligible: null,
    lotSizeInAcres: null,
    lotSizeLessThan1Acre: null,
    sizeOfHomeSqFeet: null,
  },
  'loan.$$custom.loan.employments': <ICPOSEmployment>{
    cvIncomeTypeId: null,
    employmentInfoId: null,
    incomeInfoId: null,
    borrowerId: null,
    isForeign: false,
    isPrevious: false,
    isSeasonal: false,
    isSpecialBorrowerEmployerRelationship: false,
    isTwoYearsPeriodMissing: false,
    isTwoYearsPeriodFulfilled: false,
    preserveOnTwnFilter: false,
    isEVerified: false,
    employmentVerificationEmploymentId: null,
    notes: null,
    income: {
      incomeMatrix: [
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.BaseEmployment,
          incomeType: 'Base Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.Overtime,
          incomeType: 'Overtime Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.Bonuses,
          incomeType: 'Bonuses',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.Commissions,
          incomeType: 'Commissions',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryBasePay,
          incomeType: 'Military Base Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryClothesAllows,
          incomeType: 'Military Clothes Allows',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryCombatPay,
          incomeType: 'Military Combat Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryFlightPay,
          incomeType: 'Military Flight Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryHazardPay,
          incomeType: 'Military Hazard Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryOverseasPay,
          incomeType: 'Military Overseas Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryPropPay,
          incomeType: 'Military Prop Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryQuartersAllowance,
          incomeType: 'Military Quarters Allowance',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryRationsAllowance,
          incomeType: 'Military Rations Allowance',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.MilitaryVariableHousingAllowance,
          incomeType: 'Military Variable Housing Allowance',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
        <IIncomeInfoViewModel>{
          canProvideDocumentation: null,
          description: null,
          hourPerWeek: null,
          incomeHistory: null,
          incomeTypeDisabled: null,
          isSubjectProperty: null,
          manualChange: null,
          originalAmount: null,
          payPerHour: null,
          propertyId: null,
          preferredPaymentPeriodId: PeriodTypeEnum.Monthly,
          incomeInfoId: null,
          amount: 0,
          incomeTypeId: IncomeTypeEnum.SelfEmployedIncome,
          incomeType: 'Base Pay',
          employmentInfoId: null,
          isRemoved: null,
          borrowerId: null,
          calculatedMonthlyAmount: null,
          loanType: null,
        },
      ],
      basePaymentPeriodId: PeriodTypeEnum.Annually,
      baseHoursPerWeek: null,
      baseAmount: null,
      baseDescription: null,
      annualOvertime: null,
      annualBonus: null,
      annualCommission: null,
      monthlyFlightPay: null,
      monthlyPropPay: null,
      monthlyHousingAllowance: null,
      monthlySocialSecurityPay: null,
      retirementStartDate: null,
      retirementEndDate: null,
      monthlyPension: null,
      otherIncomeTypeId: null,
      otherIncomeAmount: null,
      otherPaymentPeriodId: PeriodTypeEnum.Monthly,
      otherPayPerHour: null,
      otherIncomeDescription: null,
      retirementIncomeOther: false,
      isLoss: false,
    },
    employerInfo: {
      name: null,
      address: null,
      unitNumber: null,
      city: null,
      state: null,
      zip: null,
      phone: null,
      legalEntityType: null,
      cloverLegalEntityType: null,
      cloverLegalEntityLLC: null,
    },
    positionInfo: {
      title: null,
      branch: null,
      rank: null,
      dateStart: null,
      dateEnd: null,
      yearsInPosition: null,
      monthsInPosition: null,
      OwnershipInterestType: 1,
      expirationOfService: null,
    },
  },
  'closingCost.costs': <any[]>[
    {
      costId: null,
      loanId: null,
      mortgageType: 0,
      uniqueCostTypeId: '1006',
      costContainer: 2,
      hudLineNumber: 1006,
      subHUDLineNumber: '',
      amountForYear: 0,
      amount: 0,
      amountPerMonth: 0,
      isAprCost: false,
      cocReasonStatus: 0,
      name: 'Flood Insurance',
      description: '',
      provider: null,
      percent: 0,
      isLocked: false,
      canBeLocked: true,
      interestRate: null,
      monthsToBePaid: 0,
      sectionId: 6,
      costSection: 'G',
      sectionName: 'G. INITIAL ESCROW PAYMENT AT CLOSING',
      templateId: 4,
      templateUrl: 'angular/costdetails/edittemplates/costEdit4.html',
      paidBy: '',
      paidTo: '',
      borrowerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 0,
        paidByDisplayValue: '',
      },
      otherPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 3,
        paidByDisplayValue: 'O',
      },
      sellerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 10,
        paidByDisplayValue: 'S',
      },
      canEdit: true,
      canDelete: true,
      isRemoved: false,
      closingDate: null,
      closingDateEnd: null,
      interestDays: null,
      userAdded: false,
      includeInTotal: 0,
      totalRow: '',
      costTypeGroupCategory: '',
      costType: 3,
      costTypeId: null,
      mustBeUnique: true,
      titleCostIncludedType: 0,
      titleCostIncludedTypeDescription: '',
      originalHUDLineNumber: 0,
      originalSubHUDLineNumber: '',
      toleranceLevel: 100,
      preferredPaymentPeriod: null,
      impounded: true,
      orderNo: 0,
      isUserCalculated: true,
      useLoanEstimateFee: null,
      loanEstimateFeeAmount: null,
      section32Fee: null,
      sellerObligated: null,
      bonaFide: null,
      serviceProvider: 0,
      loanTransactionType: 2,
      amountMethod: null,
      vaAllowableType: 0,
      originalMonthsToBePaid: null,
      encompassLineItemId: null,
      disclosureLineItemId: null,
      editMode: false,
      autoRecalculate: true,
      financedType: null,
      financedAmount: 0,
      feeCategory: 0,
      upfrontPreferredPaymentPeriod: null,
      sectionLE: null,
      isInvalidChange: null,
      isDateLocked: null,
      vendorId: null,
      tX50a6AllowableType: null,
      isInternalCost: null,
      monthsToBePaidLocked: null,
      providerId: null,
      additionalAmount: null,
      periodPaymentMonths: [],
      itemizedPropertyTaxes: [],
      costCalculationFactorsViewModel: {
        costId: null,
        factor1: 0,
        factor2: 0,
        factor1Locked: false,
        factor2Locked: false,
        factor1Months: 0,
        factor2Months: 0,
        terminationType: null,
        terminationTypeLocked: false,
      },
    },
    {
      costId: null,
      loanId: null,
      mortgageType: 0,
      uniqueCostTypeId: '1002',
      costContainer: 2,
      hudLineNumber: 1002,
      subHUDLineNumber: '',
      amountForYear: 0,
      amount: 0,
      amountPerMonth: 0,
      isAprCost: false,
      cocReasonStatus: 0,
      name: 'Homeowner\'s Insurance',
      description: '',
      provider: null,
      percent: 0,
      isLocked: false,
      canBeLocked: true,
      interestRate: null,
      monthsToBePaid: 0,
      sectionId: 6,
      costSection: 'G',
      sectionName: 'G. INITIAL ESCROW PAYMENT AT CLOSING',
      templateId: 4,
      templateUrl: 'angular/costdetails/edittemplates/costEdit4.html',
      paidBy: '',
      paidTo: '',
      borrowerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 0,
        paidByDisplayValue: '',
      },
      otherPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 3,
        paidByDisplayValue: 'O',
      },
      sellerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 10,
        paidByDisplayValue: 'S',
      },
      canEdit: true,
      canDelete: true,
      isRemoved: false,
      closingDate: null,
      closingDateEnd: null,
      interestDays: null,
      userAdded: false,
      includeInTotal: 0,
      totalRow: '',
      costTypeGroupCategory: '',
      costType: 3,
      costTypeId: null,
      mustBeUnique: true,
      titleCostIncludedType: 0,
      titleCostIncludedTypeDescription: '',
      originalHUDLineNumber: 0,
      originalSubHUDLineNumber: '',
      toleranceLevel: 100,
      preferredPaymentPeriod: null,
      impounded: true,
      orderNo: 0,
      isUserCalculated: true,
      useLoanEstimateFee: null,
      loanEstimateFeeAmount: null,
      section32Fee: null,
      sellerObligated: null,
      bonaFide: null,
      serviceProvider: 0,
      loanTransactionType: 2,
      amountMethod: null,
      vaAllowableType: 0,
      originalMonthsToBePaid: null,
      encompassLineItemId: null,
      disclosureLineItemId: null,
      editMode: false,
      autoRecalculate: true,
      financedType: null,
      financedAmount: 0,
      feeCategory: 0,
      upfrontPreferredPaymentPeriod: null,
      sectionLE: null,
      isInvalidChange: null,
      isDateLocked: null,
      vendorId: null,
      tX50a6AllowableType: null,
      isInternalCost: null,
      monthsToBePaidLocked: null,
      providerId: null,
      additionalAmount: null,
      periodPaymentMonths: [],
      itemizedPropertyTaxes: [],
      costCalculationFactorsViewModel: {
        costId: null,
        factor1: 0,
        factor2: 0,
        factor1Locked: false,
        factor2Locked: false,
        factor1Months: 0,
        factor2Months: 0,
        terminationType: null,
        terminationTypeLocked: false,
      },
    },
    {
      costId: null,
      loanId: null,
      mortgageType: 0,
      uniqueCostTypeId: '1004',
      costContainer: 2,
      hudLineNumber: 1004,
      subHUDLineNumber: '',
      amountForYear: 0,
      amount: 0,
      amountPerMonth: 0,
      isAprCost: false,
      cocReasonStatus: 0,
      name: 'Property Taxes',
      description: '',
      provider: null,
      percent: 0,
      isLocked: false,
      canBeLocked: true,
      interestRate: null,
      monthsToBePaid: 0,
      sectionId: 6,
      costSection: 'G',
      sectionName: 'G. INITIAL ESCROW PAYMENT AT CLOSING',
      templateId: 4,
      templateUrl: 'angular/costdetails/edittemplates/costEdit4.html',
      paidBy: '',
      paidTo: '',
      borrowerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 0,
        paidByDisplayValue: '',
      },
      otherPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 3,
        paidByDisplayValue: 'O',
      },
      sellerPaid: {
        id: null,
        atClosing: 0,
        beforeClosing: 0,
        paidBy: 10,
        paidByDisplayValue: 'S',
      },
      canEdit: true,
      canDelete: true,
      isRemoved: false,
      closingDate: null,
      closingDateEnd: null,
      interestDays: null,
      userAdded: false,
      includeInTotal: 0,
      totalRow: '',
      costTypeGroupCategory: '',
      costType: 3,
      costTypeId: null,
      mustBeUnique: true,
      titleCostIncludedType: 0,
      titleCostIncludedTypeDescription: '',
      originalHUDLineNumber: 0,
      originalSubHUDLineNumber: '',
      toleranceLevel: 100,
      preferredPaymentPeriod: null,
      impounded: true,
      orderNo: 0,
      isUserCalculated: true,
      useLoanEstimateFee: null,
      loanEstimateFeeAmount: null,
      section32Fee: null,
      sellerObligated: null,
      bonaFide: null,
      serviceProvider: 0,
      loanTransactionType: 2,
      amountMethod: null,
      vaAllowableType: 0,
      originalMonthsToBePaid: null,
      encompassLineItemId: null,
      disclosureLineItemId: null,
      editMode: false,
      autoRecalculate: true,
      financedType: null,
      financedAmount: 0,
      feeCategory: 0,
      upfrontPreferredPaymentPeriod: null,
      sectionLE: null,
      isInvalidChange: null,
      isDateLocked: null,
      vendorId: null,
      tX50a6AllowableType: null,
      isInternalCost: null,
      monthsToBePaidLocked: null,
      providerId: null,
      additionalAmount: null,
      periodPaymentMonths: [],
      itemizedPropertyTaxes: [],
      costCalculationFactorsViewModel: {
        costId: null,
        factor1: 0,
        factor2: 0,
        factor1Locked: false,
        factor2Locked: false,
        factor1Months: 0,
        factor2Months: 0,
        terminationType: null,
        terminationTypeLocked: false,
      },
    },
  ],
};
