// custom aliases
Inputmask.extendAliases({
  alpha: {
    mask: 'a{*}',
    placeholder: ''
  },
  phone: {
    mask: '[999-]999-9999'
  },
  zipcode: {
    mask: '99999[-9999]'
  }
});

// extension aliases
Inputmask.extendAliases({
  'date-iso': {
    alias: 'datetime',
    inputFormat: 'isoDate'
  },
  'date-year': {
    alias: 'datetime',
    inputFormat: 'yyyy'
  },
  'date-month': {
    alias: 'datetime',
    inputFormat: 'mm'
  },
  'date-day': {
    alias: 'datetime',
    inputFormat: 'dd'
  },
  'time-iso': {
    alias: 'datetime',
    inputFormat: 'isoTime'
  },
  'time-hours': {
    alias: 'datetime',
    inputFormat: 'hh'
  },
  'time-hours24': {
    alias: 'datetime',
    inputFormat: 'HH'
  },
  'time-minutes': {
    alias: 'datetime',
    inputFormat: 'MM'
  },
  'time-seconds': {
    alias: 'datetime',
    inputFormat: 'ss'
  },
  'datetime-iso': {
    alias: 'datetime',
    inputFormat: 'isoDateTime'
  },
  // numeric (builtin)
  // currency (builtin)
  // decimal (builtin)
  // integer (builtin)
  // percentage (builtin)
  'percentage-nosuffix': {
    alias: 'percentage',
    suffix: '',
    digits: 2,
    digitsOptional:true,
    max: 100,
    min: 0
  },
  // url (builtin)
  // email (builtin)
  // ssn (builtin)
});
