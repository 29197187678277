// For composition
import { assetsPages } from './pages/pages-assets';
import { creditPages } from './pages/pages-credit';
import { declarationsPages } from './pages/pages-declarations';
import { demographicPages } from './pages/pages-demographics';
import { incomePages } from './pages/pages-income';
import { loanPurposePages } from './pages/pages-loan-purpose';
import { personalPages } from './pages/pages-personal';
import { propertyPages } from './pages/pages-property';
import { reviewPages } from './pages/pages-review';
import { assetsRouting } from './routing/route-assets';
import { creditRouting } from './routing/route-credit';
import { declarationsRouting } from './routing/route-declarations';
import { demographicsRouting } from './routing/route-demographics';
import { incomeRouting } from './routing/route-income';
import { loanPurposeRouting } from './routing/route-loan-purpose';
import { personalRouting } from './routing/route-personal';
import { propertyRouting } from './routing/route-property';
import { reviewRouting } from './routing/route-review';

export const sections2020: CvFormBuilder.Section[] = [
  {
    title: 'Loan Purpose',
    sectionId: 'loan-purpose',
    routeStart: 'guidRouteA',
    hasSummary: true,
    routing: loanPurposeRouting,
    pages: loanPurposePages,
  },
  {
    title: 'Personal',
    sectionId: 'personal',
    routeStart: 'guidRouteA',
    hasSummary: true,
    routing: personalRouting,
    pages: personalPages,
  },
  {
    title: 'Property',
    sectionId: 'property',
    routeStart: 'guidRouteA',
    hasSummary: true,
    routing: propertyRouting,
    pages: propertyPages,
  },

  {
    title: 'Income',
    sectionId: 'income',
    routeStart: 'guidRouteA',
    hasSummary: true,
    routing: incomeRouting,
    pages: incomePages,
    mustCompletePrevious: true,
  },

  {
    title: 'Assets',
    sectionId: 'assets',
    routeStart: 'guidRouteA',
    hasSummary: true,
    routing: assetsRouting,
    pages: assetsPages,
  },

  {
    title: 'Declarations',
    sectionId: 'declarations',
    routeStart: 'guidRouteA',
    hasSummary: false,
    routing: declarationsRouting,
    pages: declarationsPages,
  },

  {
    title: 'Demographics',
    sectionId: 'demographics',
    routeStart: 'guidRouteA',
    hasSummary: false,
    routing: demographicsRouting,
    pages: demographicPages,
  },

  {
    title: 'Credit',
    sectionId: 'credit',
    routeStart: 'guidRouteA',
    hasSummary: false,
    routing: creditRouting,
    pages: creditPages,
  },

  {
    title: 'Submit',
    sectionId: 'review',
    routeStart: 'guidRouteA',
    hasSummary: false,
    routing: reviewRouting,
    pages: reviewPages,
    mustCompletePrevious: true,
  },
];
