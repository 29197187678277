<div class="container" automationid="Page_MyAccount">
<div class="row">
<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

<mat-card class="mb-3 px-md-5">

  <h1 class="mb-5 page-title text-center border-color-primary color-primary">My Account</h1>

  <ng-container *ngIf="isLoading; else showMyAccountForm">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
  </ng-container>

  <ng-template #showMyAccountForm>

  <form [formGroup]="formMain" novalidate (ngSubmit)="updateMyAccount(formMain)" automationid="Section_UpdateMyAccount">

    <h2 class="subtitle mb-5 text-center">Update my <strong>email</strong><span *ngIf="!isSSOUser"> or <strong>password</strong></span></h2>

    <div role="alert" class="icon mb-5 alert alert-danger" *ngIf="error">{{error}}</div>
    <div role="alert" class="icon mb-5 alert alert-info" *ngIf="info">{{info}}</div>
    <div role="alert" class="icon mb-5 alert alert-success" *ngIf="success">{{success}}</div>

    <div class="form-field-container">
      <mat-form-field
        [class.has-error]="formMain.get('userName').hasError('exactMatchNotAllowed')"
      >
        <input
          matInput
          debounceInput
          [debounceTime]="650"
          (debounceChange)="checkExistingUserName(formMain.value.userName)"
          placeholder="Email"
          type="text"
          id="userName"
          formControlName="userName"
          automationid="Email"
        >
        <mat-error>
          <ng-container *ngIf="formMain.get('userName').hasError('exactMatchNotAllowed'); else showDefaultEmailError">
            This email already exists. Please enter a different email address.
          </ng-container>
          <ng-template #showDefaultEmailError>
            Email is required
          </ng-template>
        </mat-error>
      </mat-form-field>
      <div class="form-field-icon">
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </div>

    </div>


    <div class="form-field-container" *ngIf="!isSSOUser">
      <mat-form-field>
        <input
          matInput
          placeholder="New password"
          [type]="showNewPassword ? 'text' : 'password'"
          id="newPassword"
          formControlName="password"
          (focus)="showPasswordRequirements = true"
          (blur)="showPasswordRequirements = false"
          automationid="NewPassword"
        >
        <mat-error>Valid password is required</mat-error>
      </mat-form-field>
      <button
        class="toggle-show-password"
        type="button"
        (click)="showNewPassword = !showNewPassword"
        [attr.aria-pressed]="!!showNewPassword"
        automationid="BtnShowPassword"
      >
        <i
          class="fa"
          [class.fa-eye]="!showNewPassword"
          [class.fa-eye-slash]="showNewPassword"
          aria-label="View and hide password toggle button"
        ></i>
      </button>
    </div>
    <app-password-requirements
      *ngIf="!isSSOUser"
      [password]="formMain.value.password"
      [isVisible]="showPasswordRequirements || (formMain.get('password').invalid && formMain.get('password').touched)"
    ></app-password-requirements>

    <div class="form-field-container" *ngIf="!isSSOUser">
      <mat-form-field
        [class.has-error]="formMain.hasError('notSamePassword')"
      >
        <input
          matInput
          placeholder="Confirm password"
          [type]="showConfirmPassword ? 'text' : 'password'"
          id="confirmPassword"
          formControlName="passwordConfirm"
          [errorStateMatcher]="passwordErrorMatcher"
          automationid="ConfirmPassword"
        >
        <mat-error>
          <ng-container *ngIf="formMain.hasError('notSamePassword'); else showDefaultConformPasswordError">
            Password does not match
          </ng-container>
          <ng-template #showDefaultConformPasswordError>
            Confirm password is required
          </ng-template>
        </mat-error>
      </mat-form-field>
      <button
        class="toggle-show-password"
        type="button"
        (click)="showConfirmPassword = !showConfirmPassword"
        [attr.aria-pressed]="!!showConfirmPassword"
        automationid="BtnShowConfirmPassword"
      >
        <i
          class="fa"
          [class.fa-eye]="!showConfirmPassword"
          [class.fa-eye-slash]="showConfirmPassword"
          aria-label="View and hide password toggle button"
        ></i>
      </button>
    </div>


    <div class="form-field-container" *ngIf="!isSSOUser">
      <mat-form-field>
        <input
          matInput
          placeholder="Current password"
          [type]="showCurrentPassword ? 'text' : 'password'"
          id="password"
          formControlName="passwordCurrent"
          automationid="CurrentPassword"
        >
        <mat-hint>Enter your current password in order to apply changes</mat-hint>
        <mat-error>Current password is required</mat-error>
      </mat-form-field>
      <button
        class="toggle-show-password"
        type="button"
        (click)="showCurrentPassword = !showCurrentPassword"
        [attr.aria-pressed]="!!showCurrentPassword"
        automationid="BtnShowCurrentPassword"
      >
        <i
          class="fa"
          [class.fa-eye]="!showCurrentPassword"
          [class.fa-eye-slash]="showCurrentPassword"
          aria-label="View and hide password toggle button"
        ></i>
      </button>
    </div>

    <div class="button-container text-center mt-4">
      <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="isWaiting"
        [ngClass]="{'btn-waiting': isWaiting}"
        automationid="BtnWaiting"
      >
        Save
      </button>
    </div>

  </form>

  </ng-template>
</mat-card>

</div>
</div>
</div>
