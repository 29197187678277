import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const personalRouting: CvFormBuilder.Routes = {
  // contact info
  guidRouteA: {
    pageGuid: 'guidPageA',
    routeNext: 'guidRouteB',
  },
  // contact-information
  guidRouteB: {
    pageGuid: 'guidPageB',
    routeNext: 'guidRouteC',
  },

  guidRouteC: {
    pageGuid: 'guidPageC', // is-military
    ruleGroups: [
      {
        routeNext: 'guidRouteE',
        rules: [
          {
            field: 'loan.$$custom.isMilitary',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      // No agent, go to summary
      {
        routeNext: 'guidRouteF',
        rules: [
          {
            field: 'loan.$$custom.isMilitary',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteE: {
    pageGuid: 'guidPageE', // military status
    routeNext: 'guidRouteF',
  },

  guidRouteF: {
    pageGuid: 'guidPageF', // currently-live
    // routeNext: 'guidRouteG'
    ruleGroups: [
      {
        routeNext: 'guidRouteF2',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteG',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteG',
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerPrimary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
    ],
  },

  guidRouteF2: {
    pageGuid: 'guidPageF2', // mailing-address
    routeNext: 'guidRouteG',
  },

  guidRouteG: {
    pageGuid: 'guidPageG', // rent-or-own
    routeNext: 'guidRouteH',
    // routing handled by Events.timeAtAddressPrimary
    // routeNext: 'guidRoutePrevAddressPrimary', < 2 years
  },

  guidRoutePrevAddressPrimary: {
    pageGuid: 'guidPageGB', // previous-address
    routeNext: 'guidRouteGC',
  },

  guidRouteGC: {
    pageGuid: 'guidPageGC', // prev rent-or-own
    routeNext: 'guidRouteH',
  },

  guidRouteH: {
    pageGuid: 'guidPageH', // marital-status
    routeNext: 'guidRouteI',
  },

  guidRouteI: {
    pageGuid: 'guidPageI', //dependents
    ruleGroups: [
      {
        routeNext: 'guidRouteRA',
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteJ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteJ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 1,
          },
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },

      {
        routeNext: 'guidRouteR',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.GT,
            value: 0,
          },
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.GT,
            value: 0,
          },
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.GT,
            value: 0,
          },
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteRA: {
    pageGuid: 'guidPageRA', // Alimony
    //routeNext: 'guidRouteJ',
    ruleGroups: [
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 2,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // Nonspouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 2,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteJ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 0,
          },
        ],
      },
      {
        routeNext: 'guidRouteJ',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.EQ,
            value: 1,
          },
        ],
      },

      {
        routeNext: 'guidRouteR',
        rules: [
          {
            field: 'loan.transactionInfo.borrowers[0].maritalStatus',
            operator: RuleExprOp.GT,
            value: 0,
          },
        ],
      },
    ],
  },

  guidRouteJ: {
    pageGuid: 'guidPageJ', // applying-with-spouse
    ruleGroups: [
      /**
      {
        routeNext: 'guidRouteError',
        rules: [
          {
            field: 'loan.$$custom.jointCredit', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },

 */
      {
        routeNext: 'guidRouteK',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteR',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteError: {
    pageGuid: 'guidPageError', // applying-with-spouse
  },

  guidRouteK: {
    pageGuid: 'guidPageB2', // contact-information-spouse
    ruleGroups: [
      {
        routeNext: 'guidRouteK1A',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteL',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteK1A: {
    pageGuid: 'guidPageB2A', // spouse account email
    ruleGroups: [
      {
        routeNext: 'guidRouteK1B',
        rules: [
          {
            field: 'config.config.area.coborroweraccount.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.spouseSecurityAnswerProvided',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.borrowerJointAccount',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteL',
        rules: [],
      },
    ],
  },

  guidRouteK1B: {
    pageGuid: 'guidPageB2B', // spouse security question
    routeNext: 'guidRouteL',
  },

  guidRouteL: {
    pageGuid: 'guidPageC2', // is-military-spouse
    // routeNext: 'guidRouteM'
    ruleGroups: [
      {
        routeNext: 'guidRouteM',
        rules: [
          {
            field: 'loan.$$custom.isMilitarySpouse', // isMilitarySpouse,
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      // No agent, go to summary
      {
        routeNext: 'guidRouteN',
        rules: [
          {
            field: 'loan.$$custom.isMilitarySpouse', // isMilitarySpouse,
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteM: {
    pageGuid: 'guidPageE2', // military-status-spouse
    routeNext: 'guidRouteN',
  },

  guidRouteN: {
    pageGuid: 'guidPageF3', // currently-live-spouse
    ruleGroups: [
      {
        routeNext: 'guidRouteQ', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteO', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },

      {
        routeNext: 'guidRouteO', // Mailing address spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
      {
        routeNext: 'guidRouteP', // rent-or-own-spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteP', // rent-or-own-spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isMailingAddressSameAsCurrent',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
    ],
  },

  guidRouteO: {
    pageGuid: 'guidPageF4', // mailing-address-spouse
    // routeNext: 'guidRouteP',
    ruleGroups: [
      {
        routeNext: 'guidRouteQ', // Dependents spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteP', // rent-or-own-spouse
        rules: [
          {
            field: 'loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower',
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  guidRouteP: {
    pageGuid: 'guidPageG2', // rent-or-own-spouse
    routeNext: 'guidRouteQ',
    // routing handled by Events.timeAtAddressSecondary
    // routeNext: 'guidRoutePrevAddressSecondary', < 2 years
  },

  guidRoutePrevAddressSecondary: {
    pageGuid: 'guidPageGB2', // previous address
    routeNext: 'guidRouteGC2',
  },

  guidRouteGC2: {
    pageGuid: 'guidPageGC2', // previous rent-or-own spouse
    routeNext: 'guidRouteQ',
  },

  guidRouteQ: {
    pageGuid: 'guidPageI2',
    ruleGroups: [
      {
        routeNext: 'guidRouteRAB',
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },

      {
        routeNext: 'guidRouteR', // anyone-else
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'config.config.area.alimony.child.support.enabled',
            operator: RuleExprOp.EQ,
            value: false,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteRAB: {
    pageGuid: 'guidPageRAB',
    // routeNext: 'guidRouteR',
    ruleGroups: [
      {
        routeNext: 'guidRouteR', // anyone-else
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.isChildAppOriginatedByBorrower',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteR: {
    pageGuid: 'guidPageK', // anyone-else
    ruleGroups: [
      {
        routeNext: 'guidRouteSA', // contact-information-additional-applicant
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.EQ,
            value: true,
          },
          {
            field: 'loan.transactionInfo.borrowers[0].borrowerDetail.applyingWithAnyoneElse',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.inviteAdditionalApplicantEnabled',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteSA: {
    pageGuid: 'guidPageKA', // contact-information-additional-applicant
    routeNext: 'guidRouteSB',
  },

  guidRouteSB: {
    pageGuid: 'guidPageKB', // email-additional-applicant
    ruleGroups: [
      {
        routeNext: 'guidRouteSC', // security-question-additional-applicant
        rules: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
            operator: RuleExprOp.NE,
            value: true,
          },
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.NE,
            value: true,
          },
        ]
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.loan.additionalApplicant.isOnlineUser',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ]
      },
      {
        routeNext: 'guidRouteZ', // summary
        rules: [
          {
            field: 'loan.$$custom.ssoUser',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ]
      }
    ]
  },

  guidRouteSC: {
    pageGuid: 'guidPageKC', // security-question-additional-applicant
    routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
