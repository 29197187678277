import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '$api';
import { Router } from '@angular/router';
import { ApplicationNavigationService } from 'src/app/shared/services/application-navigation.service';
import { ICPOSAppState } from 'src/app/shared/models';

@Component({
  selector: 'app-urla-change-modal',
  templateUrl: './urla-change-modal.component.html',
})
export class UrlaChangeModalComponent {
  public appState$ = this.api.appState$;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public dataAlt: any,
    private api: ApiService,
    private router: Router,
    private appNav: ApplicationNavigationService,
  ) {}

  /**
   * Submit the form
   */
  public submit(appState: ICPOSAppState) {
    // Reset 1003 state
    appState.form1003 = {
      ...appState.form1003,
      urlaModalShown: true,
      state: [],
    };
    // Save changes then close modal window
    this.api.appState.set(appState).subscribe(() => {
      this.appNav.setCurrentSection({ sectionId: 'loan-purpose', pageId: null });
      this.router.navigate(['/']); // Redirect to home
      this.dialogRef.close(this.dataAlt || this.data);
    });
  }
}
