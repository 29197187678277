<div id="modal" aria-modal="true" aria-describedby="modal-title" role="alertdialog" focusContain automationid="Page_EVerifyTerms">
  <div class="modal-header" automationid="Section_Header">
    <h2 class="modal-title" id="modal-title">TERMS OF SERVICE</h2>
    <button type="button" class="close" aria-label="Close" mat-dialog-close automationid="BtnClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="modal-body">
    <mat-checkbox [formControl]="formControl" [attr.automationid]="'FormFieldChk_agreedTermsOfService'">
      I have read and agree to the <a href="{{evoaTermsOfServiceUrl}}" target="_blank">Terms of Service</a> to verify assets securely using AccountChek &reg;.
    </mat-checkbox>
    <mat-error *ngIf="formControl.touched && formControl.errors && formControl.errors.required">
      <div class="small">To Securely Verify Assets Electronically, you must first agree to the Terms of Service.</div>
    </mat-error>
</div>

  <div class="modal-footer text-center" style="justify-content: center;" automationid="Section_Footer">
    <button class="btn btn-outline-secondary w-50" aria-label="Continue" (click)="submit()" (keydown.enter)="submit()" cdkFocusInitial>
      Continue
    </button>
  </div>
</div>
