import { Component } from '@angular/core';
import { LoanUtils } from 'src/app/shared/utils/loan-utils';
import { ApiService  } from '$api';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ILoanViewModel } from 'src/app/shared/models';

@Component({
  selector: 'app-confirmation-new-loan-modal',
  styleUrls: ['./confirmation-new-loan-modal.component.scss'],
  templateUrl: './confirmation-new-loan-modal.component.html',
})
export class ConfirmationNewLoanModalComponent {

  constructor(
    private api: ApiService,
    private dialogRef: MatDialogRef<any>
  ) {
  }

  isLoanWholesale$ = this.api.getApiStoreData(this.api.select.megaLoan$)
  .pipe(
    map((loan) => this.isWholesaleLoan(loan)),
  );

  isWholesaleLoan(loan: ILoanViewModel): boolean {
    const isWholesale =  LoanUtils.isWholesale(loan) ;
    console.log(`Is wholesale loan: ${isWholesale}`);
    return isWholesale;
  }

  get lenderName(): string {
    return this.isWholesaleLoan ? 'Broker' : 'Loan Officer';
  }
  
  submit = () => {
    this.dialogRef.close(true);
  }
}
