import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { environment } from '$env';
import { AppStore } from '$shared';
import { StringUtils } from '$utils';
import { AppSettings } from '../../app.settings';
import { UIStoreActions } from './ui.actions';
import { UiSelectorsService } from './ui.selectors.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormGroup } from '@angular/forms';
import { ICPOSAppConfig } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class UIStoreService {
  /** Holds the reference to a window opened programmatically. Used by appComms for multiscreen state */
  public screen: any; // Window
  /** Global reference to current 1003 state */
  public form1003: FormGroup;

  /** Obfuscate reference */
  private pad = 100;

  constructor(
    private store: Store<AppStore.Root>,
    /** UI Store Selectors */
    public select: UiSelectorsService,
    private settings: AppSettings,
  ) {
    // Rehydrate UI state from localStorage on instantiation
    if (this.settings.ui) {
      // Get UI state from localStorage
      let str = this.settings.ui;
      // Remove obfusucation if is set
      if (environment.settings.obfuscate) {
        // If de-obfuscating errors out, remove ui store state and fail gracefully
        str = this.obfuscateRemove(str);
      }
      // Convert to JSON
      const uiState: AppStore.Ui = JSON.parse(str);
      this.storeStateRestore(uiState);
    }

    // On UI store changes, persist to localstorage
    this.select.saveState$.subscribe((uiState: AppStore.Ui) => this.storeStateSave(uiState));

    // Output store changes to console
    // this.store.subscribe(storeApi => console.log(JSON.parse(JSON.stringify(storeApi.ui))));
  }

  /**
   * Change and persist the visible tab of a tabset
   * Make sure this service is public: constructor(public ui: UIStoreService) and that the first argument matches
   * USAGE
   <mat-tab-group [selectedIndex]="ui.select.tabActive$('home') | async" (selectedTabChange)="ui.tabChange('home', $event)">
   * @param tabInstanceId - A name or unique identifier for this tab instance
   * @param tabEvent - The tabChange event supplied by ng-boostrap
   */
  public tabChange(tabInstanceId: string, tabEvent: MatTabChangeEvent) {
    this.store.dispatch(UIStoreActions.TAB_CHANGE({ tabInstanceId: tabInstanceId, tabId: tabEvent.index }));
  }

  /**
   * Change a toggle which is just a boolean in a dictionary
   * @param prop - Property to set, corresponds to toggle$ in the ui.select service
   * @param value - T/F
   */
  public toggle(prop: string, value: boolean) {
    this.store.dispatch(UIStoreActions.TOGGLES({ prop: prop, value: value }));
  }

  /**
   *  Reload the latest UI state from localStorage
   */
  public storeStateRestore(uiState: AppStore.Ui) {
    this.store.dispatch(UIStoreActions.REHYDRATE(uiState));
  }

  /**
   * Change formbuilder state
   * @param state
   */
  public formBuilderChange(state: AppStore.FormBuilder) {
    this.store.dispatch(UIStoreActions.FORM_BUILDER_CHANGE(state));
  }

  /**
   * Change formbuilder state
   * @param state
   */
  public configChange(config: Record<string, ICPOSAppConfig>) {
    this.store.dispatch(UIStoreActions.CONFIG(config));
  }

  /**
   * Save the UI store state to localStorage for persistence
   * @param state
   */
  private storeStateSave(state: AppStore.Ui) {
    if (state) {
      const filteredState = this.filterState(state);
      let str = JSON.stringify(filteredState);
      // Add obfusciation if set
      if (environment.settings.obfuscate) {
        str = this.obfuscateAdd(str);
      }
      // Set to localStorage
      this.settings.ui = str;
    }
  }

  private filterState(state: AppStore.Ui) {
    // Delete any keys that should not be persisted
    const excludeKeys = environment.state.uiStoreBlacklist || [];
    return Object.assign({},
      ...Object.entries(state || {})
        .filter(([key]) => !excludeKeys.includes(key))
        .map(([key, value]) => [key, key === 'config'
          ? this.filterConfig(value)
          : value
        ])
        .map(([key, value]) => ({ [key]: value }))
    );
  }

  private filterConfig(config: Record<string, any>) {
    const excludeKeys = [
      'clover.config.default',
      'clover.config.URLA2009',
      'clover.config.URLA2020',
    ];
    return Object.assign({},
      ...Object.entries(config || {})
        .filter(([key]) => !excludeKeys.includes(key))
        .map(([key, value]) => ({ [key]: value }))
    );
  }

  /**
   * Add state obfuscation
   * @param str
   */
  public obfuscateAdd(str: string) {
    str = StringUtils.pad(str, this.pad, this.pad);
    str = StringUtils.obfuscateAdd(str);
    str = StringUtils.charShift(str, 10);
    return str;
  }

  /**
   * Remove state obfuscation
   * @param str
   */
  public obfuscateRemove(str: string) {
    try {
      str = StringUtils.charShift(str, -10);
      str = StringUtils.obfuscateRemove(str);
      str = StringUtils.trim(str, this.pad, this.pad);
    } catch (err) {
      console.error(err);
      this.settings.ui = null;
    }
    return str;
  }
}
