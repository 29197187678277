import { Injectable } from '@angular/core';
import { AuthService, AppSettings } from '$shared';
import { ApiService } from '$api';
import { ModalsService } from '$modals';
import { LoanLockGuard } from './loan-lock.guard';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class Form1003Guard extends LoanLockGuard {
  constructor(
    api: ApiService,
    modals: ModalsService,
    settings: AppSettings,
    private router: Router,
    private authService: AuthService,
  ) {
    super(api, modals, settings);
  }

  logout() {
    if (this.settings.secureLinkId !== null && this.settings.secureLinkId !== undefined) {
      this.authService.logOut();
    } else {
      this.router.navigate(['/my-loans']);
    }
  }
  getIsTaskUnavailable() {
    return false;
  }
  isHeaderCloseButtonVisible() {
    return false;
  }
}
