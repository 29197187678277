import { PhoneNumberPipe } from '$shared';
import { ControlType } from '../form-field.component';
import { CurrencyPipe } from '@angular/common';
const phoneNumberPipe = new PhoneNumberPipe();
const currencyPipe = new CurrencyPipe('en-US');

type Formatter = { [key in ControlType]?: Formats };

export interface Formats {
  input: (val: any, event?: KeyboardEvent, format?: string) => Format;
  output: (val: any) => Format;
}
export type Format = string | number | boolean;

/**
 * Formatters perform custom manipulation of data from the source form control to the custom control
 * This allows the app to present data differently to the user than what the model needs
 * TODO: Move input masking to directive instead of control formatter
 */
export const formatters: Formatter = {
  currency: {
    input: (amount: number, _event?: KeyboardEvent, format?: string) => {
      // Negative amounts should be allowed, but only if they are entered into field with format starting with N
      let negativeValuesAllowed = false;
      let isNegativeValue = false;
      if (format && format.startsWith('N')) {
        format = format.replace('N', ''); // 'N' is removed after evaluation to enable correct pipe transformation
        negativeValuesAllowed = true;
      }
      // Remember if the number was negative, because this info will be lost after regex is applied
      isNegativeValue = negativeValuesAllowed && amount && amount.toString().startsWith('-');
      if (isNegativeValue && amount.toString() === "-")
      {
        return amount;
      }

      if (amount) {
        // Get amount, convert to string, strip out everything except numbers
        const currencyAsNumber = amount.toString().replace(/[^0-9.]/g, '');
        if (isNaN(Number(currencyAsNumber))) return '0';
        let finalAmount;
        if (format && format !== '1.0-2' && currencyAsNumber.indexOf('.') !== -1) {
          const split = currencyAsNumber.split('.');
          const currency = currencyPipe.transform(split[0], 'USD', '', format).split('.')[0];
          let digits = split[1];
          if (digits.length > 2) {
            digits = digits.substring(0, 2);
          }
          finalAmount = currency + '.' + digits;
        } else {
          // Convert to currency with currency pipe
          finalAmount = currencyPipe.transform(currencyAsNumber, 'USD', '', '1.0-2');
        }
        return isNegativeValue ? '-' + finalAmount : finalAmount; // Apply negative if original value was negative
      }
      return amount;
    },
    output: (amount: number) => {
      if (amount || amount === 0) {
        // Get amount, convert to string, strip out everything except numbers
        return amount.toString().replace(/[^0-9.\-]/g, '');
      } else {
        return null;
      }
    },
  },
  phoneNumber: {
    input: (val: string, event?: KeyboardEvent) => {
      if (val) {
        const phoneAsNumber =
          event && event.target
            ? (<any>event).target.value
            : val
              .toString()
              .trim()
              .replace(/\D/g, '')
              .substring(0, 10);

        // Convert to formatted phone number
        let phoneNumberFormatted = phoneNumberPipe.transform(phoneAsNumber);
        // Allow for backspacing of the paranthesis which needs an extra tab
        if (event && event.key === 'Backspace' && phoneNumberFormatted.length === 5) {
          phoneNumberFormatted = phoneNumberFormatted.substring(0, 4);
        }
        // console.log(phoneNumberFormatted);
        return phoneNumberFormatted;
      } else {
        return null;
      }
    },
    output: (val: Format) => {
      if (val) {
        return val
          .toString()
          .trim()
          .replace(/\D/g, '')
          .substring(0, 10);
      }
      return val;
    },
  },
  ssn: {
    input: (val: any, event?: KeyboardEvent) => {
      // Remove all non numeric chars
      const num = val != null && val.replace ? val.replace(/[^0-9\.]+/g, '') : '';
      let strMasked = '';
      for (let i = 0; i < num.length; i++) {
        strMasked += num.charAt(i);
        if ([2, 4].includes(i)) {
          strMasked += '-';
        }
      }
      if (event && event.key === 'Backspace' && strMasked[strMasked.length - 1] === '-') {
        strMasked = strMasked.slice(0, -1);
      }
      return strMasked;
    },
    output: (val: any) => {
      const num = val != null && val.replace ? val.replace(/[^0-9\.]+/g, '') : '';
      return num;
    },
  },
  dateText: {
    input: (val: any, event?: KeyboardEvent) => {
      // Remove all characters except numbers and dashes
      const valSanitized = val != null && val.replace ? val.replace(/[^0-9\-]+/g, '') : '';
      const dateArray = valSanitized.split('-');
      const year = dateArray[0] || '';
      const month = dateArray[1] || '';
      const day = dateArray[2] || '';
      let strMasked = '';
      const num = `${month}${day}${year}`;
      for (let i = 0; i < num.length; i++) {
        strMasked += num.charAt(i);
        if ([1, 3].includes(i)) {
          strMasked += '/';
        }
      }
      if (event && event.key === 'Backspace' && strMasked[strMasked.length - 1] === '/') {
        strMasked = strMasked.slice(0, -1);
      }
      // Maximum characters is 8 plus the forward slashes
      strMasked = strMasked.slice(0, 10);
      return strMasked;
    },
    output: (val: any) => {
      // Maximum characters is 8 plus the forward slashes
      const formattedDate = val.slice ? val.slice(0, 10) : '';
      const dateArray = formattedDate.split('/');
      const month = dateArray[0] || '';
      const day = dateArray[1] || '';
      const year = dateArray[2] || '';
      const output = `${year}-${month}-${day}`;
      return output;
    },
  },
};
