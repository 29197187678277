import { PropertyTypeEnum, URLAFormTypeEnum } from 'src/app/shared/models';
import { mapPages, mapSectionPageContents } from '../form-builder.utils';

function applyCustomizations(sections: CvFormBuilder.Section[], urlaType: URLAFormTypeEnum): CvFormBuilder.Section[] {
  return mapPages(sections, (page, [sectionId, pageId]) => {
    try {
      switch (sectionId) {
        case 'property': return applyPropertyCustomizations(page, urlaType, pageId);
        default: return page;
      }
    } catch (err) {
      console.error('Error mapping client customizations', err);
      return page;
    }
  });
}

function applyPropertyCustomizations(page: CvFormBuilder.Page, urlaType: URLAFormTypeEnum, pageId: string): CvFormBuilder.Page {
  switch (pageId) {
    case 'occupy-or-use': return applyPropertyTypes(page, urlaType);
    default: return page;
  }
}

function applyPropertyTypes(occupyOrUsePage: CvFormBuilder.Page, urlaType: URLAFormTypeEnum): CvFormBuilder.Page {
  return mapSectionPageContents(occupyOrUsePage, content => {
    if (content.type === 'formField' && content.field === 'loan.$$custom.loan.addressSubject.propertyType') {
      const excludeValues = new Set([
        PropertyTypeEnum.TownHouse,
        PropertyTypeEnum.ManufacturedHousingSingleWide,
        PropertyTypeEnum.Modular,
      ]);
      return {
        ...content,
        formFieldData: content.formFieldData.filter((f: any) => !excludeValues.has(f.value)),
      };
    } else if (content.type === 'feature' && content.featureId === 'hint' && content.id === 'propertyType') {
      return {
        ...content,
        data: {
          ...content.data,
          body: getPropertyTypeTooltip(urlaType),
        }
      };
    }
    return content;
  });
}

function getPropertyTypeTooltip(urlaType: URLAFormTypeEnum) {
  switch (urlaType) {
    case URLAFormTypeEnum.URLA2009:
      return `
        <h3>Single-Family</h3>
        <p>Sometimes referred to as Single-Family Detached Homes, these are built on a single lot, and do not share a wall with another home.</p>
        <h3>Single-Family Home with HOA</h3>
        <p>With similar characteristics as a typical Single-Family Home, these homes are part of a Home Owners Association (HOA).</p>
        <h3>Condo</h3>
        <p>Condominiums are single, individually owned units within a larger building sharing walls with other units.</p>
        <h3>Co-op</h3>
        <p>Cooperatives (Co-ops) are similar in some ways to condominiums. However, with Co-ops each unit is NOT individually owned. Instead, homeowners own the entire building together.</p>
        <h3>Two-to-Four Unit</h3>
        <p>Also known as Multi-Family Home, this is a building or structure that is designed to house several different families in separate housing units, and consists of 2 or more units. All units are purchased together.</p>
        <h3>Manufactured/Double-wide</h3>
        <p>Manufactured homes are built on steel beam frames with axles and tires attached underneath. The house is generally double the size of a single-wide and may come in 2 halves for separate delivery.</p>
      `;
    case URLAFormTypeEnum.URLA2020:
    default:
      return `
        <h3>Single-Family</h3>
        <p>Sometimes referred to as Single-Family Detached Homes, these are built on a single lot, and do not share a wall with another home.</p>
        <h3>Condo</h3>
        <p>Condominiums are single, individually owned units within a larger building sharing walls with other units.</p>
        <h3>Co-op</h3>
        <p>Cooperatives (Co-ops) are similar in some ways to condominiums. However, with Co-ops each unit is NOT individually owned. Instead, homeowners own the entire building together.</p>
        <h3>2-4 Unit</h3>
        <p>Also known as Multi-Family Home, this is a building or structure that is designed to house several different families in separate housing units, and consists of 2 or more units. All units are purchased together.</p>
        <h3>Manufactured/Double-wide</h3>
        <p>Manufactured homes are built on steel beam frames with axles and tires attached underneath. The house is generally double the size of a single-wide and may come in 2 halves for separate delivery.</p>
      `;
  }
}

export default applyCustomizations;
