<app-header-simple></app-header-simple>

<main class="container" *ngIf="(config$ | async) as config">
  <div class="row">
    <div class="col col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body py-5 px-md-5">
          <div class="row">
            <div class="col-md-6">
              <div class="text-center mb-4">
                <h1 *ngIf="config && config['clover.account.verify.page.title'];else mfaTitle" [innerHtml]="config['clover.account.verify.page.title'].value"></h1>
                <ng-template #mfaTitle>Let's verify your account</ng-template>
              </div>
              <div class="image-container">
                <img *ngIf="config && config['clover.account.verify.page.image'];else mfaImage" [src]="config['clover.account.verify.page.image'].value" alt=""/>
                <ng-template #mfaImage><img src="/assets/img/register.png" alt=""/></ng-template>
            </div>
            </div>
            <div class="col-md-6">
              <form
                [formGroup]="formMain"
                novalidate
                (ngSubmit)="onSubmit(formMain)"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div class="form-heading mb-2">
                  <h1>Verification</h1>
                </div>

                <div role="alert" class="alert alert-danger icon mb-4" *ngIf="error">
                  {{error}}
                </div>

                <div role="alert" class="alert alert-info icon mb-4" *ngIf="info">
                  {{info}}
                </div>

                <p class="mb-4">To ensure the security of your account, an email with a verification code has just been sent to you. Please enter the verification code in the field below.</p>

                <mat-form-field class="mb-5">
                  <input
                    matInput
                    placeholder="Input Verification Code"
                    type="text"
                    id="code"
                    formControlName="mfaCode"
                    required
                  >
                  <mat-hint>Note: It may take a few moments before you receive this email and you may need to check your spam folder for this email message.</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="formMain.get('mfaCode').hasError('incorrect'); else showDefaultError">
                      Please enter the correct verification code
                    </ng-container>
                    <ng-template #showDefaultError>
                      Verification Code is required
                    </ng-template>
                  </mat-error>
                </mat-form-field>
                <div class="mb-3">
                  <button
                    mat-flat-button
                    color="accent"
                    class="sign-in"
                    type="submit"
                    [disabled]="waiting || isAccountLocked"
                    [ngClass]="{ 'btn-waiting': waiting }"
                    aria-live="assertive"
                  >
                    <ng-container *ngIf="!waiting; else showLoading">
                      Verify
                    </ng-container>
                    <ng-template #showLoading>
                      <i class="fa fa-spinner fa-spin"></i>
                      Loading, please wait
                    </ng-template>
                  </button>
                </div>
                <div>
                  <button
                    mat-flat-button
                    class="p-0"
                    type="button"
                    [disabled]="isAccountLocked"
                    (click)="sendNewCode()"
                  >Send new verification code</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
