<div class="modal-header" automationid="Section_Header">
  <h3 class="modal-title"><i class="fa fa-question-circle-o" aria-hidden="true"></i> Confirmation</h3>
  <button type="button" class="close" aria-label="Close" mat-dialog-close tabindex="1" automationid="BtnClose">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" id="modal-body" automationid="Section_Body">
  <p class="mb-0">{{ data }} </p>
</div>

<div class="modal-footer" style="justify-content: space-between;" automationid="Section_Footer">
  <button type="button" mat-button class="float-start pull-left dismiss" mat-dialog-close tabindex="1" automationid="BtnCancel">
    <i class="fa fa-remove" aria-hidden="true"></i> Cancel
  </button>
  <button type="button" mat-button class="mat-reverse" (click)="submit()" tabindex="1" aria-label="Close" automationid="BtnConfirm">
    <i class="fa fa-check" aria-hidden="true"></i> Confirm
  </button>
</div>
