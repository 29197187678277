import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '$env';

@Injectable({
  providedIn: 'root',
})
export class DeveloperGuard implements CanActivate {
  constructor() {}

  canActivate() {
    // prevent access if production enabled
    if (environment.production) {
      return false;
    }
    return true;
  }
}
