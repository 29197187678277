import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICPOSModalData } from 'src/app/shared/models';

@Component({
  selector: 'confirmation-next-modal',
  styleUrls: ['./confirmation-next.component.scss'],
  templateUrl: './confirmation-next.component.html'
})

export class ConfirmationNextModelComponent {
  constructor( public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: ICPOSModalData) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onExit(): void {
    this.dialogRef.close();
  }
}
