import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardNavComponent implements OnInit {
  /** Should the application list be shown in the UI */
  @Input() showExpandArrow = false;
  /** Should the application list be shown in the UI */
  @Input() showList = true;
  /** Should the progress indicator be shown in the UI */
  @Input() showSectionIcon = true;
  /** Is section nav open */
  @Input() isSectionNavOpen = false;
  /** Used in UI to determine which icon to show */
  routeCurrent$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(true),
    map(() => {
      const url = this.router.url;
      if (url.includes('/documents')) {
        return 'secure-docs';
      } else if (url.includes('/loan-details')) {
        return 'loan-details';
      }
      return 'tasks';
    }),
  );

  constructor(private router: Router) {}

  ngOnInit() {}
}
