import { Directive, AfterViewInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appNoPaste]',
})
export class NoPasteDirective implements AfterViewInit, OnDestroy {

  private cleanup: () => void;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngAfterViewInit() {
    this.cleanup = this.renderer.listen(this.element.nativeElement, 'paste',
      (e: any) => e.preventDefault()
    );
  }

  ngOnDestroy() {
    this.cleanup();
  }

}
