import { Routes } from '@angular/router';
import { DashboardGuard } from '$shared';
import { DummyComponent } from '$routes';

export const ROUTES_REDIRECTS: Routes = [
  // Forward user to correct route
  {
    path: 'dashboard',
    canActivate: [DashboardGuard], // Used to redirect while preserving query params
    component: DummyComponent, // This component isn't actually used. DashboardGuard takes over
    pathMatch: 'full'
  },
  {
    path: 'loan-purpose',
    canActivate: [DashboardGuard], // Used to redirect while preserving query params
    component: DummyComponent, // This component isn't actually used. DashboardGuard takes over
    pathMatch: 'full'
  },
  {
    path: 'pricing',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'summary-review',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-personal-info',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-personal-info',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'property-info',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-address-info',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-address-info',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-employment',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-employment',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-prev-employment',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-prev-employment',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'other-income',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'assets',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-government-monitoring',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-government-monitoring',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'borrower-gov-monitoring',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'co-borrower-gov-monitoring',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'declarations',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'summary',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'additional-borrower',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'account',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'credit-results',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'waiting-for-credit',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'success',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'credit',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'instructions',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'esigning',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'appraisal-viewer',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'appraisal',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'document-upload',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'e-consent-settings',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'my-next-step-loans',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'payment-calculator',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'affordability-calculator',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'forgotpassword',
    redirectTo: '/forgot-password',
    pathMatch: 'full'
  },
];
