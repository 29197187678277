const sortBy = require('lodash/sortBy');
import {
  IBorrowerDocumentViewModel,
  DocumentAcceptanceStatusEnum,
  IRequestedDocumentViewModel,
  BorrowerNeedStatusEnum,
  BorrowerContextTypeEnum,
  FileUploadedByEnum,
  DocumentStatusEnum,
  IList,
  ILoanViewModel
 } from 'src/app/shared/models';
import { LoanUtils } from 'src/app/shared/utils/loan-utils';

export class DocumentsUtils {
  static eDiscloseDocumentIds = ["0109"];
  static eEsignOnlyDocumentIds = ["9401", "9402"];
  static eSignOnlyWetSignatureDocumentMatrixId = "9402";
  /**
   * Find all documents requiring signing based on a number of criteria
   * @param documents
   */
  static getDocumentsToSign(documents: IBorrowerDocumentViewModel[]): IBorrowerDocumentViewModel[] {
    const documentsRequiringSigning = documents.filter(document => DocumentsUtils.doesDocumentRequireSigning(document));
    return documentsRequiringSigning.filter((document) => {
      return DocumentsUtils.doesBorrowerHaveDocumentsToEsign(document)
      || DocumentsUtils.doesCoBorrowerHaveDocumentsToEsign(document)
      || DocumentsUtils.doesBorrowerOrCoBorrowerHaveDocumentsToEsign(document);
    });
  }

  /**
   * Find all edisclose documents
   * @param documents
   */
  static getDocumentsToEDisclose(documents: IBorrowerDocumentViewModel[]): IBorrowerDocumentViewModel[]{
    const eDiscloseDocuments = documents.filter(document => DocumentsUtils.isDocumentIneDiscloseList(document.documentMatrixId));
    return eDiscloseDocuments;
  }

  static getESignOnlyDocuments(documents: IBorrowerDocumentViewModel[]): IBorrowerDocumentViewModel[]{
    const eDiscloseDocuments = documents.filter(document => this.eEsignOnlyDocumentIds.filter(d => d == document.documentMatrixId).length > 0);
    return eDiscloseDocuments;
  }

  /**
   * check document matrix is in edisclose id list
   * @param documents
   */
  static isDocumentIneDiscloseList(documentMatrixId: string){
    return this.eDiscloseDocumentIds.filter(d => d == documentMatrixId).length > 0;
  }

  /**
   * Find all Order Appraisal documents
   * @param documents
   */
  static getOrderAppraisals(documents: IBorrowerDocumentViewModel[]): IBorrowerDocumentViewModel[] {
    return documents.filter(document => DocumentsUtils.isOrderAppraisalsDocument(document) && !DocumentsUtils.isDocumentIneDiscloseList(document.documentMatrixId));
  }

  /**
   * Determine if a document needs signed
   * @param document
   */
  static doesDocumentRequireSigning(document: IBorrowerDocumentViewModel): boolean {
    return document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.SigPending
      || document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.SigPartial
      || document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.SigRequired;
  }

    /**
   * Determine if a document require print and sign
   * @param document
   */
     static doesEsignOnlyDocumentRequirePrintAndSign(loan: ILoanViewModel, document: IBorrowerDocumentViewModel, allDocuments: IList<IBorrowerDocumentViewModel>): boolean {
      if (document.documentStatus != DocumentStatusEnum.Active || document.documentMatrixId != this.eSignOnlyWetSignatureDocumentMatrixId) {
        return false;
      }

      if (LoanUtils.getCoBorrower(loan)){
        let relatedEsignOnlyDocument = allDocuments.find(x => x.vendorOrderId != null && x.vendorOrderId == document.vendorOrderId &&
          x.vendorDocumentPackageType && x.vendorDocumentPackageType == document.vendorDocumentPackageType &&
          x.documentMatrixId == this.eSignOnlyWetSignatureDocumentMatrixId && x.documentId != document.documentId);
        return relatedEsignOnlyDocument && relatedEsignOnlyDocument.documentStatus == DocumentStatusEnum.Active &&
              allDocuments.findIndex(x => x.documentId == relatedEsignOnlyDocument.documentId) < allDocuments.findIndex(x => x.documentId == document.documentId);
      }

      return true;
    }

  /**
   * Determine if a document needs signed by the borrower
   * @param document
   */
  static doesBorrowerHaveDocumentsToEsign(document: IBorrowerDocumentViewModel): boolean {
    return !document?.borrowerAcceptId;
  }

  /**
   * Determine if a document needs signed by the co-borrower
   * @param document
   */
  static doesCoBorrowerHaveDocumentsToEsign(document: IBorrowerDocumentViewModel): boolean {
    return !document?.coBorrowerAcceptId;
  }

  /**
   * Determine if a document needs signed by the borrower or the co-borrower
   * @param document
   */
  static doesBorrowerOrCoBorrowerHaveDocumentsToEsign(document: IBorrowerDocumentViewModel): boolean {
    return !document?.borrowerAcceptId || !document?.coBorrowerAcceptId;
  }

  /**
   * Used to filter Order Appraisals documents
   * @param document
   */
  static isOrderAppraisalsDocument(document: IBorrowerDocumentViewModel): boolean {
    return document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.ViewPending
      || document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.ViewRequired
      || document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.Viewed;
  }

  /**
   * Used to determine of document has been marked as viewed
   * @param document
   */
  static isDocumentViewed(document: IBorrowerDocumentViewModel): boolean {
    return document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.Viewed;
  }

  /**
   * Remove bnl items that match a provided status ID
   * @param bnl Borrower Needs List
   * @param statusId Status ID to remove
   */
  static filterOutByStatusId(bnl: IRequestedDocumentViewModel[], statusId: BorrowerNeedStatusEnum) {
    return bnl.filter((task: IRequestedDocumentViewModel) => {
      return task.borrowerNeedStatusId !== statusId;
    });
  }

  /**
   * Need to sort by borrower need status ID
   * @param bnl Borrower Needs List
   */
  static sortByStatusId(bnl: IRequestedDocumentViewModel[]): IRequestedDocumentViewModel[] {
    // Need to sort by borrower need status ID
    return sortBy(bnl, (task: IRequestedDocumentViewModel) => {
      // Custom sort order
      switch (task.borrowerNeedStatusId) {
        case BorrowerNeedStatusEnum.Incomplete:
          return 1;
        case BorrowerNeedStatusEnum.Needed:
          return 2;
        case BorrowerNeedStatusEnum.Review:
          return 3;
        default:
          return 4;
      }
    });
  }

  /**
   * We need this in order to enable cookie-less download - we are passing the
   * token as a field in the form and use it on server to validate the user
   * @param url
   */
  static downloadEx(url: string, authToken: string): void {
    if (!url) return;
    const newWindow = window.open();

    newWindow.document.open();
    newWindow.document.write(`
      <html><head><title>Downloader</title></head>
      <body>
      <form method="post" action="${url}" id="submitform">
      <input type="hidden" name="token" value="${authToken}">
      </form>
      <script type="text/javascript" >
      console.info("TODO: Invoking file download ...");
      document.getElementById("submitform").submit();
      function closebytimer() {window.close();}
      </script></body></html>
    `);

    newWindow.document.close();
  }

  static areThereDocumentsToSign(document: IBorrowerDocumentViewModel, loggedInUser: BorrowerContextTypeEnum): boolean {
    switch (loggedInUser) {
      case BorrowerContextTypeEnum.Both:
        return DocumentsUtils.doesBorrowerOrCoBorrowerHaveDocumentsToEsign(document);
      case BorrowerContextTypeEnum.Borrower:
        return DocumentsUtils.doesBorrowerHaveDocumentsToEsign(document);
      case BorrowerContextTypeEnum.CoBorrower:
        return DocumentsUtils.doesCoBorrowerHaveDocumentsToEsign(document);
      default:
        throw 'Invalid logged in user ' + loggedInUser;
    }
  }

  static areThereDocumentsToReview(document: IBorrowerDocumentViewModel, loggedInUser: BorrowerContextTypeEnum): boolean {
    switch (loggedInUser) {
      case BorrowerContextTypeEnum.Both:
        return DocumentsUtils.doesBorrowerOrCoBorrowerHaveDocumentsToEsign(document);
      case BorrowerContextTypeEnum.Borrower:
        return DocumentsUtils.doesBorrowerHaveDocumentsToEsign(document);
      case BorrowerContextTypeEnum.CoBorrower:
        return DocumentsUtils.doesCoBorrowerHaveDocumentsToEsign(document);
      default:
        throw 'Invalid logged in user ' + loggedInUser;
    }
  }

  static isABorrowerProvidedDoc(document: IBorrowerDocumentViewModel): boolean {
    return document.uploadedById === FileUploadedByEnum.Consumer;
  }

  static isALenderProvidedDoc(document: IBorrowerDocumentViewModel): boolean {
    return document.uploadedById === FileUploadedByEnum.Concierge;
  }

  static isASystemProvidedDoc(document: IBorrowerDocumentViewModel): boolean {
    return document.uploadedById === FileUploadedByEnum.System;
  }

  static isApprovedDocAcceptanceStatus(document: IBorrowerDocumentViewModel): boolean {
    return document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.NotRequired ||
      document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.Viewed ||
      document.documentAcceptanceStatusId === DocumentAcceptanceStatusEnum.Signed;
  }

}
