import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppSettings } from 'src/app/shared/app.settings';

@UntilDestroy()
@Component({
  selector: 'app-everify-terms',
  templateUrl: './everify-terms.component.html',
  styleUrls: ['./everify-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EVerifyTermsModelComponent implements OnInit {
  evoaTermsOfServiceUrl: string = "";
  formControl: FormControl = new FormControl(null, [Validators.required]);

  constructor(public dialogRef: MatDialogRef<any>, private ref: ChangeDetectorRef, private settings: AppSettings) {
    this.evoaTermsOfServiceUrl = this.settings.config['Legal.eVOA.TermsOfService.URL.CS']?.value;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
      if (val === null) {
        return;
      }
      // If checkbox value is true, remove errors, otherwise set errors
      if (val === true) {
        this.formControl.setErrors(null);
      } else {
        this.formControl.setErrors({ required: true });
      }
      // Set touched and fire change detection
      this.formControl.markAsTouched();
      this.ref.markForCheck();
    });

  }

  submit() {
    this.formControl.markAsTouched();
    if (this.formControl.valid) {
      this.dialogRef.close(true);
    }
  }
}
