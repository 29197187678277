import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSPageValidatorsEnum, BankruptcyTypeEnum, SelectOneYesNoEnum } from 'src/app/shared/models';

export const declarationsPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Declarations Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Declarations',
          sectionTitle: `Next up, a little more about your financial history`,
          sectionImage: `/assets/img/application/declarations.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Declarations',
    titleShow: false,
    pageId: 'declarations',
    validatorId: CPOSPageValidatorsEnum.declarations,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do we need this information?`,
          body: `
            <p>These questions are part of the standard loan application (Uniform Residential Loan Application) that all lenders have adopted.</p>
          `,
        },
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in ANOTHER property in the last 3 years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.$$custom.subjectPropertyOccupancyType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'FHA Secondary Residence',
                value: 3,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Yourself',
                value: 0,
              },
              {
                label: 'Jointly with your Spouse',
                value: 1,
              },
              {
                label: 'Jointly with Another Person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Do you have a family relationship or business affiliation with the seller of the property?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.specialBorrowerSellerRelationship',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.loanPurposeType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is the amount of this money?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentBorrowedAmount',
            formFieldType: 'currency',
            prefix: '$',
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.additionalMortgageApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.additionalCreditApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyProposedCleanEnergyLien',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you currently delinquent or in default on a Federal debt?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you party to a lawsuit in which you potentially have any personal financial liability?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you conveyed title to any property in lieu of foreclosure in the past 7 years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyDeedInLieu',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: SelectOneYesNoEnum.Yes,
              },
              {
                label: 'No',
                value: SelectOneYesNoEnum.No,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyShortSale',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you had property foreclosed upon in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you declared bankruptcy within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Identify the types of bankruptcy',
            // field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankruptcyType',
            field: 'loan.$$custom.bankrupcyType',
            formFieldType: 'select',
            multiple: true,
            formFieldData: [
              {
                label: 'Chapter 7',
                value: BankruptcyTypeEnum.ChapterSeven,
              },
              {
                label: 'Chapter 11',
                value: BankruptcyTypeEnum.ChapterEleven,
              },
              {
                label: 'Chapter 12',
                value: BankruptcyTypeEnum.ChapterTwelve,
              },
              {
                label: 'Chapter 13',
                value: BankruptcyTypeEnum.ChapterThirteen,
              },
            ],
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is your citizenship status?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'U.S. Citizen',
                value: 0,
              },
              {
                label: 'Permanent Resident Alien',
                value: 1,
              },
              {
                label: 'Non-Permanent Resident Alien',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
          }, //
        ],
      },
    ],
  }, // end page

  guidPageBPrimary: {
    // title: 'Summary',
    pageId: 'declarations-primary',
    validatorId: CPOSPageValidatorsEnum.declarations,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in ANOTHER property in the last 3 years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.$$custom.subjectPropertyOccupancyType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'FHA Secondary Residence',
                value: 3,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Yourself',
                value: 0,
              },
              {
                label: 'Jointly with your Spouse',
                value: 1,
              },
              {
                label: 'Jointly with Another Person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Do you have a family relationship or business affiliation with the seller of the property?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.specialBorrowerSellerRelationship',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.loanPurposeType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is the amount of this money?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentBorrowedAmount',
            formFieldType: 'currency',
            prefix: '$',
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.downPaymentIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.additionalMortgageApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.additionalCreditApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyProposedCleanEnergyLien',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you currently delinquent or in default on a Federal debt?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you party to a lawsuit in which you potentially have any personal financial liability?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you conveyed title to any property in lieu of foreclosure in the past 7 years?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyDeedInLieu',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: SelectOneYesNoEnum.Yes,
              },
              {
                label: 'No',
                value: SelectOneYesNoEnum.No,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.priorPropertyShortSale',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you had property foreclosed upon in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you declared bankruptcy within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Identify the types of bankruptcy',
            // field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankruptcyType',
            field: 'loan.$$custom.bankrupcyType',
            formFieldType: 'select',
            multiple: true,
            formFieldData: [
              {
                label: 'Chapter 7',
                value: BankruptcyTypeEnum.ChapterSeven,
              },
              {
                label: 'Chapter 11',
                value: BankruptcyTypeEnum.ChapterEleven,
              },
              {
                label: 'Chapter 12',
                value: BankruptcyTypeEnum.ChapterTwelve,
              },
              {
                label: 'Chapter 13',
                value: BankruptcyTypeEnum.ChapterThirteen,
              },
            ],
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[0].declarationsInfo.bankrupcyIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is your citizenship status?`,
            field: 'loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'U.S. Citizen',
                value: 0,
              },
              {
                label: 'Permanent Resident Alien',
                value: 1,
              },
              {
                label: 'Non-Permanent Resident Alien',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
          }, //
        ],
      },
    ],
  }, // end page

  guidPageBSecondary: {
    // title: 'Summary',
    pageId: 'declarations-secondary',
    validatorId: CPOSPageValidatorsEnum.declarations,
    isLastPage: true,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center page-title">{{loan.transactionInfo.borrowers[1].firstName}} {{loan.transactionInfo.borrowers[1].lastName}}</h2>',
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'delimited',
        data: {
          delimiter: `<hr>`,
        },
        content: [
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you had an ownership interest in ANOTHER property in the last 3 years?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.$$custom.subjectPropertyOccupancyType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What type of property did you own?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.typeOfProperty',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Primary Residence',
                value: 0,
              },
              {
                label: 'FHA Secondary Residence',
                value: 3,
              },
              {
                label: 'Second Home',
                value: 1,
              },
              {
                label: 'Investment Property',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `How did you hold title to the home?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.priorPropertyTitleType',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'Yourself',
                value: 0,
              },
              {
                label: 'Jointly with your Spouse',
                value: 1,
              },
              {
                label: 'Jointly with Another Person',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.ownershipInterestLastThreeYears',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Do you have a family relationship or business affiliation with the seller of the property?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.specialBorrowerSellerRelationship',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.loanPurposeType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                },
              ],
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.downPaymentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is the amount of this money?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.downPaymentBorrowedAmount',
            formFieldType: 'currency',
            prefix: '$',
            validators: {
              required: true,
            },
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.downPaymentIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.additionalMortgageApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.additionalCreditApplication',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.propertyProposedCleanEnergyLien',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.noteEndorserIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are there any outstanding judgments against you?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.outstandingJudgmentsIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Are you currently delinquent or in default on a Federal debt?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.presentlyDelinquentIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Are you party to a lawsuit in which you potentially have any personal financial liability?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.partyToLawsuitIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Have you conveyed title to any property in lieu of foreclosure in the past 7 years?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.priorPropertyDeedInLieu',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: SelectOneYesNoEnum.Yes,
              },
              {
                label: 'No',
                value: SelectOneYesNoEnum.No,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //

          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.priorPropertyShortSale',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 1,
              },
              {
                label: 'No',
                value: 2,
              },
            ],
            validators: {
              requiredCustom: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you had property foreclosed upon in the last 7 years?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.propertyForeclosedIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Have you declared bankruptcy within the past 7 years?',
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.bankrupcyIndicator',
            formFieldType: 'toggle',
            formFieldData: [
              {
                label: 'Yes',
                value: 0,
              },
              {
                label: 'No',
                value: 1,
              },
            ],
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: 'Identify the types of bankruptcy',
            // field: 'loan.transactionInfo.borrowers[1].declarationsInfo.bankruptcyType',
            field: 'loan.$$custom.bankrupcyType2',
            formFieldType: 'select',
            multiple: true,
            formFieldData: [
              {
                label: 'Chapter 7',
                value: BankruptcyTypeEnum.ChapterSeven,
              },
              {
                label: 'Chapter 11',
                value: BankruptcyTypeEnum.ChapterEleven,
              },
              {
                label: 'Chapter 12',
                value: BankruptcyTypeEnum.ChapterTwelve,
              },
              {
                label: 'Chapter 13',
                value: BankruptcyTypeEnum.ChapterThirteen,
              },
            ],
            visible: {
              rules: [
                {
                  field: 'loan.transactionInfo.borrowers[1].declarationsInfo.bankrupcyIndicator',
                  operator: RuleExprOp.EQ,
                  value: 0,
                },
              ],
            },
            validators: {
              required: true,
            },
          }, //
          <CvFormBuilder.FormField>{
            type: 'formField',
            placeholder: `What is your citizenship status?`,
            field: 'loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator',
            formFieldType: 'select',
            formFieldData: [
              {
                label: 'Select',
                value: null,
              },
              {
                label: 'U.S. Citizen',
                value: 0,
              },
              {
                label: 'Permanent Resident Alien',
                value: 1,
              },
              {
                label: 'Non-Permanent Resident Alien',
                value: 2,
              },
            ],
            validators: {
              required: true,
            },
          }, //
        ],
      },
    ],
  }, // end page

  guidPageZ: {
    title: 'Summary',
    pageId: 'summary',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary',
      },
    ],
  }, // end page
};
