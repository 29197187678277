<div class="container" automationid="Page_MyLoans">

  <div class="row">
    <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">

      <mat-card>

        <!-- PAGE CONTENT -->
        <!-- " d-flex justify-content-between" -->
        <div class="mb-5 page-title text-center description border-color-primary color-primary">
          <h1>My Loans</h1></div>
        <div class="mb-5 buttons apply-new-loan" automationid="Section_CreateNewLoanConfirm"
             *ngIf="canAddNewLoan">
          <button
                  mat-flat-button
                  color="accent"
                  class="d-flex new-loan"
                  (click)="onApplyNewLoan()"
          >
            <i class="me-1 fa fa-plus-square-o" aria-hidden="true"></i>
            Apply for new loan
          </button>
        </div>

        <div role="alert" class="alert alert-danger icon my-4" *ngIf="error">{{error}}</div>

        <ng-container *ngIf="{
          loanSnapshotsApiState: loanSnapshotsApiState$ | async,
          loanSnapshots: loanSnapshots$ | async
        } as route">
          <app-api-state [state]="route.loanSnapshotsApiState">

            <div class="row justify-content-center">

              <!-- NO EXISTING LOANS -->
              <div class="col-md-6" *ngIf="route?.loanSnapshots?.length === 0" automationid="Section_NoExistingLoans">
                <mat-card class="loan-snapshot p-0 mb-5">
                  <div class="header d-flex">
                    <div class="icon application"></div>
                    <div class="loan-title flex-grow-1 d-flex align-items-center">Application</div>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-12 property">
                        <div class="description">Property</div>
                        <div class="value">Pending</div>
                      </div>
                    </div><!-- .row -->
                    <div class="row">
                      <div class="col-6">
                        <div class="description">Loan Purpose</div>
                        <div class="value">Pending</div>
                      </div>
                      <div class="col-6 text-end">
                        <div class="description">Loan Amount</div>
                        <div class="value">Pending</div>
                      </div>
                    </div><!-- .row -->
                    <div class="row">
                      <div class="col-6">
                        <div class="description">Loan Status</div>
                        <div class="value">Application</div>
                      </div>
                      <div class="col-6 text-end">
                        <div class="description">Interest Rate</div>
                        <div class="value">Pending</div>
                      </div>
                    </div><!-- .row -->
                    <div class="mt-4 buttons">
                      <button
                              mat-flat-button
                              color="accent"
                              (click)="createNewLoan()"
                              automationid="BtnCreateNewLoan"
                      >
                        <span class="d-flex justify-content-between">
                          <span>Continue</span>
                          <span><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </mat-card>
              </div>

              <!-- LIST OF EXISTING LOANS -->
              <div class="col-md-6" *ngFor="let loan of route.loanSnapshots" [attr.automationid]="'Section_ListOfLoans'">
                <mat-card class="loan-snapshot p-0 mb-5">

                  <div class="header d-flex">
                    <div class="icon {{loan.loanTitle | textCase:'lower'}}"></div>
                    <h2 class="loan-title flex-grow-1 d-flex align-items-center">{{loan.loanTitle}}</h2>
                    <!--                    <div class="loan-number text-end">-->
                    <!--                      <div class="description">Loan#</div>-->
                    <!--                      <div class="number">1234567890</div>-->
                    <!--                    </div>-->
                  </div>

                  <div class="body">
                    <div class="row">
                      <div class="col-12 property">
                        <div class="description">Property</div>
                        <div class="value">
                          <ng-container *ngIf="loan.subjectPropertyAddress && loan.subjectPropertyAddress != 'TBD'">
                            {{loan.subjectPropertyAddress}}{{loan.subjectPropertyUnitNumber ? ', #' + loan.subjectPropertyUnitNumber : ''}}<br>
                          </ng-container>
                          {{loan.subjectPropertyCity ? loan.subjectPropertyCity + ', ' : ''}}{{loan.subjectPropertyState}} {{loan.subjectPropertyZip}}
                        </div>
                      </div>
                    </div><!-- .row -->
                    <div class="row">
                      <div class="col-6" *ngIf="displayLoanNumber">
                        <div class="description">Loan #</div>
                        <div class="value">{{loan.loanNumber || 'Pending'}}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="description">Loan Purpose</div>
                        <div class="value">{{loan.loanPurposeString}}</div>
                      </div>
                      <div class="col-6 text-end">
                        <div class="description">Loan Amount</div>
                        <div class="value">{{loan.loanAmount && loan.loanAmount != 0 ? (loan.loanAmount | currency) : ''}}</div>
                      </div>
                    </div><!-- .row -->
                    <div class="row">
                      <div class="col-6">
                        <div class="description">Loan Status</div>
                        <div class="value">{{loan.loanStatusString}}</div>
                      </div>
                      <div class="col-6 text-end" *ngIf="displayInterestRate">
                        <div class="description">Interest Rate</div>
                        <div class="value">{{loan.loanRate && loan.loanRate != 0 ? ((loan.loanRate / 100) | percent:'1.3-3') : 'Pending'}}</div>
                      </div>
                    </div><!-- .row -->
                    <div class="row" *ngIf="loan.showDecisionedText">
                      <div class="col-md-12">
                        <div class="value">
                          {{loan.loanStatusDescription}}
                        </div>
                      </div>
                    </div><!-- .row -->

                    <div class="mt-4 buttons" *ngIf="{isBusy: isBusy$ | async} as x">
                      <button mat-flat-button
                              color="{{loan.canContinue ? 'accent' : 'primary-outline'}}"
                              [class.color-primary]="!loan.canContinue"
                              (click)="openLoan(loan)"
                              [disabled]="x.isBusy"
                              [attr.automationid]="'BtnOpenLoan_' + loan.loanId"
                      >
                        <ng-container *ngIf="x.isBusy else showContinueText">
                          <i class="fa fa-spinner fa-spin"></i>
                        </ng-container>
                        <ng-template #showContinueText>
                          <span class="d-flex justify-content-between" *ngIf="loan.canContinue else showViewText">
                            <span>Continue</span>
                            <span><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
                          </span>
                          <ng-template #showViewText>
                            View Your Loan
                          </ng-template>
                        </ng-template>
                      </button>
                    </div>

                  </div>
                </mat-card>
              </div>
            </div>

          </app-api-state>
        </ng-container>

      </mat-card>
    </div>
  </div>
</div>
