import {
  ILookupItem,
  IDebtViewModel,
  IBaseViewModel,
  IBorrowerViewModel,
  IFicoScoreViewModel,
  IPublicRecordsViewModel,
  IRealEstateViewModel,
  IIncomeInfoViewModel,
  IPropertyViewModel,
  IEmploymentInfoViewModel,
  IMiscellaneousDebtViewModel,
  ILoanContact,
  ICreditWithBorrowersViewModel,
  IZipCalculationData,
  ILiabilityViewModel,
  ILoanFinancialInfoViewModel,
  IAssetViewModel,
  ILoanViewModel,
  IConfigurationViewModel,
  IBillingInformationViewModel,
  IBaseLoanViewModel,
  IAuthenticationResponseViewModel,
  IVOAViewModel
} from 'src/app/shared/models/generated/viewmodels';
import { PropertyUsageTypeEnum, EmploymentVerificationTypeEnum } from 'src/app/shared/models/generated/enums';
import { CPOSIncomeTypeEnum, CPOSClientLogLevelEnum, CPOSCreditVendorEnum, CPOSLoanPurposeTypeEnum, CPOSElectronicAddressTypeEnum } from 'src/app/shared/models/extended/enums';
import { IList, IEnumerable } from '../types';

/**
 * Extended Models
 */
export interface ICPOSAssetViewModel extends IAssetViewModel {
  index?: number;
  previousOwnerId?: string;
  /** Delete this out of the array on map */
  forDelete?: boolean;
}

export interface ICPOSAuthenticationResponseViewModel extends IAuthenticationResponseViewModel {
  mfaToken?: string;
}

export interface ICPOSBillingInformationViewModel extends IBillingInformationViewModel {
  pciCompliancePayment?: any;
}

export interface ICPOSLoanViewModel extends ILoanViewModel {
  $$custom?: ICPOSCustom;
  borrowerUserAccountId: number;
  loUserAccountId: number;
}

export interface ICPOSConfigurationViewModel extends IConfigurationViewModel {
  loanCommentsRecipientsEnabled: boolean;
  loanCommentsRecipientsList: string;
  loanNumberingEnabled: boolean;
  uploadToExternalRepository: boolean;
  isGetLoanNumberProductEnabled: boolean;
}

export interface ICPOSBaseLoanViewModel extends IBaseLoanViewModel {
  isBaseLoan?: boolean;
}

export interface ICPOSLoanFinancialInfoViewModel extends ILoanFinancialInfoViewModel {
  downPaymentTypeCodeOriginal?: number;
}

/**
 * Only needed for Clover
 */

export interface ICPOSBorrowerWithAdditionalInfoViewModel {
  incomeTypes: IList<ILookupItem>;
}

export interface ICPOSCollectionsViewModel {
  collections: IList<IDebtViewModel>;
  comments: IList<ILookupItem>;
  visible: boolean;
}

export interface ICPOSCreditTabViewModel extends IBaseViewModel {
  borrower: IBorrowerViewModel;
  borrowerDebtAccountOwnershipTypes: IList<ILookupItem>;
  borrowersForDropDown: IList<ILookupItem>;
  coBorrower: IBorrowerViewModel;
  coBorrowerDebtAccountOwnershipTypes: IList<ILookupItem>;
  coborrowerExists: boolean;
  collectionsViewModel: ICPOSCollectionsViewModel;
  creditDataAvailable: boolean;
  creditExceptionsResolved: boolean;
  creditFileStoreItemId?: string;
  creditReportMessage: string;
  creditReportMessageVisible: boolean;
  creditReportTimeout: number;
  debtAccountOwnershipTypes: IList<ILookupItem>;
  disableFields: boolean;
  disableReRunCreditButton: boolean;
  enableMoRentalIncome: boolean;
  ficoScoreInformation: IFicoScoreViewModel;
  isLoanHarp: boolean;
  isMultiBorrowerChildLoan: boolean;
  liabilitiesViewModel: ICPOSLiabilitiesViewModel;
  loanId: string;
  loanType: number;
  miscellaneousExpensesViewModel: ICPOSMiscellaneousExpensesViewModel;
  primaryBorrowerLoanid: string;
  publicRecordsViewModel: IPublicRecordsViewModel;
  realEstateViewModel: IRealEstateViewModel;
  states: IList<ILookupItem>;
  totalAssetsAmount?: number;
  totalOwnershipPercentage: number;
}

export interface ICPOSIncomeTabViewModel extends IBaseViewModel {
  additionalEmploymentTypes: IList<ILookupItem>;
  amountPeriods: IList<ILookupItem>;
  borrower: ICPOSBorrowerWithAdditionalInfoViewModel;
  borrowers: IList<ILookupItem>;
  branches: IList<ILookupItem>;
  coBorrower: ICPOSBorrowerWithAdditionalInfoViewModel;
  coBorrowerExists: boolean;
  disableFields: boolean;
  employmentStatuses: IList<ILookupItem>;
  employmentTypes: IList<ILookupItem>;
  emptyMilitaryIncomeInfo: IList<IIncomeInfoViewModel>;
  emptyRegularIncomeInfo: IList<IIncomeInfoViewModel>;
  otherIncomeInfo: IList<IIncomeInfoViewModel>;
  rentalIncomeTypes: IList<ILookupItem>;
}

export interface ICPOSLiabilitiesViewModel {
  comments: IList<ILookupItem>;
  liabilities: IList<IDebtViewModel>;
  systemCalculatedMinPaymentVisible: boolean;
  types: IList<ILookupItem>;
}

export interface ICPOSBorrowerViewModelSane extends IBorrowerViewModel {
  addressCurrent: IPropertyViewModel;
  addressMailing: IPropertyViewModel;
  addressHistory: IPropertyViewModel;
  employments: IEmploymentInfoViewModel[];
  incomes: IIncomeInfoViewModel[];
}

export interface ICPOSMiscellaneousExpensesViewModel {
  miscellaneousDebts: IList<IMiscellaneousDebtViewModel>;
  miscellaneousDebtTypes: IList<ILookupItem>;
}


/** Custom properties needed by the configuration */
export interface ICPOSCustom {
  bankrupcyType: number[];
  bankrupcyType2: number[];
  assetType: number;
  assetIdActive?: string;
  isEVerifyAsset: boolean;
  isEVerifyIncomeEmployment: boolean;
  isEVerifyIncomeEmploymentSpouse: boolean;
  isEditing: boolean;
  loanOfficer: ILoanContact;
  noAssets: boolean;
  nonSpouseCoborrower: boolean;
  creditResponse: ICreditWithBorrowersViewModel;
  borrowerActive: ICPOSBorrowerViewModel;
  borrowerRegistrationOffered: boolean;
  spouseSecurityAnswerProvided: boolean | null;
  borrowerJointAccount: boolean | null;
  creditAuthAudited: boolean;
  nps: number;
  jointCredit: boolean;
  jointCredit2: boolean;
  ssn: string;
  ssnSpouse: string;
  isRunCreditAuthorizedSpouse: boolean;
  isRunEvoiEvoeAuthorized: boolean;
  isRunEvoiEvoeAuthorizedSpouse: boolean;
  isMilitary: boolean;
  isMilitarySpouse: boolean;
  incomeType: boolean;
  signedPurchaseAgreement: boolean;
  haveREAgent: boolean;
  agentLoanParticipant: any;
  agentName: string;
  agentEmail: string;
  agentPhone: string;
  zipLookupResponse: IZipCalculationData;
  primaryBorrowerIncomeComplete: boolean;
  recordCreditAuth: boolean;
  subjectPropertyOccupancyType: PropertyUsageTypeEnum;
  frozenCreditScore: boolean;
  employmentVerificationType: EmploymentVerificationTypeEnum;
  showVoiVoeAuthInCredit: boolean;
  twnFailed: boolean;
  twnFailedSpouse: boolean;
  twnFailedInCreditSection: boolean;
  twnFailedInCreditSectionSpouse: boolean;
  alimonyEtc: ICPOSAlimonyEtc;
  additionalREOsPreVal: boolean;
  additionalREOsPreValSpouse: boolean;
  showSecurityQuestionCoBorrowerPage: boolean;
  securityQuestionId: number;
  useEVOA: boolean;
  evoaAggreedTermsOfService: boolean;
  evoaTermsOfServiceUrl: string;
  inviteAdditionalApplicantEnabled: boolean;
  isChildAppOriginatedByBorrower: boolean;
  /** Holds the remapped loan from loan center */
  loan: ICPOSLoan;
  stub: any;
  programTypeBorrower: number;
  programTypeCoBorrower: number;
  ssoUser: boolean;
  isEmploymentInformationEmpty?: boolean;
  instantApprovalStatus?: boolean;
  newMonthlyPayment: number;
  cashToClose: number;
}


export interface ICPOSAlimonyEtc {

  hasAlimony: boolean;
  alimonyMonthlyAmount: number;
  ChildSupportMonthlyAmount: number;
  SeperateMaintenanceMonthlyAmount: number;
  hasAlimonySpouse: boolean;
  alimonyMonthlyAmountSpouse: number;
  ChildSupportMonthlyAmountSpouse: number;
  SeperateMaintenanceMonthlyAmountSpouse: number;
  miscellaneousDebtId?: string;
  alimonyMiscellaneousDebtId: string;
  childSupportMiscellaneousDebtId: string;
  seperateMiscellaneousDebtId: string;
  alimonyMiscellaneousDebtIdSpouse: string;
  childSupportMiscellaneousDebtIdSpouse: string;
  seperateMiscellaneousDebtIdSpouse: string;

}

/** Holds the remapped loan from loan center */
export interface ICPOSLoan {
  employments: ICPOSEmployment[];
  incomes: any;
  liabilities: ILiabilityViewModel[];
  addressSubject: ICPOSPropertyViewModel;
  originalSubjectProeprty?: ICPOSPropertyViewModel;
  downPaymentAsset: IAssetViewModel;
  assets: IAssetViewModel[];
  borrowerPrimary: ICPOSPrimaryBorrowerViewModel;
  borrowerSecondary: ICPOSBorrowerViewModel;
  financialInfo: ICPOSLoanFinancialInfoViewModel;
  isSubjectPropertyInValid?: boolean;
  additionalApplicant: ICPOSAdditionalApplicantViewModel;
}

/** Add property info to borrower model */
export interface ICPOSPrimaryBorrowerViewModel extends ICPOSBorrowerViewModel {
  maritalStatusOther: number | null;
}

export interface ICPOSBorrowerViewModel extends IBorrowerViewModel {
  isAddressSameAsPrimaryBorrower: boolean;
  addressCurrent: ICPOSPropertyViewModel;
  isMailingAddressSameAsCurrent: boolean;
  addressMailing: IPropertyViewModel;
  addressHistory: ICPOSPropertyViewModel;
  /** To store email address on co-borrower registeration */
  usernameStaged: string;
}

export interface ICPOSPropertyViewModel extends IPropertyViewModel {
  displayUnitNumber: string | null;
  displayStreetName: string | null;
}

export interface ICPOSAdditionalApplicantViewModel {
  firstName: string;
  isOnlineUser?: boolean;
  lastName: string;
  loanApplicationId?: string;
  middleName: string;
  phoneNumber: string;
  phoneType?: number;
  securityAnswer: string;
  securityQuestionId: number;
  suffix: string;
  userAccountId?: number;
  username: string;
}

/** Updated employment model */
export interface ICPOSEmployment {
  index?: number;
  /** Delete this out of the array on map */
  forDelete?: boolean;
  /** Internal income type */
  cvIncomeTypeId?: CPOSIncomeTypeEnum;
  /** Employment guid */
  employmentInfoId: string;
  /** Income guid */
  incomeInfoId: string;
  /** Borrower guid */
  borrowerId: string;
  preserveOnTwnFilter: boolean;
  /** Electronically verified */
  isEVerified: boolean;
  employmentVerificationEmploymentId: number;
  /** Borrower notes */
  notes: string;
  isForeign: boolean;
  /** IsPrevious */
  isPrevious: boolean;
  isSeasonal: boolean;
  /** Income info */
  income: ICPOSIncome;
  /** Is there any relationship between borrower and employer? */
  isSpecialBorrowerEmployerRelationship: boolean;
  isTwoYearsPeriodMissing?: boolean;
  isTwoYearsPeriodFulfilled?: boolean;
  /** Employer info */
  employerInfo: {
    name: string;
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    legalEntityType: number;
    cloverLegalEntityType: number;
    cloverLegalEntityLLC: number;
  };
  /** Position info */
  positionInfo: {
    title: string;
    branch: number;
    rank: string
    dateStart: string;
    dateEnd: string;
    yearsInPosition: string;
    monthsInPosition: number;
    OwnershipInterestType: number;
    expirationOfService: string;
  };
}

/** Income types */
export interface ICPOSIncome {
  incomeMatrix: IList<IIncomeInfoViewModel>;
  /** Salaried/monthly/hourly */
  basePaymentPeriodId: number;
  /** Hours per week */
  baseHoursPerWeek: number;
  /** Default amount for this income type */
  baseAmount: number;

  annualOvertime: number;
  annualBonus: number;
  annualCommission: number;

  // Social Security props
  monthlySocialSecurityPay: number;

  // Pension props
  retirementStartDate: string;
  retirementEndDate: string;
  monthlyPension: number;

  // Other Income propes
  otherIncomeTypeId: number;
  otherIncomeAmount: number;
  otherIncomeDescription: string;
  otherPaymentPeriodId: number;
  otherPayPerHour: number;

  /** is the applicant/retiree retired and receiving their own retirement money. */
  retirementIncomeOther: boolean;

  // if self-employed is loss
  isLoss: boolean;
}

export interface ICPOSAuditLogRequest {
  loanId: string;
  loanApplicationId: string;
  borrowerFistName?: string;
  borrowerMiddleName?: string;
  borrowerLastName?: string;
  borrowerFullName?: string;
}

export interface ICPOSSrvApiResponse<T> {
  errorMsg: any;
  exception: any;
  header: any;
  response: T;
  status: any;
}

export interface ICPOSSrvApiResponseCased<T> {
  ErrorMsg: any;
  Exception: any;
  Header: any;
  Response: T;
  Status: any;
}

export interface ICPOSLocationMLS {
  city?: string;
  county?: string;
  days_on_market?: number;
  display_address?: string;
  display_lat?: number;
  display_lng?: number;
  hoa_fee?: string;
  is_condo?: string;
  state?: string;
  is_condo_townhouse?: string;
  is_lot?: string;
  is_multi_family?: string;
  listing_office_name?: string;
  office_name?: string;
  is_single_family?: string;
  is_townhouse?: string;
  listing_price?: string;
  listing_status?: string;
  listing_status_standardized?: string;
  lot_dimension?: string;
  photo_url?: string;
  photos?: string;
  property_id?: number;
  square_feet?: string;
  thumbnail_url?: string;
  total_bathrooms?: number;
  total_bedrooms?: number;
  year_built?: string;
  zip_code?: number;
  metadata?: any;
}

export interface ICPOSUser {
  id: number;
  name?: string;
  username: string;
  email?: string;
  address?: {
    street?: string;
    suite?: string;
    city?: string;
    zipcode?: string;
    geo?: {
      lat?: string;
      lng?: string;
    };
  };
  phone?: string;
  website?: string;
  company?: {
    name?: string;
    catchPhrase?: string;
    bs?: string;
  };
}

export interface ICPOSIsValidUserAndActiveByUserNameResponse {
  userName?: string;
  isValidUserAccount?: boolean;
  isUserAccountActive?: boolean;
  loanId?: string;
}

export interface ICPOSAppState {
  loaded?: boolean;
  form1003?: {
    /** If the urla changes midloan, has the modal already been shown */
    urlaModalShown?: boolean;
    isStarted: boolean;
    isActive: boolean;
    completedDate?: string;
    state: ICPOSForm1003State[];
    indexes?: Record<string, number>;
    pagePath?: string[];
    isSubmitted?: boolean;
  };
  dashboard?: {
    isStarted: boolean;
    isActive: boolean;
    completedDate?: string;
  };
  complete?: boolean;
}

export interface ICPOSEnvConfig {
  apiUrl: string;
  clientId: string;
  cookieDomain: string;
  mixpanelKey: string;
}

export interface ICPOSAppConfig {
  defaultValue?: string;
  description?: string;
  isValueFromOtherContext?: boolean;
  key?: string;
  value?: any;
}

export interface ICPOSLogEntry {
  eventTime?: string | Date;
  logLevel?: CPOSClientLogLevelEnum;
  class?: string;
  message?: string;
  exception?: any;
  metadata?: Record<string, any>;
}

export interface ICPOSForm1003State {
  sectionId: string;
  lastPageId: string;
  isComplete: boolean;
  isActive: boolean;
  title: string;
}

export interface ICPOSIsExistingUserResponse {
  isValidUserAccount: boolean;
  isUserAccountActivated: boolean;
  isSameName: boolean;
  userAccountId: number | null;
}

export interface ICPOSCreditScoreDetails {
  creditVendor?: CPOSCreditVendorEnum;
  frozen?: boolean;
  noScore?: boolean;
  score: number;
}

export interface ICPOSIVOAViewModel extends IVOAViewModel  {
  assetInfoId: string;
  financialInstitutionId: number;
  financialInstitutionName: string;
  institutionLogoUrl: string;
  jointAccount: boolean;
  ownerId: string;
}

export interface ICPOSIVOAListViewModel {
  accounts: IEnumerable<ICPOSIVOAViewModel>;
  assets: IEnumerable<ICPOSAssetViewModel>;
  financialInstitutionId: number;
  financialInstitutionName: string;
  institutionLogoUrl: string;
}

export interface ICPOSModalData {
  title?: string;
  body?: string;
  buttonDiscard?: string;
  buttonConfirm?: string;
}

export interface IPreviousContactIdsViewModel {
  prevLoasIds: string;
  prevLoanProcessorsIds: string;
}

export interface ExternalLeadImportViewModel {
  LeadId: string;
  ExternalLeadId: string;
  BorrowerFirstName: string;
  BorrowerLastName: string;
  BorrowerEmail: string;
  PreferredPhone: string;
  PreferredPhoneType: number;
  LoanPurposeType: number;
  StateName: string;
  Zip: string;
}

export interface ICPOSExternalLeadImport {
  leadId: string;
  externalLeadId: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerEmail: string;
  preferredPhone: string;
  preferredPhoneType: CPOSElectronicAddressTypeEnum;
  loanPurposeType: CPOSLoanPurposeTypeEnum;
  stateName: string;
  zip: string;
}

export interface ICPOSSessionInfo {
  reason: boolean;
  idleTimeout?: number;
  logOutClicked?: boolean;
}

export class CPOSExternalLeadImport implements ICPOSExternalLeadImport {
  leadId: string;
  externalLeadId: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerEmail: string;
  preferredPhone: string;
  preferredPhoneType: CPOSElectronicAddressTypeEnum;
  loanPurposeType: CPOSLoanPurposeTypeEnum;
  stateName: string;
  zip: string;

  constructor(getExternalLeadImportResponse: ExternalLeadImportViewModel) {
    if (getExternalLeadImportResponse != null) {
      this.leadId = getExternalLeadImportResponse.LeadId
      this.externalLeadId = getExternalLeadImportResponse.ExternalLeadId
      this.borrowerFirstName = getExternalLeadImportResponse.BorrowerFirstName
      this.borrowerLastName = getExternalLeadImportResponse.BorrowerLastName
      this.borrowerEmail = getExternalLeadImportResponse.BorrowerEmail
      this.preferredPhone = getExternalLeadImportResponse.PreferredPhone
      this.preferredPhoneType = getExternalLeadImportResponse.PreferredPhoneType
      this.loanPurposeType = getExternalLeadImportResponse.LoanPurposeType
      this.stateName = getExternalLeadImportResponse.StateName
      this.zip = getExternalLeadImportResponse.Zip
    }
  }
}
