import { mapPages, mapSectionPageContents } from '../form-builder.utils';
import { CPOSPageValidatorsEnum, URLAFormTypeEnum } from 'src/app/shared/models';

function applyCustomizations(sections: CvFormBuilder.Section[], _urlaType: URLAFormTypeEnum): CvFormBuilder.Section[] {
  return mapPages(sections, (page, [sectionId, pageId]) => {
    try {
      switch (sectionId) {
        case 'income':    return applyIncomeCustomizations(page, pageId);
        case 'credit':    return applyCreditCustomizations(page, pageId);
        default:          return page;
      }
    } catch (err) {
      console.error('Error mapping client customizations', err);
      return page;
    }
  });
}

function applyIncomeCustomizations(page: CvFormBuilder.Page, pageId: string): CvFormBuilder.Page {
  switch (pageId) {
    case 'employer-job':
    case 'military-info':
    case 'employer-job2':
      return mapSectionPageContents(page, (c: any) => {
        if (c.id == 'years-in-profession-label'
          || c.field == 'loan.$$custom.loan.employments[].positionInfo.rank'
          || c.field == 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition'
          || c.field == 'loan.$$custom.loan.employments[].positionInfo.monthsInPosition') {
          return null
        }
        return c;
      });
    case 'employer':
    case 'business':
      return mapSectionPageContents(page, (c: any) => {
        switch (c.type) {
          case 'formField': {
            if (c.field == 'loan.$$custom.loan.employments[].employerInfo.unitNumber') {
              return c;
            }
            return { ...c,
              validators: { ...c.validators,
                required: true,
              },
            };
          }
          case 'feature':
            return { ...c,
              fields: c.fields.map((f: any) => ({ ...f,
                validators: { ...f.validators,
                  required: true,
                }
              })),
            };
          default:
            return c;
        }
      });
    default:
      return page;
  }
}

function applyCreditCustomizations(page: CvFormBuilder.Page, pageId: string): CvFormBuilder.Page {
  switch (pageId) {
    case 'info':
      return { ...page,
        validatorId: CPOSPageValidatorsEnum.creditPull,
      };
    case 'info-coborrower':
      return { ...page,
        validatorId: CPOSPageValidatorsEnum.creditPullSpouse,
      };
    default:
      return page;
  }
}

export default applyCustomizations;
