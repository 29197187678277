<app-header-simple></app-header-simple>

<main class="container" *ngIf="config$ | async as config" automationid="Page_RegisterUser">

  <div class="row">
    <div class="col col-12" [ngClass]="isLsidProvidedInQuery ? 'col-sm-7 col-md-8 col-lg-8 offset-lg-1 order-2 order-sm-1' : 'col-lg-8 offset-lg-2'">
      <div class="card">

        <div class="card-body py-5 px-md-5">
          <div class="row">
            <div class="col-lg-6">

              <div class="text-center mb-5">
                  <h1 *ngIf="config && config['clover.account.register.page.title'];else title" [innerHtml]="config['clover.account.register.page.title'].value"></h1>
                  <ng-template #title>Let's get started!</ng-template>
              </div>
              <div class="image-container">
                  <img *ngIf="config && config['clover.account.register.page.image'];else image" [src]="config['clover.account.register.page.image'].value" alt=""/>
                  <ng-template #image><img src="/assets/img/register.png" alt=""/></ng-template>
              </div>
            </div>
            <div class="col-lg-6">

              <div class="form-heading mb-5">
                <h1>Register</h1>
                <a routerLink="/login" queryParamsHandling="merge" automationid="Section_RegisterUser" *ngIf="!isSSOUser">I already have an account</a>
              </div>

              <div *ngIf="formMain.invalid && isRegistrationError==true" role="alert" class="alert alert-danger" automationid="Alert">
                <h3>
                  <i class="fa fa-warning #400101 field-icon anim-bounce-in fa-lg" aria-hidden="true" role="presentation"></i>&nbsp;
                  <b>Required Fields</b>
                </h3>
                The following fields are required:<br />
                <ul>
                  <li *ngIf="formMain.get('firstName').value == null || formMain.get('firstName').value == ''">First name is <strong>required</strong></li>
                  <li *ngIf="formMain.get('lastName').value == null || formMain.get('lastName').value == ''">Last name is <strong>required</strong></li>
                  <li *ngIf="formMain.get('email').hasError('accountInactive')">The email exists but hasn't been activated</li>
                  <li *ngIf="formMain.get('email').hasError('emailTaken')">This email address is taken</li>
                  <li *ngIf="formMain.get('email').hasError('validEmail')">Please provide valid email address</li>
                  <li *ngIf="formMain.get('emailConfirm').value == null || formMain.get('emailConfirm').value == '' || formMain.hasError('notSameEmail')">Your confirm email must match</li>
                  <li *ngIf="formMain.get('password').invalid">Please provide valid password</li>
                </ul>
              </div>

              <ng-container *ngIf="!isLsidAvailable">
                <p>Registration has been disabled. Please contact your loan officer.</p>
              </ng-container>

              <form *ngIf="isLsidAvailable" [formGroup]="formMain" (ngSubmit)="onRegister()">

                <div class="existing-account-container mb-3" *ngIf="formMain.get('email').hasError('accountInactive')">
                  <div class="alert alert-danger icon text-center">
                    This email address exists in our system, but needs to be activated. Please click "Send Activation Email" below
                  </div>
                  <button mat-flat-button
                          color="outline"
                          type="button"
                          class="border-color-primary color-primary"
                          (click)="sendActivationEmail(formMain)"
                          [disabled]="activationEmailLoading"
                          [ngClass]="{ 'btn-waiting': activationEmailLoading }"
                          automationid="BtnSendEmail">
                    <ng-container *ngIf="!activationEmailLoading; else showActivationEmailLoading">
                      Send Activation Email
                    </ng-container>
                    <ng-template #showActivationEmailLoading>
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Sending...
                    </ng-template>
                  </button>
                </div>

                <mat-form-field>
                  <input matInput placeholder="First Name" type="text" maxlength="100" id="firstName" formControlName="firstName" autocomplete="given-name" automationid="FirstName" required>
                  <mat-error>First name is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Last Name" type="text" maxlength="100" id="lastName" formControlName="lastName" autocomplete="family-name" automationid="LastName" required>
                  <mat-error>Last name is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Email" type="text" maxlength="100" id="email" formControlName="email" autocomplete="email" automationid="Email" required>
                  <mat-error>
                    <ng-container *ngIf="formMain.get('email').hasError('accountInactive'); else emailError">
                      The email exists but hasn't been activated
                    </ng-container>
                    <ng-template #emailError>
                      <ng-container *ngIf="formMain.get('email').hasError('emailTaken'); else invalidEmailError">
                        This email address is taken
                      </ng-container>
                      <ng-template #invalidEmailError>
                        Not a valid email address
                      </ng-template>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
                <mat-form-field [class.has-error]="formMain.hasError('notSameEmail')">
                  <input matInput
                         placeholder="Confirm Email"
                         type="text"
                         maxlength="100"
                         id="emailConfirm"
                         formControlName="emailConfirm"
                         autocomplete="email"
                         (blur)="checkSameEmails()"
                         [errorStateMatcher]="emailErrorMatcher"
                         automationid="ConfirmEmail"
                         required>
                  <mat-error>
                    <ng-container *ngIf="formMain.hasError('notSameEmail'); else showDefaultConfirmEmailError">
                      Your email does not match
                    </ng-container>
                    <ng-template #showDefaultConfirmEmailError>
                      Confirm email is required
                    </ng-template>
                  </mat-error>
                </mat-form-field>

                <div class="preferred-phone-container" *ngIf="externalLeadImport != null">
                  <mat-form-field>
                    <input matInput placeholder="Preferred Phone" maxlength="15" type="text" id="preferredPhone" formControlName="preferredPhone" automationid="PreferredPhone" required>
                  </mat-form-field>
                </div>

                <div class="preferred-phone-type" *ngIf="externalLeadImport != null">
                  <mat-form-field>
                    <mat-select id="preferredPhoneType" placeholder="Preferred Phone Type" formControlName="preferredPhoneType" automationid="PreferredPhoneType">
                      <mat-option value="0">Home Phone</mat-option>
                      <mat-option value="1">Cell Phone</mat-option>
                      <mat-option value="2">Work Phone</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="loan-purchase-type" *ngIf="externalLeadImport != null">
                  <mat-form-field>
                    <mat-select id="loanPurposeType" placeholder="Loan Purpose" formControlName="loanPurposeType" automationid="LoanPurpose">
                      <mat-option value="1">Purchase</mat-option>
                      <mat-option value="2">Refinance</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="state-name" *ngIf="externalLeadImport != null">
                  <mat-form-field>
                    <mat-select id="stateName" placeholder="Subject Property State Name" formControlName="stateName" automationid="StateName">
                      <mat-option value="AL">Alabama</mat-option>
                      <mat-option value="AK">Alaska</mat-option>
                      <mat-option value="AZ">Arizona</mat-option>
                      <mat-option value="AR">Arkansas</mat-option>
                      <mat-option value="CA">California</mat-option>
                      <mat-option value="CO">Colorado</mat-option>
                      <mat-option value="CT">Connecticut</mat-option>
                      <mat-option value="DE">Delaware</mat-option>
                      <mat-option value="DC">District Of Columbia</mat-option>
                      <mat-option value="FL">Florida</mat-option>
                      <mat-option value="GA">Georgia</mat-option>
                      <mat-option value="HI">Hawaii</mat-option>
                      <mat-option value="ID">Idaho</mat-option>
                      <mat-option value="IL">Illinois</mat-option>
                      <mat-option value="IN">Indiana</mat-option>
                      <mat-option value="IA">Iowa</mat-option>
                      <mat-option value="KS">Kansas</mat-option>
                      <mat-option value="KY">Kentucky</mat-option>
                      <mat-option value="LA">Louisiana</mat-option>
                      <mat-option value="ME">Maine</mat-option>
                      <mat-option value="MD">Maryland</mat-option>
                      <mat-option value="MA">Massachusetts</mat-option>
                      <mat-option value="MI">Michigan</mat-option>
                      <mat-option value="MN">Minnesota</mat-option>
                      <mat-option value="MS">Mississippi</mat-option>
                      <mat-option value="MO">Missouri</mat-option>
                      <mat-option value="MT">Montana</mat-option>
                      <mat-option value="NE">Nebraska</mat-option>
                      <mat-option value="NV">Nevada</mat-option>
                      <mat-option value="NH">New Hampshire</mat-option>
                      <mat-option value="NJ">New Jersey</mat-option>
                      <mat-option value="NM">New Mexico</mat-option>
                      <mat-option value="NY">New York</mat-option>
                      <mat-option value="NC">North Carolina</mat-option>
                      <mat-option value="ND">North Dakota</mat-option>
                      <mat-option value="OH">Ohio</mat-option>
                      <mat-option value="OK">Oklahoma</mat-option>
                      <mat-option value="OR">Oregon</mat-option>
                      <mat-option value="PA">Pennsylvania</mat-option>
                      <mat-option value="RI">Rhode Island</mat-option>
                      <mat-option value="SC">South Carolina</mat-option>
                      <mat-option value="SD">South Dakota</mat-option>
                      <mat-option value="TN">Tennessee</mat-option>
                      <mat-option value="TX">Texas</mat-option>
                      <mat-option value="UT">Utah</mat-option>
                      <mat-option value="VT">Vermont</mat-option>
                      <mat-option value="VA">Virginia</mat-option>
                      <mat-option value="WA">Washington</mat-option>
                      <mat-option value="WV">West Virginia</mat-option>
                      <mat-option value="WI">Wisconsin</mat-option>
                      <mat-option value="WY">Wyoming</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="zip" *ngIf="externalLeadImport != null">
                  <mat-form-field>
                    <input matInput placeholder="Subject Property Zip" type="text" maxlength="9" id="zip" formControlName="zip" automationid="Zip" required>
                  </mat-form-field>
                </div>

                <div class="password-container" *ngIf="!isSSOUser">
                    <mat-form-field>
                        <input matInput
                               placeholder="Password"
                               [type]="showPassword ? 'text' : 'password'"
                               maxlength="100" 
                               id="password"
                               formControlName="password"
                               name="password"
                               autocomplete="new-password"
                               aria-describedby="password-requirements"
                               (focus)="showPasswordRequirements = true"
                               automationid="Password"
                               required>
                        <mat-error>Valid password is required</mat-error>
                    </mat-form-field>
                    <button class="toggle-show-password"
                            type="button"
                            (click)="showPassword = !showPassword"
                            [attr.aria-pressed]="!!showPassword"
                            automationid="BtnShowPassword">
                        <i class="fa"
                           [class.fa-eye]="!showPassword"
                           [class.fa-eye-slash]="showPassword"
                           role="button"
                           aria-label="View and hide password toggle button"></i>
                    </button>
                </div>

                <app-password-requirements
                  [password]="formMain.value.password"
                  [isVisible]="showPasswordRequirements || (formMain.get('password').invalid && formMain.get('password').touched)"
                ></app-password-requirements>

                <div class="mb-4">
                  <p>By clicking "Register" you agree to our
                    <a tabindex="0" (click)="onTermsClick()" (keydown.enter)="onTermsClick()" (keydown.space)="onTermsClick()" class="alink">Terms of Use</a>
                    and our
                    <a tabindex="0" (click)="onPrivacyClick()" (keydown.enter)="onPrivacyClick()" (keydown.space)="onPrivacyClick()" class="alink">Privacy Policy</a>.
                  </p>
                </div>

                <div class="text-center">
                  <button mat-flat-button
                          color="accent"
                          class="register"
                          [disabled]="waiting" [ngClass]="{'btn-waiting': waiting}"
                          aria-live="assertive"
                          automationid="BtnWaiting">
                    <ng-container *ngIf="!waiting; else showLoading">
                      Register
                    </ng-container>
                    <ng-template #showLoading>
                      <i class="fa fa-spinner fa-spin"></i>
                      Loading, please wait
                    </ng-template>
                  </button>
                </div>

                <div role="alert" class="alert alert-danger icon my-4" *ngIf="error">{{error}}</div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="isLsidProvidedInQuery" class="col col-12 col-sm-5 col-md-4 col-lg-3 order-1 order-sm-2">
      <div class="sidebar sidebar-right">
        <app-sidebar-right noLoanLoading="true"></app-sidebar-right>
      </div>
    </div>
  </div>

</main>

<app-footer></app-footer>
