<ng-container *ngIf="{
  loanContactsState: loanContactsState$ | async,
  loanContacts: loanContacts$ | async,
  appState: appState$ | async
} as route">
  <app-api-state [state]="route.loanContactsState" [disableLoading]="true">
    <mat-card class="contact-card" [ngClass]="{'no-loan': noLoanLoading}">
      <div class="contact main-contact">
        <div class="image">
            <img [src]="pictureUrl" (error)="onImageError($event)" alt="Your loan contact" />
          </div>
        <ng-container *ngIf="!route?.loanContacts?.length">
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
        </ng-container>
        <ng-container *ngIf="route?.loanContacts">
          <ng-container *ngFor="let contact of route?.loanContacts">
            <div class="contact-info" [ngClass]="{'contact-info-lo': contact.loanContactType === loanContactType.LoanOfficer}">
              <div class="name">{{ contact.name }}</div>
              <div class="role" *ngIf="contact.title && contact.loanContactType !== loanContactType.LoanOfficer">{{ contact.title }}</div>
              <div class="role" *ngIf="contact.loanContactType === loanContactType.LoanOfficer">{{ contact.title || 'Loan Officer' }}</div>
              <div class="referenceId" *ngIf="contact.nmlsNumber && contact.loanContactType === loanContactType.LoanOfficer">NMLS# {{ contact.nmlsNumber }}</div>
              <div class="address" *ngIf="loAddress && contact.loanContactType === loanContactType.LoanOfficer">
                <a href="#" (click)="openAddress(loAddress)" class="color-default-link" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                <span class="street d-block" *ngIf="loAddress.street">
                  {{ loAddress.street }} {{ loAddress.street2 }}
                </span>
                <span class="city-state-zip d-block" *ngIf="loAddress.city">
                  {{ loAddress.city }}, {{ loAddress.state }} {{ loAddress.zip }}
                </span>
                </a>
              </div>
              <a class="phone" [href]="'tel:' + (getPhoneNumberForContact(contact) | phoneNumber: true)" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                {{getPhoneNumberForContact(contact) | phoneNumber}}
              </a>
              <div class="contact-methods mt-2">
                <div class="email-button" *ngIf="contact.email">
                  <a class="color-default-link" [href]="'mailto:' + contact.email" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                    <span class="email"><i class="fa fa-envelope-o"></i></span>
                    <span class="email-text">Email<br />Me</span>
                  </a>
                </div>
                <div class="email-button sms-button d-lg-none" *ngIf="contact.phone">
                  <a class="color-default-link" [href]="('sms:' + (contact.phone | phoneNumber: true) | safeHtml:'url')" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                    <span class="email"><i class="fa fa-comments-o"></i></span>
                    <span class="email-text">SMS<br />Text</span>
                  </a>
                </div>
              </div>
              <div class="contact-touch-actions" *ngIf="contact.loanContactType === loanContactType.LoanOfficer">
                <div class="email-button" *ngIf="contact.phone">
                  <a class="color-default-link" [href]="'tel:' + ((loAddress.phone||contact.phone) | phoneNumber: true)" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                    <span class="email"><i class="fa fa-mobile"></i></span>
                  </a>
                </div>
                <div class="email-button" *ngIf="contact.email">
                  <a class="color-default-link" [href]="'mailto:' + contact.email" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
                    <span class="email"><i class="fa fa-envelope-o"></i></span>
                  </a>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </ng-container>
        </ng-container>
        <div class="contact-info">
          <div class="name">{{lenderName}}</div>
          <div class="referenceId">NMLS# {{companyNmls}}</div>
          <a class="phone" *ngIf="companyPhone" [href]="'tel:' + (companyPhone | phoneNumber: true)" target="_blank" [attr.tabIndex]="isCommNavOpen ? null : '-1'">
            {{companyPhone | phoneNumber}}
          </a>
        </div>
      </div>
    </mat-card>
  </app-api-state>
</ng-container>
