<ng-container *ngIf="{ routeCurrent: routeCurrent$ | async } as component">
  <div class="section-icon-with-progress text-center" *ngIf="showSectionIcon">
    <div class="expand-indicator-arrow" *ngIf="showExpandArrow">
      <i class="fa fa-chevron-down color-primary"></i>
    </div>
    <div class="icon-container">
      <div class="icon color-primary">
        <i class="cvi cvi-{{ component.routeCurrent }}"></i>
      </div>
    </div>
  </div>

  <ul class="nav nav-main flex-column" *ngIf="showList">
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/tasks"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [attr.aria-current]="component.routeCurrent === 'tasks' ? 'page' : null"
        [tabindex]="isSectionNavOpen ? '0' : '-1'"
      >
        Your Tasks
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/tasks/documents"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [attr.aria-current]="component.routeCurrent === 'secure-docs' ? 'page' : null"
        [tabindex]="isSectionNavOpen ? '0' : '-1'"
      >
        Secure Documents
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        routerLink="/tasks/loan-details"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [attr.aria-current]="component.routeCurrent === 'loan-details' ? 'page' : null"
        [tabindex]="isSectionNavOpen ? '0' : '-1'"
      >
        Loan Information
      </a>
    </li>
  </ul>
</ng-container>
