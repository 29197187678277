<app-header-simple></app-header-simple>

<main class="container" automationid="Page_AuthenticationStatus">
  <div class="row">
    <div class="col col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body py-5 px-md-5">
          <div class="row" *ngIf="authenticationStatus === 'issue'">
            <div class="col-md-6">
              <div class="text-center mb-5">
                <h1>Oops! </h1>
              </div>
              <div class="image-container">
                <img src="/assets/img/register.png" alt="" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-left mb-5">
                <h1>Authentication Issue</h1>
              </div>
              <div class="text-left mb-5" *ngIf="authenticationErrorMessage">
                <div class="alert alert-danger icon" automationid="AuthErrorMessage">
                  {{authenticationErrorMessage}}.
                </div>
              </div>
              <div class="text-left mb-5">
                <div role="alert" class="alert alert-danger icon" automationid="Alert">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  We have encountered an issue while authenticating your account. Please contact your system administrator for assistance&nbsp;{{errorContact}}.
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="authenticationStatus === 'inprogress'">
            <app-loading-graphic [showLoadingMessage]="false">
              <h2>Authentication in progress. Please wait.</h2>
            </app-loading-graphic>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
