import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { HomeBuyingTypeEnum, LoanPurposeTypeEnum } from 'src/app/shared/models';

export const propertyRouting: CvFormBuilder.Routes = {
  guidRouteA: {
    pageGuid: 'guidPageA',
    ruleGroups: [
      {
        routeNext: 'guidRouteC',
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          },
          {
            field: 'loan.homeBuyingType',
            operator: RuleExprOp.NE,
            value: HomeBuyingTypeEnum.GetPreApproved,
          },
        ],
      },
      {
        routeNext: 'guidRouteBB',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          },
          {
            field: 'loan.homeBuyingType',
            operator: RuleExprOp.EQ,
            value: HomeBuyingTypeEnum.GetPreApproved,
          },
        ],
      },
    ],
  },

  // Purchase Path
  guidRouteB: {
    pageGuid: 'guidPageB', // Where is prop located?
    routeNext: 'guidRouteC',
  },

  // Refi Path
  guidRouteBB: {
    pageGuid: 'guidPageBB', // Where are you looking to buy?
    routeNext: 'guidRouteC',
  },

  guidRouteC: {
    pageGuid: 'guidPageC', // Occupancy
    ruleGroups: [
      {
        routeNext: 'guidRouteF',
        rules: [
          {
            field: 'loan.$$custom.nonSpouseCoborrower',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteE',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          },
        ],
      },
      {
        routeNext: 'guidRouteD',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
        ],
      },
    ],
  },

  guidRouteD: {
    pageGuid: 'guidPageD', // What is the original <strong>purchase price</strong> and <strong>date</strong>
    routeNext: 'guidRouteEE',
  },

  guidRouteE: {
    pageGuid: 'guidPageE', // Let's calculate the <strong>loan amount</strong>
    routeNext: 'guidRouteF',
  },

  guidRouteEE: {
    pageGuid: 'guidPageEE', // Let's calculate the <strong>loan amount</strong> you're looking for
    routeNext: 'guidRouteF',
  },

  guidRouteY: {
    pageGuid: 'guidPageY', // Please <strong>contact</strong> your loan officer
    routeNext: 'guidRouteZ',
  },

  guidRouteF: {
    pageGuid: 'guidPageF',
    ruleGroups: [
      {
        routeNext: 'guidRouteFF',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteFF: {
    pageGuid: 'guidPageFF',
    routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
