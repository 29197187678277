import { Routes } from '@angular/router';
import {
  NoContentComponent,
  LoginComponent,
  MfaComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  ActivationComponent,
  BrokerComponent,
  MyLoansComponent,
  MyAccountComponent,
  AuthenticationStatusComponent,
} from '$routes'; // HomeComponent,

import { LayoutMainComponent } from '$components';
import { AuthGuard } from '$shared';
import { Form1003Guard } from './shared/guards/form1003.guard';
import { ROUTES_REDIRECTS } from './app.routes-redirects';
import { RegisterGuard } from './shared/guards/register.guard';
import { SectionsConfigComponent } from './sections-config/sections-config.component';
import { DeveloperGuard } from './shared/guards/developer.guard';

export const ROUTES: Routes = [
  // Add redirects to forward user to correct route
  ...ROUTES_REDIRECTS,
  // Routes without masterpage or that do not need to be authenticated need to go first
  {
    path: 'activation',
    component: ActivationComponent,
    data: { title: 'Activation' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Please Log In', disableAutoTrack: true },
  },
  {
    path: 'mfa',
    component: MfaComponent,
    data: { title: 'Multi-Factor Auth' },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Create An Account', disableAutoTrack: true },
    canActivate: [RegisterGuard]
  },
  {
    path: 'register/:leadId',
    component: RegisterComponent,
    data: { title: 'Create An Account', disableAutoTrack: true },
    canActivate: [RegisterGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'broker-loanapp',
    component: BrokerComponent,
    data: { title: 'Broker' },
  },

  {
    path: 'authentication-issue',
    component: AuthenticationStatusComponent,
    data: { title: 'Authentication issue', authenticationStatus: 'issue' },
  },

  {
    path: 'authentication-inprogress',
    component: AuthenticationStatusComponent,
    data: { title: 'Authentication in progress', authenticationStatus: 'inprogress' },
  },

  // Routes that use LayoutMainComponent go here
  // canActivate with AuthGuard determines if this is an authenticated only route
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'base',
        pathMatch: 'full',
        loadChildren: './routes/base/base.module#BaseModule',
        canActivate: [AuthGuard],
      },

      {
        path: 'admin',
        loadChildren: './routes/admin/admin.module#AdminModule',
        canActivate: [AuthGuard],
      },

      {
        path: 'application',
        loadChildren: './routes/application/application.module#ApplicationRouteModule',
        canActivate: [AuthGuard, Form1003Guard],
        data: { disableAutoTrack: true },
      },

      {
        path: 'tasks',
        loadChildren: './routes/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
      },

      {
        path: 'my-loans',
        component: MyLoansComponent,
        data: { title: 'My Loans' },
        canActivate: [AuthGuard],
      },

      {
        path: 'my-account',
        component: MyAccountComponent,
        data: { title: 'My Account' },
        canActivate: [AuthGuard],
      },

      {
        path: 'config/:version',
        component: SectionsConfigComponent,
        canActivate: [DeveloperGuard],
      },
      {
        path: 'config',
        redirectTo: 'config/2020',
      },

      // Empty path string for homepage ('') needs to be LAST otherwise it catches all other routes
      {
        path: '',
        pathMatch: 'full',
        loadChildren: './routes/home/home.module#HomeModule',
        canActivate: [AuthGuard],
      },

      {
        path: '**',
        component: NoContentComponent,
        data: { title: 'Page Not Found' },
        canActivate: [AuthGuard],
      },
    ],
  },
];
