import { Component, OnInit } from '@angular/core';
import { UIStoreService } from '$ui';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-layout-main',
  styleUrls: ['./layout-main.component.scss'],
  templateUrl: './layout-main.component.html',
})
export class LayoutMainComponent implements OnInit {

  public shouldOverlap$ = this.ui.select.config$.pipe(
    map(config => {
      return config['clover.global.banner.overlap']
        && config['clover.global.banner.overlap'].value
        || false;
    })
  );
  
  constructor(private ui: UIStoreService) {}

  ngOnInit() {  }
}
