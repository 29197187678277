import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const demographicsRouting: CvFormBuilder.Routes = {
  // contact info
  guidRouteA: {
    pageGuid: 'guidPageA',
    ruleGroups: [
      {
        routeNext: 'guidRouteBPrimary',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan', // isSpouse
            operator: RuleExprOp.EQ,
            value: false,
          },
        ],
      },
    ],
  },

  // contact info
  guidRouteB: {
    pageGuid: 'guidPageB',
    routeNext: 'guidRouteC',
  },

  // contact info
  guidRouteC: {
    pageGuid: 'guidPageC',
    routeNext: 'guidRouteD',
  },

  // contact info
  guidRouteD: {
    pageGuid: 'guidPageD',
    sectionComplete: true,
    // routeNext: 'guidRouteZ',
  },

  // contact info
  guidRouteBPrimary: {
    pageGuid: 'guidPageBPrimary',
    routeNext: 'guidRouteCPrimary',
  },

  // contact info
  guidRouteCPrimary: {
    pageGuid: 'guidPageCPrimary',
    routeNext: 'guidRouteDPrimary',
  },

  // contact info
  guidRouteDPrimary: {
    pageGuid: 'guidPageDPrimary',
    routeNext: 'guidRouteBSecondary',
  },

  // contact info
  guidRouteBSecondary: {
    pageGuid: 'guidPageBSecondary',
    routeNext: 'guidRouteCSecondary',
  },

  // contact info
  guidRouteCSecondary: {
    pageGuid: 'guidPageCSecondary',
    routeNext: 'guidRouteDSecondary',
  },

  // contact info
  guidRouteDSecondary: {
    pageGuid: 'guidPageDSecondary',
    sectionComplete: true,
    // routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
