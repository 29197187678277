import { mapRouting, mapSectionPageContents, mapPages } from '../form-builder.utils';
import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

function applyCustomizations(sections: CvFormBuilder.Section[]): CvFormBuilder.Section[] {
  sections = mapRouting(sections, (route, sectionId, pageId) => {
    switch (sectionId) {
      case 'loan-purpose':
        return removeSignedPurchaseAgreementPage(route, pageId);
      default:
        return route;
    }
  });

  return mapPages(sections, (page, [sectionId, pageId]) => {
    switch (sectionId) {
      case 'property':
        return applyLoanPurposeCustomizations(page, pageId);
      default:
        return page;
    }
  });
}

function removeSignedPurchaseAgreementPage(route: CvFormBuilder.Route, pageId: string): CvFormBuilder.Route {
  if (pageId === 'purchase-process') {
    return <CvFormBuilder.Route>{
      pageGuid: 'guidPageC',
      pageId: 'purchase-process',
      routeNext: 'guidRouteC3',
    };
  }
  return route;
}



function applyLoanPurposeCustomizations(page: CvFormBuilder.Page, pageId: string): CvFormBuilder.Page {
  switch (pageId) {
    case 'property-located':
      return mapSectionPageContents(page, (c: any) => {
        if (c.id === 'purchase' && c.featureId === 'address-autocomplete') {
          return {
            ...c,
            visible: {
              rules: [
                {
                  field: 'loan.loanPurposeType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                }
              ]
            }
          }
        }
        if (c.id === 'purchase' && c.field === 'loan.$$custom.loan.addressSubject.unitNumber') {
          return {
            ...c,
            visible: {
              rules: [
                {
                  field: 'loan.loanPurposeType',
                  operator: RuleExprOp.EQ,
                  value: 1,
                }
              ]
            }
          }
        }
        return c;
      });
    default:
      return page;
  }
}

export default applyCustomizations;
