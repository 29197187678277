import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EconsentResult } from '../../../shared/services/econsent.service';
import { IBorrowerViewModel } from 'src/app/shared/models';

interface ModalInputData {
  step?: number;
  forcedBorrower?: IBorrowerViewModel;
}

interface CloseModalResponse {
  declined?: boolean;
  approved?: boolean;
  updatedBorrower?: IBorrowerViewModel;
}

@Component({
  selector: 'app-econsent-modal',
  templateUrl: './econsent-modal.component.html',
  styleUrls: ['./econsent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EconsentModalComponent implements OnInit {

  /** Used in forcedBorrower mode, this determines which step/screen is being displayed */
  step = 1;
  /** Used in forcedBorrower mode, this determines which borrower is being displayed */
  borrower: IBorrowerViewModel;
  /** Save list of borrowers to decline if borrower confirms */
  borrowersToDecline: IBorrowerViewModel[];
  /** Show errors in the UI */
  error: string;
  /** Determine if we force only showing one borrower in the form */
  isForcedSingleBorrowerMode = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ModalInputData,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.data) {
      // Set step
      if (this.data.step) {
        this.step = this.data.step;
      }
      // Set borrower and forced mode
      if (this.data.forcedBorrower) {
        this.borrower = this.data.forcedBorrower;
        this.isForcedSingleBorrowerMode = true;
      }
    }
  }

  /**
   * Handle click events on the cancel button
   */
  onCancel() {
    // If we're in forced borrower mode, go back to step 1
    if (this.isForcedSingleBorrowerMode) {
      this.step = 1;
      this.ref.markForCheck();
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Handle declining eConsent
   */
  confirmDecline(): void {
    this.dialogRef.close(<CloseModalResponse>{declined: true});
  }

  /**
   * Handle eConsent form results
   */
  handleEconsentResult(eConsentResult: EconsentResult): void {

    if (eConsentResult.borrowersToDecline) {
      // If result is to decline borrowers, show confirmation first
      this.step = 2;
      this.borrowersToDecline = eConsentResult.borrowersToDecline;
      this.ref.markForCheck();
      return;

    } else if (eConsentResult.wasEconsentSuccessful) {
      // If successfully saved, go to dashboard
      this.dialogRef.close(<CloseModalResponse>{approved: true});

    } else if (eConsentResult.wasEconsentSuccessful === false) {
      // Handle save errors
      this.error = `An error occurred when saving. Please try again.`;
      this.ref.markForCheck();
    }
  }

}
