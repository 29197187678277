import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorModule } from '../../vendor.module';
import { PhoneNumberPipe, SharedModule } from '$shared';

import { StepperComponent } from './stepper/stepper.component';
import { StepDirective } from './stepper/directives/step.directive';
import { StepBodyCellDirective } from './stepper/directives/step-body.directive';
import { StepLabelDirective } from './stepper/directives/step-label.directive';
import { EnumToStringPipe } from 'src/app/shared/pipes/enum-to-string.pipe';

const Components = [
  StepperComponent,
  StepDirective,
  StepBodyCellDirective,
  StepLabelDirective,
];

/**
 * UI library abstractions, in this case for materials design
 */
@NgModule({
  imports: [CommonModule, VendorModule, SharedModule],
  declarations: [...Components],
  exports: [...Components],
  providers: [PhoneNumberPipe, EnumToStringPipe],
})
export class UILibModule {}
