
   <div automationid="Section_Header" class="page-title padding-10">
    <div class="inline" id="modal-title">{{data.title}} </div>
      <div class="inline">
      <button type="button" class="close" tabindex="3" (click)="onExit()" aria-label="Close" mat-dialog-close automationid="BtnClose">
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
   </div>

  <div class="modal-body" id="modal-body" focusContain automationid="Section_ModalBody">
  <p>
    We noticed you <strong>changed</strong> the <strong>subject property location.</strong>
      In order to save this change, you will need to return to the screen and select the Next button.
  </p>
  <p>
    Would you like to return to the screen so that you can save the change, or continue and discard the change?
  </p>
 </div>

<div class="modal-footer justify-content-between justify-content-center" automationid="Section_Footer">
  <div class="row col-12">
    <div class="col-6">
      <button type="button"
              mat-flat-button
              color="primary-outline"
              tabindex="2"
              [mat-dialog-close]="false"
              automationid="BtnDiscard"
              (click)="onCancel()"
              class="col-12 btn btn-outline-secondary"
              style="margin-bottom: 5%">
        {{data.buttonDiscard}}
      </button>
    </div>
    <div class="col-6">
      <button type="button"
              mat-flat-button
              color="accent"
              tabindex="1"
              [mat-dialog-close]="false"
              automationid="BtnReturn"
              (click)="onConfirm()"
              class="col-12"
              style="margin-left: 5px; margin-bottom: 5%">
        {{data.buttonConfirm}}
      </button>
    </div>
  </div>

</div>

