/** String enum of store properties/primary keys */
export enum ApiProps {
  // Example
  users = 'users',
  bnl = 'bnl',
  documents = 'documents',
  activeLoanSnapshots = 'activeLoanSnapshots',
  loanContacts = 'loanContacts',
  currentCompanyProfile = 'currentCompanyProfile',
  megaLoan = 'megaLoan',
  broker = 'broker',
  appState = 'appState',
  config1003 = 'config1003',
  client1003Config = 'client1003Config'
}
