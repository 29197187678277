import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { UIStoreService } from '$ui';
import { ModalsService } from '$modals';

@Injectable({
  providedIn: 'root',
})
export class TermsAndPrivacyService {
  // config API
  public config$ = this.ui.select.config$;
  // Values from config API
  privacyUrl: string;
  privacyHtml: string;
  termsUrl: string;
  termsHtml: string;
  thirdPartyUrl: string;
  thirdPartyHtml: string;


  constructor(
    private ui: UIStoreService,
    private modals: ModalsService,
  ) {
    this.setValues();
  }

  /**
   * Create variables from WordPress configuration
   */
  setValues(): void {
    this.config$.pipe(take(2)).subscribe((config) => {
      this.privacyUrl = config 
        && ((config['clover.editor.footer.privacypolicyurl'] && config['clover.editor.footer.privacypolicyurl'].value)
            || (config['Legal.PrivacyPolicy.URL.CS'] && config['Legal.PrivacyPolicy.URL.CS'].value))
        || '#';
      this.privacyHtml = config
        && config['Legal.PrivacyPolicy.Manual.CS']
        && config['Legal.PrivacyPolicy.Manual.CS'].value
        || null;
      this.termsUrl = config
        && ((config['clover.editor.footer.termsofuseurl'] && config['clover.editor.footer.termsofuseurl'].value)
            || (config['Legal.TermsOfUse.URL.CS'] && config['Legal.TermsOfUse.URL.CS'].value))
        || '#';
      this.termsHtml = config
        && config['Legal.TermsOfUse.Manual.CS']
        && config['Legal.TermsOfUse.Manual.CS'].value
        || null;
      this.thirdPartyUrl = config
        && config['Legal.ThirdPartySuppliers.URL.CS']
        && config['Legal.ThirdPartySuppliers.URL.CS'].value
        || '#';
      this.thirdPartyHtml = config
        && config['Legal.ThirdPartySuppliers.Manual.CS']
        && config['Legal.ThirdPartySuppliers.Manual.CS'].value
        || null;
    });
  }

  /**
   * View terms of use in modal or new window
   */
  viewTermsOfUse(): void {
    return this.openModalOrNewWindow(this.termsHtml, 'Terms of Use', this.termsUrl);
  }

  /**
   * View Privacy Policy in modal or new window
   */
  viewPrivacyPolicy(): void {
    return this.openModalOrNewWindow(this.privacyHtml, 'Privacy Policy', this.privacyUrl);
  }

  /**
   * View Third Party Suppliers in modal or new window
   */
  viewThirdPartySuppliers(): void {
    return this.openModalOrNewWindow(this.thirdPartyHtml, 'Third Party Suppliers', this.thirdPartyUrl);
  }

  /**
   * Generic function for opening a new window or modal
   * @param modalHtml HTML that will appear inside the modal
   * @param modalTitle The title of the modal
   * @param newWindowUrl The URL for the new window
   */
  openModalOrNewWindow(modalHtml: string, modalTitle: string, newWindowUrl: string): void {
    if (modalHtml) {
      this.modals.open('HintModalComponent', false, 'lg', {
        title: modalTitle,
        body: modalHtml,
      });
    } else {
      window.open(newWindowUrl, '_blank');
    }
    return;
  }
}
