import { Injectable } from '@angular/core';
import { AppSettings } from '../app.settings';
import { UIStoreService } from '$ui';
import { Subscription } from 'rxjs';
import { ICPOSAppConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StyleGeneratorService {
  /** Unique ID to add to the style tag in the header. Used to remove when updating */
  private styleTagId = 'appStyles';
  /** Holds the currently generated style string, used for checking for changes */
  private stylesCurrent: string;
  /** Sub to UI store changes */
  private sub: Subscription;

  constructor(private settings: AppSettings, private ui: UIStoreService) {}

  /**
   * Manage style changes from the webapi
   * TODO: Better checking for changes to avoid an occasional double update
   */
  public start() {
    // When styling changes come through the UI store
    this.sub = this.ui.select.config$.subscribe(configs => {
      this.settings.config = configs;
      this.stylingCreate(configs);
    });
  }

  /**
   * End updates
   */
  public end() {
    this.sub.unsubscribe();
  }

  /**
   * Create the css text string to insert into the style block
   * @param styling
   */
  private stylingCreate(configs: Record<string, ICPOSAppConfig>) {
    // console.log('stylingCreate', configs);

    let styles = ``;
    if (configs['clover.global.background.color']) {
      styles += `body{background-color: ${configs['clover.global.background.color'].value};}`;
    }
    if (configs['clover.global.background.image'] && configs['clover.global.background.image'].value) {
      styles += `body{background-image: url('${configs['clover.global.background.image'].value}');}`;
    }
    if (configs['clover.global.font.family']) {
      styles += `
body,
.btn {
  font-family: ${configs['clover.global.font.family'].value};
}
      `;
    }

    /**************************
       Static Color
    **************************/
    if (configs['clover.global.color.static']) {
      let staticActionColor = configs['clover.global.color.static'].value;
      const stringWithValuesToReplace = `
/**********************
 Global
 *********************/
.color-static {
  color: staticActionColorReplace !important;
}
.border-color-static {
  border-color: staticActionColorReplace !important;
}
.background-color-static {
  background-color: staticActionColorReplace !important;
}
.mat-expansion-panel .mat-expansion-panel-header {
  border-color: staticActionColorReplace;
}


/**********************
 Milestones
*********************/
app-home .application-nav li.active:after {
  background-color: staticActionColorReplace !important;
}
app-home .application-nav li.active h2 {
  color: staticActionColorReplace;
}

/**********************
  1003 / Application
*********************/
.section-label {
  color: staticActionColorReplace;
}
.section-title {
  color: inherit !important;
}
app-page .title-sub {
  color: staticActionColorReplace;
}
.group-icon {
  color: staticActionColorReplace;
}
.summary-page .heading {
  color: staticActionColorReplace;
}
app-page h1 {
  color: staticActionColorReplace;
  border-bottom-color: staticActionColorReplace !important;
}

/**********************
 Dashboard
*********************/
app-tasks .task .task-icon,
app-tasks .task .task-name {
  color: staticActionColorReplace;
}

#dashboard-container .page-title {
  color: staticActionColorReplace !important;
  border-color: staticActionColorReplace !important;
}
      `;
      styles += stringWithValuesToReplace.replace(/staticActionColorReplace/g, staticActionColor);
    }

    /**************************
       Primary Action Color
    **************************/
    if (configs['clover.global.color.primary']) {
      const primaryActionColor = configs['clover.global.color.primary'].value;
      const stringWithValuesToReplace = `
/**********************
 Global
 *********************/
.color-primary {
  color: primaryActionColorStringReplace !important;
}
.border-color-primary {
  border-color: primaryActionColorStringReplace !important;
}
.background-color-primary {
  background-color: primaryActionColorStringReplace !important;
}

/**********************
 1003 / Application
 *********************/
app-page .nav-buttons button:after {
  background-color: primaryActionColorStringReplace;
}

/**********************
 Application left sidebar
 *********************/
app-application-nav .application-nav li.active:after {
  border-color: primaryActionColorStringReplace !important;
  background-color: primaryActionColorStringReplace !important;
}
app-application-nav .section-icon-with-progress .progress-container canvas {
  border-left-color: primaryActionColorStringReplace !important;
}

/**********************
 Dashboard
 *********************/
#dashboard-container .content-button-footer button:after {
  background-color: primaryActionColorStringReplace !important;
}

app-tasks .econsent a {
  color: primaryActionColorStringReplace;
}
app-documents .hide-button {
  color: primaryActionColorStringReplace;
}
app-everify-or-upload a,
app-everify-or-upload .task-name {
  color: primaryActionColorStringReplace;
}
app-documents .document .name,
app-documents .document .download-button {
  color: primaryActionColorStringReplace;
}
app-esign-intro .pagination-buttons button.active {
  background-color: primaryActionColorStringReplace !important;
}

app-econsent-modal .modal-footer button.mat-primary-outline {
  color: primaryActionColorStringReplace;
  border-color: primaryActionColorStringReplace;
}

app-appraisal-order .mat-step-header .mat-step-label {
  color: primaryActionColorStringReplace !important;
}
app-appraisal-order .mat-step-header .mat-step-icon {
  color: primaryActionColorStringReplace;
  border-color: primaryActionColorStringReplace;  
}
app-appraisal-order .mat-step-header .mat-step-icon.mat-step-icon-selected {
  background-color: primaryActionColorStringReplace;
}

/**********************
 Dashboard left sidebar
 *********************/
app-dashboard-nav .nav.nav-main .nav-link.active {
  border-left-color: primaryActionColorStringReplace !important;
}
app-application-nav .section-icon{
  color: primaryActionColorStringReplace !important;
}

/**********************
 Dashboard right sidebar
 *********************/
app-sidebar-right .contact-methods button {
  color: primaryActionColorStringReplace;
}

/**********************
 Ride sidebar
 *********************/
app-sidebar-right .main-contact .image img {
  border-color: primaryActionColorStringReplace !important;
}

/**********************
 Site Footer
 *********************/
app-footer {
  background-color: primaryActionColorStringReplace;
}

/**********************
 Login / Register / Activate
 *********************/
app-forgot-password a,
app-activation a,
app-register a,
app-login a {
  color: primaryActionColorStringReplace;
}
app-forgot-password button.mat-blue-outline {
  border-color: primaryActionColorStringReplace !important;
  color: primaryActionColorStringReplace;
}

/**********************
 Material Design
 *********************/
.mat-flat-button.mat-accent, .btn.btm-primary{
  background-color: primaryActionColorStringReplace;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: primaryActionColorStringReplace;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: primaryActionColorStringReplace;
}
.mat-badge-content {
  background-color: primaryActionColorStringReplace;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: primaryActionColorStringReplace;
}
.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: primaryActionColorStringReplace;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: primaryActionColorStringReplace;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: primaryActionColorStringReplace;
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: primaryActionColorStringReplace;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: primaryActionColorStringReplace;
}
.mat-calendar-body-selected {
  background-color: primaryActionColorStringReplace;
}
.mat-datepicker-toggle-active {
  color: primaryActionColorStringReplace;
}
.mat-form-field.mat-focused.ng-valid .mat-form-field-label {
  color: primaryActionColorStringReplace;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: primaryActionColorStringReplace;
}
.mat-icon.mat-primary {
  color: primaryActionColorStringReplace;
}
.mat-input-element {
  caret-color: primaryActionColorStringReplace !important;
}
.mat-progress-bar-background {
  fill: primaryActionColorStringReplace !important;
}
.mat-progress-bar-buffer {
  background-color: primaryActionColorStringReplace;
}
.mat-progress-bar-fill::after {
  background-color: primaryActionColorStringReplace;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: primaryActionColorStringReplace !important;
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: primaryActionColorStringReplace !important;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: primaryActionColorStringReplace;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: primaryActionColorStringReplace;
}
.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: primaryActionColorStringReplace;
}
.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: primaryActionColorStringReplace;
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: primaryActionColorStringReplace;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: primaryActionColorStringReplace;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: primaryActionColorStringReplace;
}
.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: primaryActionColorStringReplace;
}
.mat-toolbar.mat-primary {
  background-color: primaryActionColorStringReplace;
}
.mat-form-field.mat-form-field-can-float.mat-form-field-should-float.ng-valid .mat-form-field-label {
  color: primaryActionColorStringReplace;
}
.mat-form-field.mat-form-field.ng-valid .mat-placeholder-required.mat-form-field-required-marker {
  color: primaryActionColorStringReplace;
}

/**********************
 Buttons / Links
 *********************/
a,
a:not([href]):not([tabindex]){
  color: primaryActionColorStringReplace;
}
a:hover,
a:not([href]):not([tabindex]):hover {
  color: primaryActionColorStringReplace;
}
.btn.btn-secondary {
  background-color: primaryActionColorStringReplace;
}
.btn.btn-secondary:hover:not([disabled]) {
  color: primaryActionColorStringReplace;
  border-color: primaryActionColorStringReplace !important;
}
.mat-button-toggle-group-wrapper .mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked {
  color: primaryActionColorStringReplace;
}
.mat-button-toggle.mat-button-toggle-checked {
  color: primaryActionColorStringReplace;
}
.dropdown .dropdown-item.active,
.dropdown-menu .dropdown-item.active {
  background-color: primaryActionColorStringReplace;
}

/**********************
 Buttons / Links
 *********************/
.btn.btn-outline-secondary {
  color: primaryActionColorStringReplace;
  border-color: primaryActionColorStringReplace !important;
}
.btn.btn-outline-secondary:hover:not([disabled]) {
  background-color: primaryActionColorStringReplace;
  color: #fff !important;
}
app-page .btn.btn-outline-secondary:hover:not([disabled]) {
  background: transparent !important;
  color: inherit !important;
}
.btn.btn-outline-secondary.selected {
  background-color: primaryActionColorStringReplace;
  color: white !important;
}

/**********************
 Modals
 *********************/
#modal .modal-header {
  border-bottom-color: primaryActionColorStringReplace !important;
}
#modal .modal-title {
  color: primaryActionColorStringReplace;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: primaryActionColorStringReplace;
}

/**********************
 Loader
 *********************/
svg .fill-primary {fill: primaryActionColorStringReplace;}
svg .stroke-primary {stroke: primaryActionColorStringReplace;}
svg .stop-color-primary {stop-color: primaryActionColorStringReplace;}
        `;
      styles += stringWithValuesToReplace.replace(/primaryActionColorStringReplace/g, primaryActionColor);
    }

    /**************************
      Secondary Action Color
    **************************/
    if (configs['clover.global.color.secondary']) {
      const secondaryActionColor = configs['clover.global.color.secondary'].value;
      const stringWithValuesToReplace = `
/**********************
 Milestones
 *********************/
app-home .application-nav li.complete:after {
  background-color: secondaryActionColorReplaceString !important;
}

/**********************
 Application left sidebar
 *********************/
//.application-nav[_ngcontent-c20] li.complete[_ngcontent-c20]:after {
//  background-color: secondaryActionColorReplaceString;
//}
app-application-nav .application-nav li.complete:after {
  color: secondaryActionColorReplaceString;
  //background-color: #fff;
}

/**********************
 Dashboard
 *********************/
app-bnl-file-upload .upload-button {
  background-color: secondaryActionColorReplaceString;
}

/**********************
 Material Design
 *********************/
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: secondaryActionColorReplaceString;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: secondaryActionColorReplaceString;
}
.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: secondaryActionColorReplaceString;
}
.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: secondaryActionColorReplaceString;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: secondaryActionColorReplaceString;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: secondaryActionColorReplaceString;
  //color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: secondaryActionColorReplaceString;
  //color: white;
}
.mat-datepicker-toggle-active.mat-accent {
  color: secondaryActionColorReplaceString;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: secondaryActionColorReplaceString;
}
.mat-focused .mat-form-field-required-marker {
  color: secondaryActionColorReplaceString;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: secondaryActionColorReplaceString;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: secondaryActionColorReplaceString;
}
.mat-icon.mat-accent {
  color: secondaryActionColorReplaceString;
}
.mat-accent .mat-input-element {
  caret-color: secondaryActionColorReplaceString !important;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: secondaryActionColorReplaceString !important;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: secondaryActionColorReplaceString;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: secondaryActionColorReplaceString;
}
.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: secondaryActionColorReplaceString !important;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: secondaryActionColorReplaceString;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: secondaryActionColorReplaceString;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: secondaryActionColorReplaceString;
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: secondaryActionColorReplaceString;
}
.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: secondaryActionColorReplaceString;
}
.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: secondaryActionColorReplaceString;
}
.mat-toolbar.mat-accent {
  background-color: secondaryActionColorReplaceString;
  //color: white;
}
.mat-simple-snackbar-action {
  color: secondaryActionColorReplaceString;
}
label.dragNDrop:hover,
label.dragNDrop.hover {
  outline-color: secondaryActionColorReplaceString !important;
  color: secondaryActionColorReplaceString;
}
.form-control:hover,
.form-control:focus,
.form-control:active {
  border-color: secondaryActionColorReplaceString !important;
}
ngb-accordion > .card > .card-header > a {
  display: block;
  padding: 0.75rem 1.25rem;
  color: secondaryActionColorReplaceString;
}
.btn.btn-primary {
  //color: #fff;
  background-color: secondaryActionColorReplaceString;
}
.btn.btn-primary:hover:not([disabled]) {
  //background: #fff;
  color: secondaryActionColorReplaceString;
  border: 1px solid secondaryActionColorReplaceString !important;
}
.btn.btn-outline-primary {
  color: secondaryActionColorReplaceString;
  //background: #fff;
  border: 1px solid secondaryActionColorReplaceString !important;
}
.btn.btn-outline-primary:hover:not([disabled]) {
  //color: #fff;
  background-color: secondaryActionColorReplaceString;
  border: 1px solid secondaryActionColorReplaceString !important;
}
body .mat-button,
body .mat-raised-button,
body .mat-stroked-button,
body .mat-icon-button {
  color: secondaryActionColorReplaceString;
}
body .mat-button:after,
body .mat-raised-button:after,
body .mat-stroked-button:after,
body .mat-icon-button:after {
  background-color: secondaryActionColorReplaceString;
}
body .mat-button.mat-reverse,
body .mat-button.mat-flat-button,
body .mat-raised-button.mat-reverse,
body .mat-raised-button.mat-flat-button,
body .mat-stroked-button.mat-reverse,
body .mat-stroked-button.mat-flat-button,
body .mat-icon-button.mat-reverse,
body .mat-icon-button.mat-flat-button {
  background-color: secondaryActionColorReplaceString;
}
body .mat-button.mat-reverse:hover .mat-button-wrapper,
body .mat-button.mat-flat-button:hover .mat-button-wrapper,
body .mat-raised-button.mat-reverse:hover .mat-button-wrapper,
body .mat-raised-button.mat-flat-button:hover .mat-button-wrapper,
body .mat-stroked-button.mat-reverse:hover .mat-button-wrapper,
body .mat-stroked-button.mat-flat-button:hover .mat-button-wrapper,
body .mat-icon-button.mat-reverse:hover .mat-button-wrapper,
body .mat-icon-button.mat-flat-button:hover .mat-button-wrapper {
  color: secondaryActionColorReplaceString;
}
body .mat-button.mat-reverse.mat-stroked-button .mat-button-wrapper,
body .mat-button.mat-flat-button.mat-stroked-button .mat-button-wrapper,
body .mat-raised-button.mat-reverse.mat-stroked-button .mat-button-wrapper,
body .mat-raised-button.mat-flat-button.mat-stroked-button .mat-button-wrapper,
body .mat-stroked-button.mat-reverse.mat-stroked-button .mat-button-wrapper,
body .mat-stroked-button.mat-flat-button.mat-stroked-button .mat-button-wrapper,
body .mat-icon-button.mat-reverse.mat-stroked-button .mat-button-wrapper,
body .mat-icon-button.mat-flat-button.mat-stroked-button .mat-button-wrapper {
  color: secondaryActionColorReplaceString;
}
.mat-tab-group.mat-primary .mat-tab-label {
  color: secondaryActionColorReplaceString;
}
      `;
      styles += stringWithValuesToReplace.replace(/secondaryActionColorReplaceString/g, secondaryActionColor);
    }

    /**************************
       Default Link Color
    **************************/
    if (configs['clover.global.color.link']) {
      const defaultLinkColor = configs['clover.global.color.link'].value;
      const stringWithValuesToReplace = `
/**********************
 Global
 *********************/
.summary-page .heading .edit a,
app-tasks .misc-task .btn-upload,
app-sidebar-right .phone {
  color: defaultLinkColorStringReplace;
}
.color-default-link {
  color: defaultLinkColorStringReplace !important;
}
      `;
      styles += stringWithValuesToReplace.replace(/defaultLinkColorStringReplace/g, defaultLinkColor);
    }

    /**************************
       Default Input Color
    **************************/
    if (configs['clover.global.color.input']) {
      const defaultInputColor = configs['clover.global.color.input'].value;
      const stringWithValuesToReplace = `
/**********************
 Global
 *********************/
.icon-group .icon-group-btn:before,
.mat-button-toggle-checked .mat-radio-outer-circle,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: defaultInputColorStringReplace !important;
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.icon-group .icon-group-btn.icon-group-btn-active:after,
.mat-form-field.mat-focused.ng-valid .mat-form-field-ripple,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-button-toggle-checked .mat-radio-inner-circle,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: defaultInputColorStringReplace;
}
.btn.btn-yes-no.selected {
  background-color: defaultInputColorStringReplace;
  border-color: defaultInputColorStringReplace !important;
}
      `;
      styles += stringWithValuesToReplace.replace(/defaultInputColorStringReplace/g, defaultInputColor);
    }

    /**************************
      Page Title and Bar Color
    **************************/
    if (configs['clover.global.color.title']) {
      const titleColor = configs['clover.global.color.title'].value;
      styles += `main h1, main  h2{color:${titleColor}!important;border-bottom-color:${titleColor}!important;}`; // Page titles and borders
      styles += `main .nav-buttons button.next{border-top-color:${titleColor}!important;}`; // Next button
      styles += `.main-contact .image img{border-color:${titleColor}!important;}`; // Loan contacts sidebar
      styles += `.application-nav li.active:after{background-color:${titleColor}!important;}`; // Homepage progress icon
      styles += `.nav.nav-main .nav-link.active{border-color:${titleColor}!important;}`;
    }

    /**************************
      Indicator/Badge Color
    **************************/
    if (configs['clover.global.color.badge']) {
      const badgeColor = configs['clover.global.color.badge'].value;
      const stringWithValuesToReplace = `
/**********************
 Milestones
 *********************/
app-home .application-nav li.complete:after {
  background-color: badgeColorReplaceString !important;
}

/**********************
 Application left sidebar
 *********************/
//.application-nav[_ngcontent-c20] li.complete[_ngcontent-c20]:after {
//  background-color: badgeColorReplaceString;
//}
app-application-nav .application-nav li.complete:after {
  color: badgeColorReplaceString;
  //background-color: #fff;
}

/**********************
  Task List
*********************/
.page-title .task-count {
  background-color: badgeColorReplaceString;
}

/**********************
 Material Design
 *********************/
.mat-badge-accent .mat-badge-content {
  background-color: badgeColorReplaceString;
  //color: white;
}
      `;
      styles += stringWithValuesToReplace.replace(/badgeColorReplaceString/g, badgeColor);
    }

    this.stylingLoad(styles);
  }

  /**
   * Take a css string and insert it into the head
   * @param css
   */
  private stylingLoad(css: string) {
    // Get existing style block
    const styleBlock = document.getElementById(this.styleTagId);
    // If the newly created css styling string matches what is already in the header style block, do nothing
    if (styleBlock && this.stylesCurrent && styleBlock.innerHTML === this.stylesCurrent) {
      return;
    }
    // If styleblock does not exist or the new style does not match what is currently in the head, replace it

    // Head ref to head
    const head = document.head || document.getElementsByTagName('head')[0];
    // Create new style tag
    const styleTag = document.createElement('style');

    // If existing style block is found, remove it
    if (styleBlock && styleBlock.remove) {
      styleBlock.remove();
    } else if (styleBlock && styleBlock.parentNode) {
      styleBlock.parentNode.removeChild(styleBlock); // IE11 Fix
    }

    // Add css
    styleTag.appendChild(document.createTextNode(css));
    // Add ID so this block an later be removed
    styleTag.id = this.styleTagId;
    // Append to head
    head.appendChild(styleTag);
  }
}
