import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';

export const assetsRouting: CvFormBuilder.Routes = {
  // contact info
  guidRouteA: {
    pageGuid: 'guidPageA',
    // routeNext: 'guidRouteC',
    /**  */
    ruleGroups: [
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.$$custom.loan.assets[].assetType',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteB',
        rules: [
          {
            field: 'loan.$$custom.loan.assets[].monthlyAmount',
            operator: RuleExprOp.EQ,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.assets[].assetType',
            operator: RuleExprOp.NE,
            value: null,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.loan.assets[].assetType',
            operator: RuleExprOp.GT,
            value: 0,
          },
        ],
      },
    ],
  },

  guidRouteB: {
    pageGuid: 'guidPageB',
    // routeNext: 'guidRouteZ',
    ruleGroups: [
      {
        routeNext: 'guidRouteC2',
        rules: [
          {
            field: 'loan.$$custom.assetType',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          {
            field: 'loan.$$custom.useEVOA',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteC4',
        rules: [
          {
            field: 'loan.$$custom.assetType',
            operator: RuleExprOp.EQ,
            value: 0,
          },
          {
            field: 'loan.$$custom.useEVOA',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteD',
        rules: [
          {
            field: 'loan.$$custom.assetType',
            operator: RuleExprOp.EQ,
            value: 1,
          },
        ],
      },
      {
        routeNext: 'guidRouteE',
        rules: [
          {
            field: 'loan.$$custom.assetType',
            operator: RuleExprOp.EQ,
            value: 2,
          },
        ],
      },
      {
        routeNext: 'guidRouteZ',
        rules: [
          {
            field: 'loan.$$custom.assetType',
            operator: RuleExprOp.EQ,
            value: 3,
          },
        ],
      },
    ]
  },

  guidRouteC2: {
    pageGuid: 'guidPageC2',
    //routeNext: 'guidRouteZ',
    ruleGroups: [
      {
        routeNext: 'guidRouteC3',
        rules: [
          {
            field: 'loan.$$custom.isEVerifyAsset',
            operator: RuleExprOp.EQ,
            value: true,
          },
        ],
      },
      {
        routeNext: 'guidRouteC4',
        rules: [
          {
            field: 'loan.$$custom.isEVerifyAsset',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
    ],
  },

  guidRouteC3: {
    pageGuid: 'guidPageC3',
    routeNext: 'guidRouteZ',
  },

  guidRouteC4: {
    pageGuid: 'guidPageC4',
    routeNext: 'guidRouteZ',
  },

  guidRouteD: {
    pageGuid: 'guidPageD',
    routeNext: 'guidRouteZ',
  },

  guidRouteE: {
    pageGuid: 'guidPageE',
    routeNext: 'guidRouteZ',
  },

  /** SUMMARY */
  guidRouteZ: {
    pageGuid: 'guidPageZ',
    sectionComplete: true,
  },
};
