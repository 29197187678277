import { Injectable } from '@angular/core';
import { AppSettings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { AuthService } from './auth.service';
import { interval } from 'rxjs';
import { SingleUserLockTypeEnum } from '../models';


const REFRESH_TIME: number = 60000;
@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {
  private releaseLockUnsubscribeFunc: () => void;

  constructor(private settings: AppSettings, private http: HttpClient,
    private authService: AuthService) { }
  releaseLock() {
    if (this.settings.userId) {
      const releaseLockUrl = `${this.settings.apiUrl}/api/loan/SingleUserReleaseLock?loanId=${this.settings.loanId}&UserAccountId=${this.settings.userId}`;
      return this.http.post<any>(releaseLockUrl, null);
    } else {
      return of({ success: true });
    }
  }
  refreshLock(lockType: SingleUserLockTypeEnum = SingleUserLockTypeEnum.Exclusive) {
    if (this.settings.userId) {
      const refreshLockUrl = `${this.settings.apiUrl}/api/loan/SingleUserRefreshLock?loanId=${this.settings.loanId}&UserAccountId=${this.settings.userId}&lockType=${lockType}`;
      return this.http.post<any>(refreshLockUrl, null);
    } else {
      return of({ success: true });
    }
  }
  createLock() {
    if (this.settings.userId) {
      const createLockUrl = `${this.settings.apiUrl}/api/loan/SingleUserCreateLock?loanId=${this.settings.loanId}&UserAccountId=${this.settings.userId}`;
      return this.http.post<any>(createLockUrl, null);
    } else {
      return of({ success: true });
    }
  }


  confirmReleaseOnLogOut() {
    if (this.releaseLockUnsubscribeFunc) {
      return;
    }
    this.releaseLockUnsubscribeFunc = this.authService.attachPreLogOutAction(() => {
      this.releaseLockUnsubscribeFunc = null;
      return this.releaseLock();
    });
  }

  public handleLoanRefresh(lockType: SingleUserLockTypeEnum = SingleUserLockTypeEnum.Exclusive) {
    return interval(REFRESH_TIME).subscribe(() => {
      return this.refreshLock(lockType).subscribe();
    });
  }

  public handleLoanCreateLock() { 
    return this.createLock();
  }
}
