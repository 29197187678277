import { ButtonActions, RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSdateTypeEnum, CPOSDisableCustomEnum, CPOSEventsEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const incomePages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Income Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Income',
          sectionTitle: `Let's get some details about your <strong>income</strong>`,
          sectionImage: `/assets/img/application/income.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Select any <strong>income source</strong> you would like to add.',
    titleShow: false,
    pageId: 'income-source',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger padding-bottom-1em">We haven't collected 2 years of income from you so please provide previous income source(s). If you do not have any, then select "I don't have any income" below.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isTwoYearsPeriodMissing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-info">Good news, you have provided 2 years of income. You do not need to provide any prior incomes. Please add any other income that is current.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isTwoYearsPeriodFulfilled',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-center">Please provide <strong>2 years</strong> of your most recent income source(s).</h3>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Income Type',
        errorCustom: 'Selecting an option for income source <strong>is required</strong>.',
        field: 'loan.$$custom.loan.employments[].cvIncomeTypeId', // incomeType
        formFieldType: 'iconGroup',
        // dataField: 'df-91',
        // Hard coding data for now since enums do not match what client wants
        formFieldData: [
          <any>{
            enumValue: 0,
            selected: false,
            text: 'Employed',
            value: 0,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Employed',
            description: '',
            orderValue: 2,
            removed: false,
            label: 'Employment<br>(W-2)',
          },
          <any>{
            enumValue: 1,
            selected: false,
            text: 'Active Military Duty',
            value: 1,
            disabled: false,
            contextFlags: 0,
            stringValue: 'ActiveMilitaryDuty',
            description: '',
            orderValue: 1,
            removed: false,
            label: 'Military Pay',
          },
          <any>{
            enumValue: 2,
            selected: false,
            text: 'Social Security',
            value: 2,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SocialSecurity',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Social Security',
          },
          <any>{
            enumValue: 3,
            selected: false,
            text: 'Retired',
            value: 3,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Retired',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Pension Retirement',
          },

          <any>{
            enumValue: 4,
            selected: false,
            text: 'Self Employed',
            value: 4,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SelfEmployed',
            description: '',
            orderValue: 3,
            removed: false,
            label: 'Self Employed',
          },
          <any>{
            enumValue: 5,
            selected: false,
            text: 'Other/Unemployed',
            value: 5,
            disabled: false,
            contextFlags: 0,
            stringValue: 'OtherUnemployed',
            description: '',
            orderValue: 5,
            removed: false,
            label: 'Other Income',
          },
          <any>{
            enumValue: 99,
            selected: false,
            text: 'No Income',
            value: 99,
            disabled: false,
            contextFlags: 0,
            stringValue: 'NoIncome',
            description: '',
            orderValue: 5,
            removed: false,
            label: `I don't have any income`,
          },
        ],

        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isTwoYearsPeriodMissing',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Income Type',
        errorCustom: 'Selecting an option for income source <strong>is required</strong>.',
        field: 'loan.$$custom.loan.employments[].cvIncomeTypeId', // incomeType
        formFieldType: 'iconGroup',
        // dataField: 'df-91',
        // Hard coding data for now since enums do not match what client wants
        formFieldData: [
          <any>{
            enumValue: 0,
            selected: false,
            text: 'Employed',
            value: 0,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Employed',
            description: '',
            orderValue: 2,
            removed: false,
            label: 'Employment<br>(W-2)',
          },
          <any>{
            enumValue: 1,
            selected: false,
            text: 'Active Military Duty',
            value: 1,
            disabled: false,
            contextFlags: 0,
            stringValue: 'ActiveMilitaryDuty',
            description: '',
            orderValue: 1,
            removed: false,
            label: 'Military Pay',
          },
          <any>{
            enumValue: 2,
            selected: false,
            text: 'Social Security',
            value: 2,
            disabled: true,
            contextFlags: 0,
            stringValue: 'SocialSecurity',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Social Security',
          },
          <any>{
            enumValue: 3,
            selected: false,
            text: 'Retired',
            value: 3,
            disabled: true,
            contextFlags: 0,
            stringValue: 'Retired',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Pension Retirement',
          },

          <any>{
            enumValue: 4,
            selected: false,
            text: 'Self Employed',
            value: 4,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SelfEmployed',
            description: '',
            orderValue: 3,
            removed: false,
            label: 'Self Employed',
          },
          <any>{
            enumValue: 5,
            selected: false,
            text: 'Other/Unemployed',
            value: 5,
            disabled: true,
            contextFlags: 0,
            stringValue: 'OtherUnemployed',
            description: '',
            orderValue: 5,
            removed: false,
            label: 'Other Income',
          },
          <any>{
            enumValue: 99,
            selected: false,
            text: 'No Income',
            value: 99,
            disabled: false,
            contextFlags: 0,
            stringValue: 'NoIncome',
            description: '',
            orderValue: 5,
            removed: false,
            label: `I don't have any income`,
          },
        ],

        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isTwoYearsPeriodMissing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do we collect employment and income information?`,
          body: `
            <p>To check your qualification for loan products, we need to understand how large of a loan you can afford to pay, and income provides an important part of this understanding. In particular, we need to understand your past 2 years of employment and income history.</p>
            <h3>Employment (W-2)</h3>
            <p>You work for an employer earning a salary or hourly wages. In this case, your employer sends you a W-2 form annually to file with your taxes.</p>
            <h3>Military Pay</h3>
            <p>If you serve in the U.S military, you would select this option to describe your military pay.</p>
            <h3>Social Security</h3>
            <p>You may collect a regular Social Security check for retirement. This payment counts as an income source.</p>
            <h3>Retirement / Pension</h3>
            <p>If you may receive a regular payment from a retirement account, 401k or a pension plan, select this option to provide these payments as an income source.</p>
            <h3>Self Employed</h3>
            <p>Select this option to provide income information if you are a 1099 contractor, or if you own your own business or are a partner in a business.</p>
            <h3>Other Income</h3>
            <p>Other sources of income may include (but are not limited to): Child Alimony/Child Support, Disability, Interest/Dividends, Unemployment, and other miscellaneous income.</p>
            <h3>I don't have any income</h3>
            <p>If you do not collect any income at this time, please select the "I don't have any income" option.</p>
          `,
        },
      }, // end
    ],
  }, // end page

  guidPageEVOIEVOESelectA: {
    title: 'Hidden title',
    titleShow: false,
    pageId: 'evoi-evoe-or-manual',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.transactionInfo.borrowers[0].firstName}}, please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.$$custom.borrowerActive.firstName}}, please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure income verification work?`,
          body: `
            <h3>Verify Your Income Securely</h3>
            <p>Instead of gathering and uploading your Pay Stubs, W-2 Forms, and other income related documents, you can utilize our third party vendor, The Work Number to digitally retrieve your income and employment information instantly and securely.</p>
            <p>The Work Number is owned by Equifax.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Income details',
        errorCustom: 'Selecting an option for income verification <strong>is required</strong>.',
        field: 'loan.$$custom.isEVerifyIncomeEmployment',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Income details',
        errorCustom: 'Selecting an option for income verification <strong>is required</strong>.',
        field: 'loan.$$custom.isEVerifyIncomeEmployment',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Income details',
        errorCustom: 'Selecting an option for income verification <strong>is required</strong>.',
        field: 'loan.$$custom.isEVerifyIncomeEmploymentSpouse',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageSocialSecurityBorrower1: {
    title: 'Please provide your <strong>social security number</strong> to automatically find your income history',
    titleShow: false,
    pageId: 'social-security',
    validatorId: CPOSPageValidatorsEnum.ssnEvoiEvoe,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.transactionInfo.borrowers[0].firstName}}, please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.$$custom.borrowerActive.firstName}}, please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure income verification work?`,
          body: `
            <h3>Verify Your Income Securely</h3>
            <p>Instead of gathering and uploading your Pay Stubs, W-2 Forms, and other income related documents, you can utilize our third party vendor, The Work Number to digitally retrieve your income and employment information instantly and securely.</p>
            <p>The Work Number is owned by Equifax.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        errorCustom: 'Selecting third party vendor authorization <strong>is required</strong>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        errorCustom: 'Selecting third party vendor authorization <strong>is required</strong>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[1].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssnSpouse',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.$$custom.borrowerActive.firstName}} {{loan.$$custom.borrowerActive.lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        errorCustom: 'Selecting third party vendor authorization <strong>is required</strong>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.$$custom.borrowerActive.firstName}} {{loan.$$custom.borrowerActive.lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        errorCustom: 'Selecting third party vendor authorization <strong>is required</strong>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.$$custom.borrowerActive.firstName}} {{loan.$$custom.borrowerActive.lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorizedSpouse',
        formFieldType: 'checkbox',
        errorCustom: 'Selecting third party vendor authorization <strong>is required</strong>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
    ],
  }, // end page

  guidPageTWNFeatureBorrower1: {
    title: 'TWN Feature Page Borrower 1',
    titleShow: false,
    pageId: 'twn',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'twn',
        data: {
          borrowerId: 'loan.transactionInfo.borrowers[0].borrowerId',
        },
      },
    ],
  }, // end page

  guidPageTWNFeatureBorrower2: {
    title: 'TWN Feature Page Borrower Active',
    titleShow: false,
    pageId: 'twn-active',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'twn',
        data: {
          borrowerId: 'loan.$$custom.borrowerActive.borrowerId',
        },
      },
    ],
  }, // end page

  guidPageEmpA: {
    title: 'Tell us about your <strong>employer</strong>.',
    titleShow: false,
    pageId: 'employer',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>employer</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>employer</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>employer</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailed',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailedSpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.name',
        placeholder: 'Company Name',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        data: {
          placeholder: 'Company Street Address',
          addressPath: 'loan.$$custom.loan.employments[].employerInfo.address',
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.address',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.city',
        placeholder: 'Company City',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.state',
        placeholder: 'Company State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.zip',
            validators: {},
          },
        ],
      },

      /**
       <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.zip',
        placeholder: 'Company Zip',
        columns: 6,
        formFieldType: 'text',
        minlength: 5,
        maxlength: 5,
        validators: {
          // required: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.phone',
        placeholder: 'Company Phone Number',
        formFieldType: 'phoneNumber',
        validators: {
          minLength: 10,
          // required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageEmpB: {
    title: `Tell us about your job with your employer.`,
    titleShow: false,
    pageId: 'employer-job',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Tell us about your job with <strong> {{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.title',
        placeholder: 'Job Title',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employmentInfoId',
        placeholder: 'INTERNAL HIDDEN',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].employmentInfoId',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].isPrevious',
        placeholder: 'Do you currently work here?',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Yes, I currently work here',
            value: false,
          },
          {
            label: 'No, I previously worked here',
            value: true,
          },
        ],
        disabledCustom: CPOSDisableCustomEnum.checkEmploymentCurrentWorkDisabled,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.incomeEndDate,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
               required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years in this Profession',
        formFieldType: 'number',
        maxlength: 2,
        validators: {
          // required: true,
        },
      },
    ],
  }, // end page

  guidPageEmpC: {
    title: `What is your income at your employer.`,
    titleShow: false,
    pageId: 'employer-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">What is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Base Payment Period',
        errorCustom: 'Selecting an option for income payment period <strong>is required</strong>.',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Salaried',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
          {
            label: 'Hourly',
            value: 7,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Hourly Rate',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseHoursPerWeek',
        placeholder: 'Hours per week',
        formFieldType: 'number',
        min: 0,
        max: 168,
        prefix: '<i class="fa fa-clock-o fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
          min: 0,
          max: 168
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualOvertime',
        placeholder: 'Annual Overtime',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',

        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualBonus',
        placeholder: 'Annual Bonus',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualCommission',
        placeholder: 'Annual Commission',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
    ],
  }, // end page

  guidPageMilitaryA: {
    title: 'What is your <strong>military income</strong>?',
    titleShow: false,
    pageId: 'military-info',
    analyticsId: 'Military Position BLC',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>military position</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>military position</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>military position</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.branch',
        placeholder: 'Branch',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Air Force',
            value: 0,
          },
          {
            label: 'Army',
            value: 1,
          },
          {
            label: 'Marines',
            value: 2,
          },
          {
            label: 'Navy',
            value: 3,
          },
          {
            label: 'Other',
            value: 4,
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.rank',
        placeholder: 'Rank',
        formFieldType: 'text',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employmentInfoId',
        placeholder: 'INTERNAL HIDDEN',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].employmentInfoId',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].isPrevious',
        placeholder: 'Do you currently work here?',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Yes, I currently work here',
            value: false,
          },
          {
            label: 'No, I previously worked here',
            value: true,
          },
        ],
        disabledCustom: CPOSDisableCustomEnum.checkEmploymentCurrentWorkDisabled,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.incomeEndDate,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
              required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years in this Profession',
        formFieldType: 'number',
        maxlength: 2,
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageMilitaryB: {
    title: `What is your <strong>military income</strong>?`,
    titleShow: false,
    pageId: 'military-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">What is your <strong>military income</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your <strong>military income</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your <strong>military income</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Base Payment Period',
        errorCustom: 'Selecting an option for income payment period <strong>is required</strong>.',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Salaried',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageSocialA: {
    title: `What is your <strong>monthly social security</strong> income?`,
    titleShow: false,
    pageId: 'social',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">What is your <strong>monthly social security</strong> income?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your <strong>monthly social security</strong> income?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your <strong>monthly social security</strong> income?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.monthlySocialSecurityPay',
        placeholder: 'Monthly Social Security',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPagePensionA: {
    title: 'Please provide some details about your <strong>Pension / Retirement</strong> income',
    titleShow: false,
    pageId: 'pension',
    validatorId: CPOSPageValidatorsEnum.pension,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide some details about your <strong>Pension / Retirement</strong> income</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, please provide some details about your <strong>Pension / Retirement</strong> income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, please provide some details about your <strong>Pension / Retirement</strong> income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your Pension/Retirement income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailed',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailedSpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Retirement Date',
        hint: `If you don't know the exact date, provide an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].income.retirementStartDate',
            validators: {
              required: true,
            },
          },
        ],
        rules: [
          {
            field: 'loan.$$custom.loan.employments[].income.retirementIncomeOther',
            operator: RuleExprOp.NE,
            value: true,
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Retirement End Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.end,
          startDate: 'loan.$$custom.loan.employments[].income.retirementStartDate',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].income.retirementEndDate',
            validators: {
              // required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.monthlyPension',
        placeholder: 'Monthly Pension/Retirement',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageBusinessA: {
    title: 'Tell us about your <strong>self employment</strong>',
    titleShow: false,
    pageId: 'business',
    eventIds: {
      onNext: CPOSEventsEnum.incomeBusiness
    },
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>self employment</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>self employment</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>self employment</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.name',
        placeholder: 'Company Name',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        data: {
          placeholder: 'Company Street Address',
          addressPath: 'loan.$$custom.loan.employments[].employerInfo.address',
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.address',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.city',
        placeholder: 'Company City',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.state',
        placeholder: 'Company State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.zip',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.phone',
        placeholder: 'Company Phone Number',
        formFieldType: 'phoneNumber',
        validators: {
          minLength: 10,
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityType',
        placeholder: 'Legal Entity Type',
        columns: 12,
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'C-Corp',
            value: 1,
          },
          {
            label: 'LLC',
            value: 5,
          },
          {
            label: 'Partnership',
            value: 2,
          },
          {
            label: 'S-Corp',
            value: 3,
          },
          {
            label: 'Sole Proprietorship',
            value: 4,
          }
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityLLC',
        placeholder: 'How are you classified as an LLC?',
        columns: 12,
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'C-Corp',
            value: 1,
          },
          {
            label: 'Partnership',
            value: 2,
          },
          {
            label: 'S-Corp',
            value: 3,
          },
          {
            label: 'Sole Proprietorship',
            value: 4,
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityType',
              operator: RuleExprOp.EQ,
              value: 5,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageBusinessB: {
    title: 'Tell us about your job with your <strong> employer </strong>.',
    titleShow: false,
    pageId: 'employer-job2',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Tell us about your job with <strong> {{loan.$$custom.loan.employments[].employerInfo.name}} </strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.title',
        placeholder: 'Job Title',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employmentInfoId',
        placeholder: 'INTERNAL HIDDEN',
        formFieldType: 'text',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].employmentInfoId',
              operator: RuleExprOp.EQ,
              value: '',
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].isPrevious',
        placeholder: 'Do you currently work here?',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Yes, I currently work here',
            value: false,
          },
          {
            label: 'No, I previously worked here',
            value: true,
          },
        ],
        disabledCustom: CPOSDisableCustomEnum.checkEmploymentCurrentWorkDisabled,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        columns: 6,
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.incomeEndDate,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
              required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years in this Profession',
        formFieldType: 'number',
        maxlength: 2,
        validators: {
          // required: true,
        },
      },
    ],
  }, // end page

  guidPageBusinessC: {
    title: 'What is your income at <strong>your employer</strong>?',
    titleShow: false,
    pageId: 'business-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">What is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Base Payment Period',
        errorCustom: 'Selecting an option for income payment period <strong>is required</strong>.',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Salaried',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
          {
            label: 'Hourly',
            value: 7,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: 'N1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: 'N1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Hourly Rate',
        formFieldType: 'currency',
        format: 'N1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseHoursPerWeek',
        placeholder: 'Hours per week',
        formFieldType: 'number',
        min: 0,
        max: 168,
        prefix: '<i class="fa fa-clock-o fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
          min: 0,
          max: 168
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualOvertime',
        placeholder: 'Annual Overtime',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualBonus',
        placeholder: 'Annual Bonus',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualCommission',
        placeholder: 'Annual Commission',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
    ],
  }, // end page

  guidPageOtherA: {
    title: 'Please provide some details about your <strong>other source</strong> of income',
    titleShow: false,
    pageId: 'other',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide some details about your <strong>other source</strong> of income</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, please provide some details about your <strong>other source</strong> of income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, please provide some details about your <strong>other source</strong> of income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
        placeholder: 'Income Type:',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Alimony / Child Support',
            value: 8,
          },
          {
            label: 'Disability',
            value: 15,
          },
          {
            label: 'Interest / Dividends',
            value: 4,
          },
          {
            label: 'Unemployment',
            value: 27,
          },
          {
            label: 'Other',
            value: 6,
          },
        ],
        validators: {
          required: true,
        },
      },
      // Interest / Dividens [4]: { Placeholder: Annual Income, Format: Allows only positive }
      // Disability, Unemployment [15, 27]  { Placeholder: Monthly Income, Format: Allows only positive }
      // Other [6]  { Placeholder: Annual Income, Format: Allows negative }
      // Alimony / Child Support [8]  { Placeholder: Monthly Income, Format: Allows negative }
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeAmount',
        placeholder: 'Monthly Income',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
              operator: RuleExprOp.IN,
            value: [15, 27],
            },
          ],
  },
},
<CvFormBuilder.FormField>{
  type: 'formField',
  field: 'loan.$$custom.loan.employments[].income.otherIncomeAmount',
  placeholder: 'Monthly Income',
  formFieldType: 'currency',
  format: 'N1.2-2',
  hint: 'Add decimal point for cents',
  prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
  validators: {
    required: true,
  },
  visible: {
    rules: [
      {
        field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
        operator: RuleExprOp.EQ,
        value: 8,
      },
    ],
  },
},
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeAmount',
        placeholder: 'Annual Income',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
          operator: RuleExprOp.EQ,
          value: 4,
        },
      ],
    },
  },
  <CvFormBuilder.FormField>{
    type: 'formField',
    field: 'loan.$$custom.loan.employments[].income.otherIncomeAmount',
    placeholder: 'Annual Income',
    formFieldType: 'currency',
    format: 'N1.2-2',
    hint: 'Add decimal point for cents',
    prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
    validators: {
      required: true,
    },
    visible: {
      rules: [
        {
          field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
          operator: RuleExprOp.EQ,
          value: 6,
        },
      ],
    },
  },
  <CvFormBuilder.FormField>{
    type: 'formField',
    field: 'loan.$$custom.loan.employments[].income.otherIncomeDescription',
    placeholder: 'Description',
    hint: 'Please provide a description',
    formFieldType: 'text',
    validators: {
      // required: true,
    },
    visible: {
      rules: [
        {
          field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
          operator: RuleExprOp.EQ,
          value: 6,
        },
      ],
    },
  },
  <CvFormBuilder.Html>{
    id: 'alimony-description',
    type: 'html',
    html:
      '<div class="alert alert-info icon">Notice: Alimony, child support, or separate maintenance income need not be revealed if the borrower or co-borrower does not choose to have it considered for repaying this loan</div>',
    visible: {
      rules: [
        {
          field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
          operator: RuleExprOp.EQ,
          value: 8,
        },
      ],
    },
  },
  <CvFormBuilder.Buttons>{
    type: 'button',
    buttons: [
      {
        label: 'Delete this income',
        class: 'mat-danger delete-btn',
        actions: [
          {
            type: ButtonActions.modalDelete,
            params: {
              type: 'income',
              field: 'loan.$$custom.loan.employments[]',
              featureId: 'income-delete',
              routeGoTo: 'guidRouteZ',
            },
          },
        ],
      },
    ],
    visible: {
      rules: [
        {
          field: 'loan.$$custom.isEditing',
          operator: RuleExprOp.EQ,
          value: true,
        },
      ],
    },
  },
    ],
  }, // end page

guidPageZ: {
  title: 'Please <strong>review</strong> the information you\'ve entered',
    titleShow: true,
      pageId: 'summary',
        eventIds: {
    onNext: CPOSEventsEnum.incomeSummary,
    },
  isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-income',
      },
      /**
       <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Add another income source',
            actions: [
              {
                type: ButtonActions.modelAdd,
                params: {
                  model: 'loan.$$custom.loan.employments[]',
                  data: {
                    // borrowerId: 'test'
                  }
                },
              },
              {
                type: ButtonActions.routeGoTo,
                params: 'guidRouteB',
              },
            ],
          },
        ],
      }, // end
       */
    ],
  }, // end page
};
