<div *ngIf="showSpacer" class="embedded-spacer"></div>
<div *ngIf="showNavigationButtons"
      class="nav-buttons btn-group d-flex justify-content-between"
      type="button"
      automationid="Section_NavigationButt"
      [ngClass]="navButtonRowClass">
  <button *ngIf="showBackNavigationButton"
          mat-flat-button
          class="btn py-2 back"
          type="button"
          automationid="BtnPrev"
          [disabled]="disableBackButton"
          [ngClass]="buttonClass"
          (click)="onPageBack($event)">
    <i class="fa fa-long-arrow-left me-1" aria-hidden="true"></i> Back
  </button>
  <button *ngIf="showNextNavigationButton"
          mat-flat-button
          class="btn py-2 next"
          type="button"
          automationid="BtnNextNext"
          [ngClass]="buttonClass"
          [attr.aria-describedby]="ariaDescribedById"
          (click)="onPageNext($event)">
    Next <i class="fa fa-long-arrow-right ms-1" aria-hidden="true"></i>
  </button>
  <button *ngIf="showSubmitNavigationButton"
          mat-flat-button
          class="btn py-2 submit"
          type="button"
          automationid="BtnNextSubmit"
          [ngClass]="buttonClass"
          [attr.aria-describedby]="ariaDescribedById"
          (click)="onPageNext($event)">
    Next <i class="fa fa-long-arrow-right ms-1" aria-hidden="true"></i>
  </button>
</div>
